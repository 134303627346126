const fi = {
  language: 'fi',
  userRoles: {
    admin: 'Admin',
    planner: 'Normaali',
    read_only_planner: 'Lukuoikeus',
    plus_planner: 'Plus',
    partner: 'Partneri',
    customer: 'Asiakas',
    customs_agent: 'Tulliagentti',
    handler: 'Käsittelijä'
  },
  emptyStates: { notFoundTitle: 'Ei tuloksia' },
  application: {
    requiredLocation: 'Sijainti on pakollinen tieto',
    recipient: 'Vastaanottaja',
    save: 'Tallenna',
    update: 'Päivitä',
    required: '*Pakollinen kenttä',
    lastUpdated: 'Viimeksi päivitetty',
    requiredShort: '*Pakollinen tieto',
    search: 'Etsi',
    logOut: 'Kirjaudu ulos',
    proceed: 'Jatka',
    courier: 'Lähetti',
    couriers: 'Lähetit',
    consignments: 'Lähetykset',
    status: 'Tila',
    address: 'Osoite',
    estimated: 'Arviolta',
    adjusted: 'Tarkistaa',
    actual: 'Nykyinen',
    funeral: 'Hautajaiset',
    tracking: 'Seuranta',
    trackingPage: 'Vastaanottajan seurantasivu',
    delivery: 'Toimitus',
    phoneNumberShort: 'Puhelin',
    yes: 'Kyllä',
    no: 'Ei',
    notSelected: 'Ei valittu',
    httpErrorToString: 'HTTP virhe: koodi {{status}} {{statusText}}',
    anErrorOccurred: 'Tapahtui virhe!',
    noAccessTitle: 'Ei pääsyä',
    noAccessMessage: 'Sinulla ei ole oikeutta käyttää tätä toimintoa',
    autoComplete: 'Etsi paikkoja...',
    autoCompleteWithoutDots: 'Etsi paikkoja',
    totalWeight: 'Kokonaispaino',
    totalVolume: 'Kokonaisvolyymi',
    title: {
      root: 'Tervetuloa',
      planner: 'Reitin suunnittelutyökalu',
      plannerLive: 'Suunnittelutyökalu - Live',
      plannerLiveSlot: 'Suunnittelutyökalu - Live - Toimitusajankohta',
      plannerCouriers: 'Suunnittelutyökalu - Lähetit',
      plannerImport: 'Suunnittelutyökalu - Tuo',
      plannerUnresolved: 'Suunnittelutyökalu  - Selvittämätön',
      plannerConsignments: 'Suunnittelutyökalu  - Lähetykset',
      plannerSearch: 'Suunnittelutyökalu   - Etsi',
      plannerPlanning: 'Suunnittelutyökalu  - Sunnittelu',
      plannerInvoicing: 'Suunnittelutyökalu  - Laskutus',
      plannerVehicle: 'Suunnittelutyökalu - Vehicles',
      report: 'Raportit',
      customerDepartmentLive: 'Live-näkymä',
      recipient: 'Lähetyksen seuranta - seuranta - Bring',
      instant: 'Kuriiri',
      instantBooking: 'Tilaus',
      h2Booking: 'H2 Tilaus',
      orderConfirmation: 'Tilausvahvistus',
      customerPortal: 'Asiakasportaali',
      instantMap: 'Pikakartta',
      recurringOrder: 'Vakio tilaukset',
      corrections: 'Tilauksen virheiden korjaus',
      jobs: 'Työpaikkojen seuranta',
      holidays: 'Lomat',
      shipmentLabels: 'Osoitekortit',
      addresses: 'Osoitteet',
      airexpressExportData: 'Exporter les données AirExpress',
      airexpressRecurringOrder: 'Air Express Vakio tilaukset',
      airexpressInvoicing: 'Air Express Laskutus',
      routeReceiptList: 'Kuittauslista',
      loadList: 'Lastauslista',
      airexpressAdminTerminals: 'Terminaalit',
      manageReturns: 'Hallinnoi palautuksia',
      preAdviceFollowup: 'Hallitse ennakkoilmoitusta'
    },
    language: { en: 'Englanti', nb: 'Norja', sv: 'Ruotsi', dk: 'Tanska', fi: 'Suomi' },
    country: {
      AllCountry: 'Kaikki maat',
      Country: 'Maa',
      AT: 'Itävalta',
      BE: 'Belgia',
      BA: 'Bosnia ja Hertsegovina',
      HR: 'Kroatia',
      CZ: 'Tšekki',
      DK: 'Tanska',
      GB: 'Yhdistynyt kuningaskunta',
      EE: 'Viro',
      FO: 'Färsaaret',
      FI: 'Suomi',
      FR: 'Ranska',
      DE: 'Saksa',
      GR: 'Kreikka',
      HU: 'Unkari',
      IS: 'Islanti',
      IE: 'Irlanti',
      IT: 'Italia',
      LT: 'Liettua',
      LU: 'Luxemburg',
      MK: 'Pohjois-Makedonia',
      NL: 'Alankomaat',
      NO: 'Norja',
      PL: 'Puola',
      PT: 'Portugali',
      RU: 'Venäjä',
      ES: 'Espanja',
      SE: 'Ruotsi',
      CH: 'Sveitsi',
      UA: 'Ukraina'
    },
    timeDistance: 'Suunniteltu aika ja välimatka',
    driverReturnToAddress: 'Älä anna palautusosoitetta',
    plannedTime: 'Suunniteltu aika',
    plannedDistance: 'Suunniteltu etäisyys',
    plannedWeight: 'Suunniteltu paino',
    distance: 'Välimatka',
    helpLink: '(Mikä tämä on?)',
    groups: 'Ryhmät',
    departments: 'Osastot',
    selectAll: 'Valitse kaikki'
  },
  simpleSearch: { link: 'Linkki' },
  dropdown: {
    selectedCustomers: '{{count}} asiakas',
    selectedCustomers_plural: '{{count}} asiakkaat',
    allCustomers: 'Kaikki asiakkaat',
    filterCustomers: 'Suodata asiakkaat',
    selectedDepartments: '{{count}} osasto',
    selectedDepartments_plural: '{{count}} osastot',
    allDepartments: 'Kaikki osastot',
    filterDepartments: 'Suodata osastot',
    selectedServiceCodes: '{{count}} palvelu',
    selectedServiceCodes_plural: '{{count}} palvelut',
    allServiceCodes: 'Kaikki palvelut',
    filterServiceCodes: 'Suodatinpalvelut',
    selectedVasCodes: '{{count}} vas',
    selectedVasCodes_plural: '{{count}} vases',
    allVasCodes: 'Kaikki vases',
    filterVasCodes: 'Suodata vases',
    selectedDestinations: '{{count}} määränpää',
    selectedDestinations_plural: '{{count}} määränpäät'
  },
  button: {
    edit: 'Muokkaa',
    back: 'Takaisin',
    cancel: 'Peruuta',
    done: 'Valmis',
    clear: 'Tyhjennä',
    close: 'Sulje',
    save: 'Tallenna',
    delete: 'Poista',
    new: 'Luo uusi',
    search: 'Etsi',
    add: 'Lisää',
    remove: 'Poista',
    fullEdit: 'Muokkaa tilauksella',
    continue: 'Jatka'
  },
  modals: {
    preplan: {
      create: 'Suunnittele tilaus',
      change: 'Vaihda valittu kuljettaja',
      shipmentInfo: 'Suunnittele tilaus {{shipmentId}} ({{pickupName}} {{deliveryName}})',
      pickDriver: 'Valitse kuljettaja..',
      unpreplan: 'Peru Suunnittelu',
      preplan: 'Suunnittele',
      error: 'Suunnittelua ei pystytä toteuttamaan',
      notification: {
        preplanned: 'Suunniteltu',
        unpreplanned: 'Suunnittelematon',
        preplannedMessage: 'Tilaus suunniteltiin {{courierId}}',
        unpreplannedMessage: 'Tilauksen suunnittelu peruttu'
      }
    },
    maps: {
      save: 'Tallenna',
      cancel: 'Peruuta',
      delete: 'Poista',
      editMode: 'Muokkaa',
      mapArea: '{{routeName}} kartta-alue',
      saved: 'Tallennettu',
      deleted: 'Poistetut'
    }
  },
  consignment: {
    onlyReturnPreAdvice: 'Palauta lähettäjälle',
    consignmentDetails: 'Lähetyksen tiedot',
    search: 'Etsi lähetyksiä',
    noShipmentsFound: 'Ei haku tuloksia',
    clickButtonToSearch: 'Paina haku painiketta suorittaaksesi haku',
    consignmentsForDate: 'Lähetykset {{date}}',
    showOnlyEditable: 'Vain muokattavissa',
    showOnlyNotAssigned: 'Ei annettu toimitettavaksi',
    withoutDate: 'Ilman päivämäärää',
    extendedSearch: 'Laajennettu haku',
    showDeleted: 'Näytä poistetut',
    showDeviated: 'Näytä poikkeama',
    deletedBy: 'Poistanut',
    deletionReason: 'Syy',
    numberOfConsignments: 'Löydetty {{number}} lähetystä',
    showAll: 'Kaikki',
    delivery: 'Toimitus',
    pickup: 'Nouto',
    pickups: '{{count}} nouto',
    pickups_plural: '{{count}} noutoa',
    edit: 'Muokkaa',
    deleteOrders: 'Poista',
    duplicateOrder: 'Kopioida',
    copyOrder: 'Kopioi',
    editOrder: 'Muokkaa',
    deletedOrderNotCopyable: 'Poistettua tilausta ei voida kopioida koska tilauksen tiedot ovat puutteelliset',
    restoreOrder: 'Palauta',
    deletedOrderNotRestorable: 'Poistettua tilausta ei voida palauttaa puuttuvien tietojen takia',
    deleteConfirmation: 'Haluatko varmasti poistaa tämän lähetyksen?',
    deleteMultipleConfirmation: 'Oletko varma että haluat poistaa valitut lähetykset?',
    copyConfirmation: 'Haluatko varmasti kopioida tämän lähetyksen?',
    editConsignment: 'Muokkaa lähetystietoja',
    searchShipments: 'Etsi tilaus:',
    searchCustomers: 'Etsi asiakas:',
    filterBy: 'Suodata:',
    limitedShipmentsShownMessage: 'Näyttää enimmäis määrän {{count}} tilausta, tarkenna hakua',
    limitedUnresolvedAddressesShownMessage: 'Näytetään enimmäiset {{count}} epäselvää osoitetta',
    bulkActions: 'Massa toiminta',
    bulkActionsMultiple: 'Massa toiminta ({{count}})',
    addToRecurringGroup: 'Lisää tilaus vakio ryhmään',
    moveShipmentsToDifferentDepartment: 'Siirrä toiseen osastoon',
    addEvents: 'Lisää tapahtuma',
    disabledEditDeleteHelperText:
      'Tilauksen tietoja ei voi muuttaa, eikä sitä voi poistaa. Tehdäksesi muutoksia ole yhteydessä Bring asiakaspalveluun.',
    editPickupConsignment: 'Päivitä noutotietoja',
    editDeliveryConsignment: 'Päivitä toimitustietoja',
    editPickupAndDeliveryTime: 'Päivitä nouto ja toimitus aikoja',
    editConsignmentLocation: 'Sijainti',
    editConsignmentTime: 'Aika',
    editConsignmentName: 'Nimi',
    editConsignmentPhone: 'Puhelinnumero',
    editConsignmentInstructions: 'Ohjeet',
    earliestPickupTime: 'Aikaisin noutoaika *',
    latestPickupTime: 'Myöhäisin noutoaika *',
    earliestDeliveryTime: 'Aikaisin toimitusaika',
    latestDeliveryTime: 'Myöhäisin toimitusaika',
    enterPickupInstructions: 'Anna nouto-ohjeet',
    enterDeliveryInstructions: 'Anna toimitusohjeet',
    enterDeliveryName: 'Anna toimituksen nimi',
    enterPickupName: 'Annan noudon nimi',
    enterPickupPhone: 'Anna noudosta vastaavan puhelinnumero',
    enterDeliveryPhone: 'Anna toimituksesta vastaavan puhelinnumero',
    enterSpecificationSize: 'Anna specification size',
    editSpecificationSize: 'Muokkaa specification size',
    enterSpecificationDescription: 'Kirjoita kuvaus',
    editSpecificationDescription: 'Muokkaa kuvausta',
    editPickupInfoDescription: 'Voidaksesi muokata tietoja sinun pitää valita jompikumpi yllä olevista vaihtoehdoista',
    updateLocationFor: 'Päivitä {{count}} lähetyksen tiedot',
    updateLocationFor_plural: 'Päivitä {{count}} lähetyksen noutotiedot',
    comment: 'Kommentti',
    show: 'Näytä',
    filter: 'Suodata lähetyksiä',
    state: 'Tila',
    estimatedDelivery: 'Arvioitu toimitus',
    requestedTimePickup: 'Toivottu noutoaika',
    requestedTimeDelivery: 'Toivottu toimitusaika',
    timeNotSet: 'Ei valittu',
    customerReference: 'Asiakkaan viite',
    shipmentId: 'Lähetystunnus',
    packageId: 'Paketin tunnus',
    noCourier: 'Ei lähettiä',
    noSlot: 'Ei sopivaa toimitusaikaa',
    showEvents: 'Näytä tapahtumat →',
    hideEvents: 'Piilota tapahtumat ←',
    events: 'Tapahtumat',
    send: 'Lähetä',
    sendMessage: 'Lähetä viesti',
    sendCommentFailed: 'Kommentin tallentaminen epäonnistui',
    recipient: 'Vastaanottaja',
    unresolved: 'Selvittämättömät lähetykset',
    description: 'Kuvaus',
    pickupAddress: 'Nouto-osoite',
    numberOfPackages: 'Pakettien lukumäärä',
    registeredPickupAddress: 'Rekisteröity nouto-osoite',
    pickupZipCode: 'Postinumero',
    pickupZipArea: 'Alue',
    pickupLocation: 'Sijainti',
    deliveryAddress: 'Toimitusosoite',
    deliveryZipCode: 'Postinumero',
    deliveryZipArea: 'Alue',
    deliveryLocation: 'Sijainti',
    registeredDeliveryAddress: 'Rekisteröity toimitusosoite',
    updated: 'Lähetys on päivitetty',
    moved: 'Lähetys on siirretty',
    unresolvedMessage: 'Emme pystyneet paikantamaan {{count}} lähetyksen osoitetta, ole hyvä ja lisää osoitteet.',
    unresolvedMessage_plural:
      'Emme pystyneet paikantamaan {{count}} lähetyksen osoitetta, ole hyvä ja lisää osoitteet.',
    foundUnresolvedMessage:
      'Löysimme seuraavat voimassa olevan osoitteet tälle lähetykselle. Jos ne ovat oikein, paina päivitä. Muussa tapauksessa etsi oikea osoite alla olevalta kartalta.',
    remove: 'Poista',
    alterSlot: 'Muuta toimitusajankohtaa (ilman optimointia)',
    alterSlotAndReoptimize: 'Muuta toimitusajankohtaa (optimoi uusi aika samalle tilaukselle)',
    alterSlotNotAvailable: 'Ei saatavana optimoinnin aikana',
    optimizingNewSlot: 'Optimoi lähetyksen uuteen ajankohtaan',
    selectSlot: 'Valitse uusi toimitusajankohta',
    slotSelect: 'Valitse toimitusajankohta',
    confirmSlotChange: 'Vahvista toimitusajankohdan muutos',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInstructions: 'Toimitusohjeet',
    specificationDescription: 'Kuvaus',
    tracking: 'Seuranta',
    recipientPhone: 'Vastaanottajan puhelinnumero',
    customerRef: 'Asiakkaan viite',
    service: 'Palvelu',
    message: {
      CREATED: 'Luotu',
      ASSIGNED: 'Sijoitettu ajankohtaan',
      UNASSIGNED: 'Ei annettu toimitettavaksi',
      COLLECTED: 'Noudettu',
      DELIVERED: 'Toimitettu',
      DEVIATED: 'Poikkeama',
      COMMENT: 'Kommentti',
      RESOLVED: 'Ratkaistu',
      UNRESOLVED: 'Ei ratkaistu',
      OFFERED: 'Tarjottu',
      REJECTED: 'Hylätty',
      ACCEPTED: 'Hyväksytty',
      RETURNED: 'Palautettu',
      DELETED: 'Poistetut'
    },
    manualOverrideTitle: 'Manuaalinen ohitus',
    adjustTabTitle: 'Muokata',
    mapPanelTitle: 'Kartta',
    recipientName: 'Vastaanottajan nimi',
    receivedBy: 'Vastaanottanut',
    manuallyChangeLocations: 'Sijainnit muutettu manuaalisesti',
    manualOverrideDeviationTimeConfirm: 'Hyväksy manuaalinen poikkeama valituille tilauksille',
    manualOverrideDeliveryTimeConfirm: 'Hyväksy manuaalinen toimitus valituille tilauksille',
    onManuallyChangeLocationsConfirm: 'Oletko varma että haluat tehdä tilauksista epäselvän?',
    unresolveDeliveryAddress: 'Siirrä vienti-osoite epäselväksi',
    unresolvePickupAddress: 'Siirrä nouto-osoite epäselväksi',
    reference: 'Viite',
    shipmentIdNumber: 'Ei',
    shipmentDetails: 'Lähetyksen tiedot',
    customer: 'Asiakas',
    subcustomer: 'Asiakasnumero',
    dateAndTime: 'Päivä ja ajat',
    filterCustomer: 'Suodata asiakas',
    searchShipmentDateError: 'Anna aikaväliksi alle 120 päivää',
    searchPhraseLength: 'Haun täytyy olla vähintään {{minLength}} kirjainta pitkä.',
    moveOrder: 'Siirrä lähetys',
    moveOrderTitle: 'Siirrä lähetys toiselle osastolle',
    moveOrderDefault: 'Valitse osasto',
    label: 'Etiketti',
    createLabel: 'Luo etiketti',
    createLabelShipment: 'Luo etiketti (lähetys)',
    createMultipleLabel: 'Luo etiketti ({{count}})',
    viewLabel: 'Näytä etiketti',
    generateLabelErrorDescription: 'Osoitekortteja ei voitu luoda',
    generatingLabels: 'Odota hetki. Osoitekortteja luodaan',
    labelsGeneratedSuccessfully: 'Osoitekortit luotu onnistuneesti',
    vas: 'Lisäarvoa tuottavat palvelut (VAS)',
    price: 'Hinta',
    orderSources: { glow_customerBooking: 'GLOW Asiakkaan tilaus', glow_recurring: 'Vakio tilaukset' },
    shipmentSyncStatus: { yes: 'Kyllä', no: 'Ei' },
    unresolvedSuccessfully: 'Lähetys on selvitetty onnistuneesti.',
    wrongCoordinates: 'Väärät koordinaatit?',
    moveShipmentToUnresolved: 'Siirrä lähetys ”Epäselviin” jotta voit korjata koordinaatit.',
    mapUnavailable: 'Kartta tulee näkyviin kun lähetys on selvitetty.',
    selectOtherActions: 'Valitse muut toimet'
  },
  eventsList: { timeWindow: 'Aikaikkuna', from: 'Alkaen', to: 'Asti' },
  consignmentEvent: {
    message: {
      resolved: 'Osoitekoordinaatit asetettu',
      comment: 'Kommentti',
      created: 'Luotu',
      completed: 'Valmis',
      assigned: 'Valittu lähetti',
      plannedOnRoute: 'Suunniteltu reitille',
      plannedOnRouteMoreInfo: 'Suunniteltu reitille {{routeName}} ({{unitName}})',
      delivered: 'Toimitettu',
      collected: 'Noudettu',
      returned: 'Palautettu',
      deviated: 'Poikkeama',
      damaged: 'Vaurioitunut',
      notification: 'Ilmoitus',
      slotChanged: 'Toimitusajankohta muutettu manuaalisesti',
      unassigned: 'Ei annettu toimitettavaksi',
      rejected: 'Hylätty',
      image: 'Kuva',
      error: 'Virhe',
      started: 'Aloitettu',
      stopped: 'Lopetettu',
      accepted: 'Hyväksytty',
      offered: 'Tarjottu',
      unitName: 'Yksikkö',
      driverName: 'Kuljettaja',
      'delivery-id-check': 'ID:n tarkistus',
      'delivered-manually-overridden': 'Toimitus manuaalisesti ohitettu',
      'deviated-manually-overridden': 'Poikkeama manuaalisesti ohitettu',
      'collected-manually-overridden': 'Nouto manuaalisesti ohitettu',
      'pickup-location-changed': 'Noutoaika muutettu',
      'delivery-time-estimated': 'Päivitetty toimitusaika',
      'slot-manually-changed': 'Toimitusajankohta manuaalisesti ohitettu',
      'pickup-time-changed': 'Noutoaika muutettu',
      'manually-change-location': 'Sijainnit manuaalisesti ohitettu',
      'pickup-consignment-data-changed': 'Noutotietoja muutettu',
      'delivery-consignment-data-changed': 'Toimitustietoja muutettu',
      'specification-size-data-changed': 'Specification Size muutettu',
      'specification-description-data-changed': 'Kuvausta muutettu',
      'digital-identity-verified': 'Vastaanottajan henkilöllisyys varmistettu digitaalisesti',
      'digital-identity-verification-failed': 'Ei voitu vahvistaa vastaanottajan henkilöllisyyttä digitaalisesti',
      'flex-delivery-ordered': 'Yksinkertaistettu toimitus tilattu vastaanottajan toimesta',
      'flex-delivery-ordered-by-sender': 'Yksinkertaistettu toimitus tilattu lähettäjän toimesta',
      'flex-delivery-confirmation-sms-sent': 'Flex toimitus vahvistus viesti lähetetty',
      'return-to-pickup-point-delivery-deviated': 'Toimitetaan toimituspisteeseen',
      recipientName: 'Vastaanottajan nimi',
      receivedBy: 'Vastaanottanut',
      'reject-order-correction': 'Tilauksen korjaus hylätty',
      'accept-order-correction': 'Tilauksen korjaus hyväksytty',
      'handed-over-to-other-unit': 'Välitetty toiselle yksikölle',
      deliveredWithoutSignature: 'Toimitettu ilman allekirjoitusta',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Toimitettu ohjeiden mukaisesti',
        did_not_get_signature: 'Toimitettu henkilölle'
      },
      'returned-manually-overridden': 'Toimitus manuaalisesti ohitettu',
      scanned: 'Saapunut terminaaliin',
      'arrived-at-terminal': 'Saapunut jakeluterminaaliin',
      'order-sorted': 'Lajiteltu',
      'pickup-eta-sms-sent': 'Arvioitu noutoaika lähetetty',
      'handed-in-at-posten-terminal': 'Saapunut lajitteluterminaaliin',
      'pickup-sms-sent': 'Seuranta tekstiviesti lähetetty',
      'pre-pickup-sms-sent': 'Seuranta SMS lähetetty',
      'pickup-address-unresolved': 'Nouto-osoite on siirretty epäselviin',
      'delivery-address-unresolved': 'Vienti osoite on siirretty epäselviin',
      'upcoming-pickup-sms-sent': 'Tulevan toimituksen SMS lähetetty',
      'order-sorted-same-day': 'Päivämäärä asetettu tälle päivälle terminaali sovelluksella',
      'signature-sms-sent': 'Allekirjoitus sms lähetetty',
      'flex-delivery-cancelled': 'Simplified delivery peruttu',
      'delivery-time-ordered': 'Vastaanottaja on muuttanut toimituspäivän',
      'delivery-time-ordered-internal': 'Muuttanut toimituspäivän',
      'delivery-time-updated-from-hf-sync': 'Toimitusaika synkronoitu HF:stä',
      'planned-delivery-date-ordered': 'Suunniteltu toimitus päivä tilattu',
      'delay-sms-sent': 'Tekstiviesti liittyen myöhästyneeseen toimitukseen lähetetty',
      'route-planning-complete': 'Reitti valmis',
      'signature-delivered': 'Vastaanottajan allekirjoitus',
      showPicture: 'Näytä kuva',
      'external-address-wash': 'Toimitustietoja muutettu',
      'order-note-changed': 'Muistiinpanot muutettu',
      'order-data-changed': 'Järjestys muutettu',
      'order-moved-to-department': 'Siirretty osastojen välillä',
      'order-moved-to-department-with-data': 'Lähetys siirretty osastosta',
      'package-measurements-changed': 'Paketin koon muuttaminen',
      'driver-deviated-from-route': 'Kuljettaja poikkesi reitiltä',
      'delivered-outside-service-time-window': 'Toimitus tai poikkeama on palvelu ajan ulkopuolella',
      'delivered-outside-estimated-time-window': 'Toimitus tai poikkeama on arvioidun aikaikkunan ulkopuolella',
      'seen-by-recipient': 'Vastaanottaja on nähnyt seuranta sivun',
      'first-estimated-times-seen': 'Vastaanottaja on nähnyt arvioidun ajan',
      'pre-advice-return-sms-sent': 'Preadvice RETURN-SMS sent',
      'delivery-location-deviation': 'Toimitus tai poikkeama ei odotetussa paikassa',
      'delivery-sms-sent': 'Toimitus SMS lähetetty',
      'parcelbox-allocated': 'Paikka varattu pakettilaatikossa',
      'apartment-information-updated': 'Asunnon tiedot päivitetty',
      'claim-registered-email-sent': 'Sähköposti viallisista tai puuttuvista tuotteista lähetetty',
      'service-upgrade-purchased': 'Lisämyynti: Vastaanottajan tilaama kuljetuspalvelu',
      'preliminary-loaded': 'Alustavasti lastattu',
      'manual-price-override': 'Hinta päällekirjoitettu',
      'incomplete-price-approved': 'Epätäydellinen hinta hyväksytty',
      'incomplete-price-unapproved': 'Puutteellinen hinnan hyväksyntä peruttu',
      unloaded: 'Purettu',
      'manually-changed-measurements': 'Manuaalisesti muutettu paino ja tilavuus',
      'internal-trade-order-created': 'Sisäisen kaupan tilaus luotu',
      'delivery-time-purchased': 'Lisämyynti: Lyhyempi aikaikkuna vastaanottajan tilaamana',
      'invoice-awaiting-acknowledgement': 'Odottaa laskun vahvistusta',
      'load-carrier-ready-for-distribution': 'Kuormankuljettaja valmiina jakelua varten'
    },
    rejectedReason: {
      reason: 'Syy',
      private: 'Yksityinen asia',
      notEnoughTime: 'En pysy aikataulussa',
      notEnoughSpaceOnVehicle: 'Liian suuri lähetys',
      other: 'Muu'
    },
    idCheck: {
      idCheckFailed: 'ID:n tarkistus epäonnistui, mutta toimitettiin {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Muu ({{otherType}}), syntymäaika {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, syntymäaika {{birthDate}}'
    },
    damaged: {
      damageType: 'Vaurion tyyppi',
      causeCode: 'Poikkeama koodi',
      courierInput: 'Kuljettajan merkintä',
      showPictureLink: 'Näytä kuva {{idx}}',
      type: { A: 'Vahingoittunut paketti/pakkaus', D: 'Paine/puristus vahinko', E: 'Tuhoutunut lähetys' }
    },
    invoiceInitiationType: {
      automatic: 'Suunniteltu laskutus',
      'manual-department-level': 'Osastotason manuaalinen laskutus',
      'manual-order-level': 'Tilaustason manuaalinen laskutus',
      'multiple-order-level': 'Käyttäjän manuaalinen useiden tilausten laskutus'
    },
    changedFrom: 'Muutettu -',
    blankText: '(Tyhjä)',
    resolved: {
      source: {
        DepartmentLocation: 'koordinaatit osastolle',
        GeocodingAPI: 'Google',
        DatabaseLookup: 'esitallennetut koordinaatit',
        PickupPointAPI: 'Pickup Point API',
        GlowFrontend: 'Glow',
        EDI: 'tuntematon',
        CustomerSite: 'asiakkaan sijainti',
        ParcelInMailbox: 'postilaatikon koordinaatit',
        Driver: 'kuljettaja',
        OriginalOrder: 'Alkuperäinen tilaus',
        Google: 'Google',
        GoodsReturnAddress: 'tavaroiden palautusosoite'
      },
      pickup: {
        coordinatesSetBy: 'Noutokoordinaatit asetettu {{source}} toimesta',
        coordinatesManuallySet: 'Noutokoordinaatit on asetettu manuaalisesti',
        coordinatesRestored: 'Noutokoordinaatit palautettu',
        coordinatesSwapped: 'Noutokoordinaatit asetettu aiempiin toimituskoordinaatteihin'
      },
      delivery: {
        coordinatesSetBy: 'Toimituskoordinaatit asetettu {{source}} toimesta',
        coordinatesManuallySet: 'Toimituskoordinaatit on asetettu manuaalisesti',
        coordinatesRestored: 'Toimituskoordinaatit palautettu',
        coordinatesSwapped: 'Toimituskoordinaatit asetettu aiempiin noutokoordinaatteihin'
      }
    },
    created: {
      recurringOrder: 'Luotu toistuvasta tilauksesta.',
      showOriginalOrder: 'Näytä alkuperäinen'
    }
  },
  numberSeries: { numberSeries: 'Numerosarjat' },
  preadvice: {
    searchPlaceholder: 'Kirjoita vähintään 3 merkkiä suodatusta varten',
    selectedCustomers: 'Valitut asiakkaat ({{count}})',
    customerSearch: 'Hakukenttä - Kirjoita vähintään 3 merkkiä etsiäksesi asiakkaita',
    settingTargetCustomers:
      'Tämä preavisoimissääntö kohdistuu tiettyyn asiakkaaseen. Poista kaikki valitut asiakkaat voidaksesi vaihtaa asetuksen asiakasryhmiin',
    customers: 'Asiakkaat',
    nameDescription: 'Nimi ja kuvaus',
    createNewPreadvice: 'Luo uusi preavisoimissääntö',
    bizArea: 'Valitse liiketoiminta-alue',
    filterDepartmentAndGroups: 'Suodata osastoilla tai osastoryhmillä',
    filterCustomersAndGroups: 'Suodata asiakkaille tai asiakasryhmille',
    hdDepartments: 'HD Departments',
    noPoliciesFound: 'No Preadvice policies',
    policies: 'Preadvice policies',
    policy: {
      servicePoliciesDescription: 'Valitsemastasi ilmoitustyypistä riippuen näytetään eri syöttökentät',
      exception: 'Poikkeus',
      new: 'New preadvice policy',
      edit: 'Edit preadvice policy',
      targets: 'Customer-/department connections'
    }
  },
  holidays: { holidays: 'Lomat', addHoliday: 'Lisää uusi pyhäpäivä', country: 'Maa', type: 'Tyyppi', date: 'Päivä' },
  department: {
    searchDepartment: 'Suodata osaston nimen tai Alystra ID:n mukaan',
    searchDepartmentGroups: 'Etsi osastoryhmän nimi tai kuvauksia',
    createNewDepartment: 'Luo uusi osasto',
    addressAndContact: 'Osoite ja yhteystiedot',
    searchAddressError: 'Puuttuvat arvot osoitteelle. Sinun täytyy käyttää hakukenttää saadaksesi oikean osoitteen.',
    addressContactIngress:
      'Saadaksesi oikean osoitteen, käytä hakukenttää. Osoite, postinumero ja kaupunki täytetään automaattisesti.',
    settingsInGlow: 'Asetukset vastaanottajan seurantatilalla',
    settingsBodyText: 'Valintasi mukaan erilaiset sisällöt ja viestintä näytetään/lähetetään vastaanottajille.',
    emailAddress: 'Sähköpostiosoite',
    routePlanning: 'Reittisuunnittelu',
    autoRouteCreationTime: 'Automaattinen reitin luominen',
    autoRouteOptimizationTime: 'Automaattinen reitin optimointi',
    clearTime: 'Poista aika',
    manageDepartment: 'Hallitse osasto',
    listAllGroups: 'Listaa kaikki osastoryhmät',
    departmentTitle: 'Osasto',
    department: 'osasto',
    department_plural: 'osastot',
    departmentWithCount: '{{count}} osasto',
    departmentWithCount_plural: '{{count}} osastoa',
    departmentGroups: 'Osasto ryhmät',
    numberOfDepartmentGroups: 'Osasto ryhmä',
    numberOfDepartments: 'Määrä osastoa',
    search: 'Etsi osastoja',
    noDepartmentName: 'Nimi puuttuu',
    noDepartmentId: 'ID puuttuu',
    noCouriers: 'Ei lähettejä',
    courierCount_plural: '{{count}} lähettiä',
    courierCount: '{{count}} lähetti',
    noCourier: 'Ei lähettejä',
    couriers: 'Lähettien lukumäärä',
    plannerCount_plural: '{{count}} reitin suunnittelutyökalua',
    plannerCount: '{{count}} reitin suunnittelutyökalu',
    noPlanner: 'Ei reitin suunnittelutyökaluja',
    planners: 'Reitin suunnittelutyökalujen määrä',
    choose: 'Valitse osasto',
    new: 'Uusi osasto',
    alystraId: 'Alystra ID',
    description: 'Kuvaus',
    name: 'Nimi',
    created: 'Osasto luotu',
    updated: 'Osasto päivitetty',
    departmentType: 'Osastotyyppi',
    courier: 'Lähetti',
    express: 'Pikatoimitus',
    hd: 'Kotitoimitus',
    other: 'Muu',
    all: 'Kaikki',
    deliveryDisplayType: 'Toimitusajan näyttö',
    terminalName: 'Terminaalin nimi',
    terminalNameTooltip: 'Jos nouto ja osaston nimi eroavat toisistaan, täytä nämä tiedot',
    address: 'Osoite',
    zipCode: 'Posti nro',
    zipArea: 'Alue',
    orderBillingType: {
      title: 'Tilauksen laskutustyyppi',
      selectBillingType: 'Valitse Laskutustyyppi',
      customerBillingNumber: 'Asiakkaan laskutusnumero',
      addMoreOrderBillingInfo: 'Lisää uusi tilauksen laskutustyyppi'
    },
    departmentId: 'Osasto Id',
    autoInvoiceTitle: 'Automaattinen laskutus',
    autoInvoiceEnable: 'Aseta automaattinen laskutus',
    autoInvoiceTime: 'Aika',
    autoInvoiceOffset: {
      legend: 'Mitkä tilaukset tulee laskuttaa',
      oneDayEarlier: 'Toimitettu 1 päivää sitten',
      twoDaysEarlier: 'Toimitettu 2 päivää sitten',
      threeDaysEarlier: 'Toimitettu 3 päivää sitten',
      fourDaysEarlier: 'Toimitettu 4 päivää sitten',
      fiveDaysEarlier: 'Toimitettu 5 päivää sitten'
    }
  },
  departmentGroup: {
    countries: 'Maat',
    departmentTypes: 'Osastotyypit',
    manageDepartments: 'Hallitse osastoja ryhmässä',
    createDepartmentGroup: 'Luo uusi osastoryhmä',
    noSelectedDepartments: 'Ei valittuja osastoja',
    selectedDepartments: 'Valitut osastot ({{count}})',
    selectAllFor: 'Valitse kaikki {{name}}',
    departmentGroup: 'Osasto ryhmä',
    name: 'Nimi',
    deleteConfirm: 'Oletko varma että haluat poistaa osaston tämän ryhmän?',
    edit: 'Muokkaa osasto ryhmä'
  },
  deliveryDisplayType: { instant: 'Kuriiri', customized: 'Räätälöity' },
  menu: {
    defaultDisabled: 'Valitse yksi asiakastunnus nähdäksesi valikon',
    home: 'Alkuun',
    admin: 'Hallinto',
    planner: 'Reitin suunnittelutyökalu',
    report: 'Raportit',
    customer: 'Asiakas',
    apiOrder: 'Etsi API-tilaus',
    priceMotor: 'Hinnoittelutyökalu',
    departments: 'Osastot',
    users: 'Käyttäjät',
    customers: 'Asiakas',
    customerGroups: 'Asiakasryhmiä',
    numberSeries: 'Numerosarjat',
    jobsMonitoring: 'Työpaikkojen seuranta',
    holidays: 'Lomat',
    settings: 'Asetukset',
    userRoles: 'Käyttäjäroolit',
    integrations: 'Integraatiot',
    routePlanning: 'Reitin suunnittelu',
    dispatching: 'Ajojärjestely',
    booking: 'Tilaus',
    h2Booking: 'H2 Tilaus',
    setCoordinates: 'Aseta koordinaatit',
    shipments: 'Haku',
    corrections: 'Muutokset',
    couriers: 'Lähetit',
    recurring: 'Vakiot',
    addresses: 'Osoitteet',
    invoicing: 'Laskutus',
    manageReturns: 'Hallinnoi palautuksia',
    preAdviceFollowup: 'Hallitse ennakkoilmoitusta',
    optimizing: 'Optimointi',
    services: 'Palvelut',
    matrix: 'Matriisi',
    vendors: 'Toimittajat',
    backToOldShipmentsPage: 'Takaisin vanhalle sivustolle.',
    mainNavigation: 'Päänavigointi',
    closePanel: 'Sulje paneeli',
    expandPanel: 'Laajenna paneeli'
  },
  planner: {
    canLogIn: 'Voi kirjautua sisään',
    chooseYourDepartment: 'Valitse osastosi',
    unassignedShipments: 'Varaamattomat lähetykset',
    filterCustomers: 'Suodata asiakkaita',
    filterServices: 'Suodata palvelut',
    autoRoute: 'Automaattireitti',
    saved: 'Tallennettu',
    allUserRoles: 'Kaikki käyttäjäroolit',
    userRolesCount_plural: '{{count}} käyttäjäroolia',
    createNewUser: 'Luo uusi käyttäjä',
    userRolesCount: '{{count}} käyttäjärooli',
    role: 'Rooli',
    searchForNameOrNumber: 'Hae nimeä tai puhelinnumeroa',
    noPlannersFound: 'Suunnittelijoita ei löytynyt',
    typeOfRole: 'Käyttäjäroolin tyyppi',
    selectUserRole: 'Valitse käyttäjärooli',
    users: 'Käyttäjät',
    connectDepartment: 'Yhdistä osasto käyttäjään',
    banner: {
      tryNewRoutePlanner:
        '1. toukokuuta, <0>reitinsuunnittelija 2.0</0> on ainoa saatavilla oleva reitinsuunnittelija. Jos et ole käyttänyt reitinsuunnittelijaa aiemmin, kannustamme sinua aloittamaan sen käytön nyt. <1>Lue lisää Zendeskistä</1> ja muista asiaan liittyvistä artikkeleista saadaksesi yleiskuvan siitä, miten se toimii.'
    },
    totalTitle: 'Paketit',
    pickList: 'Valintalista',
    export: 'Siirto laskutukseen',
    deleteSlot: 'Poista',
    editSlot: 'Muokkaa',
    deleteSlotConfirmDialogText: 'Oletko varma, että haluat poistaa tämän toimitusajankohdan?',
    deleteFilteredSlots: 'Poista kaikki paikat',
    deleteAllSlotsConfirmDialogText: 'Haluatko varmasti poistaa kaikki paikat?',
    created: 'Reitin suunnittelutyökalu luotu',
    updated: 'Reitin suunnittelutyökalu päivitetty',
    removeDepartment: 'Poista osasto {{name}}',
    connectAirlineTerminal: 'Yhdistä lentoterminaali(t) käyttäjään',
    connectedAirlineTerminals: 'Yhdistetyt lentoterminaalit ({{count}})',
    noConnectedTerminals: 'Ei yhdistettyjä terminaaleja vielä',
    removeTerminal: 'Poista terminaali {{name}}',
    searchTerminals: 'Etsi lentoterminaaleja',
    youMustSelectTerminal: 'Sinun on valittava lentoterminaali',
    youMustProvideName: 'Sinun on annettava käyttäjän nimi',
    youMustProvidePhonenumber: 'Sinun on annettava puhelinnumero',
    routePlanning: 'Reitin suunnittelu',
    routePlanningConsignmentsAssigned: 'annettu toimitettavaksi',
    routePlanningRangeDescription: 'Suodata lähetyksiä toimitusajan perusteella',
    unresolvedConsignments: 'Epäselvät',
    consignments: 'Haku',
    couriers: 'Lähetit',
    invoicing: 'Laskutus',
    planner: 'Reitin suunnittelutyökalu',
    planners: 'Reitin suunnittelutyökalut',
    noPlanners: 'Ei reitin suunnittelutyökaluja',
    missingId: 'ID puuttuu',
    missingName: 'Nimi puuttuu',
    search: 'Etsi',
    searchForPlanner: 'Etsi reitin suunnittelutyökaluja',
    name: 'Nimi',
    phoneNumber: 'Puhelinnumero',
    selectDepartments: 'Valitse osastot',
    createSlot: 'Luo',
    editSlotForm: 'Muokkaa toimitusajankohtaa',
    routeUpdated: 'Toimitusajankohdan tiedot päivitetty',
    createSlotFormName: 'Toimitusajankohdan nimi',
    createSlotFormUnit: 'Yksikkö',
    createSlotFormCourier: 'Lähetti',
    createSlotFormSlotMode: 'Toimitusajankohdan tila',
    createSlotFormSlotModePickup: 'Piirrä noutoalue kartalle',
    createSlotFormSlotModeDelivery: 'Piirrä toimitusalue kartalle',
    createSlotFormSlotModeManual: 'Valitse lähetykset manuaalisesti',
    createSlotFormTimeRange: 'Aikaväli',
    createSlotFormStartLocation: 'Aloituspaikka',
    createSlotFormEndTime: 'Päättymisaika',
    createSlotFormEndLocation: 'Lopetuspaikka',
    createSlotFormSlotColor: 'Toimitusajankohdan väri',
    createSlotFormTimeRangeTooBig: 'Aikaväli on liian suuri. Enimmäisaika on {{hours}} tuntia.',
    createSlotFormFilterCustomers: 'Suodata asiakkaita',
    createSlotFormFilterServices: 'Suodata palveluita (jos yhtään ei rastita, kaikki on valittu)',
    slotFilterInfo:
      'Tämä toimitusajankohta on suodatettu, mikä tarkoittaa, että vain valitulle/-tuille asiakkaalle/-kaille kuuluvat lähetykset ja valittu/-tut palvelu/-t voidaan yhdistää toimitusaikaan.',
    slotFilteredCustomer: 'Toimitusajankohtaan sisältyy VAIN seuraavien asiakkaiden tilaukset',
    slotFilteredService: 'Toimitusajankohtaan sisältyy VAIN seuraavia palveluita sisältävät tilaukset',
    onlyShowApplicable:
      'Näytä vain sellaiset tilaukset, jotka sopivat tähän toimitusajankohtaan asiakkaan/palveluiden suodatuksen perusteella',
    optimizeButtonLabel: 'Optimoi ja määrittele reitit',
    optimizeSlotButtonLabel: 'Optimoi toimitusajankohta uudelleen',
    reversedOptimizeSlotButtonLabel: 'Käänteinen toimitusjärjestys',
    optimizingSince: 'Optimointi käynnissä, alkanut klo {{startTime}}',
    alterSlotIdOnConsignment: 'Oletko varma, että haluat muuttaa tämän lähetyksen toimitusajankohtaa?',
    addToRoute: 'Lisää reittiin...',
    pickup: 'Nouto',
    delivery: 'Toimitus',
    slot: 'Toimitusajankohta',
    notAssigned: 'Ei annettu toimitettavaksi',
    readOnly: 'Vain luku -oikeus',
    normal: 'Normaali',
    partner: 'Kumppani',
    readOnlyAccessPlannersWithCount: '{{count}} suunnittelijat (lukuoikeudet)',
    plusPlannersWithCount: '{{count}} plus suunnittelijat (admin)',
    normalPlannersWithCount: '{{count}} suunnittelijat (perusoikeudet)',
    saveTemplate: 'Tallenna sivupohjana',
    loadTemplate: 'Lataa sivupohjasta',
    routeListForFilteredSlots: 'Kuittauslista (tulosta kaikki)',
    loadListForFilteredSlots: 'Lastauslista (tulosta kaikki)',
    sendPrePickupSmsesToAll: 'Ennen lähetyksen seurantatekstiviesti',
    alertBeforeSendingPrePickupSms:
      'Haluatko varmasti lähettää seurantatekstiviestin kaikille tämän päivän lähtö- ja saapumisaikoille?',
    createTemplateFormName: 'Sivupohjan nimi',
    createOrUpdateTemplateDescription:
      'Voidaksesi tallentaa sinun on JOKO annettava sivupohjalle uniikki nimi tai valittava jo luotu sivupohja päivitettäväksi.',
    newTemplate: 'Luo uusi sivupohja',
    overwriteTemplate: 'Kirjoita sivupohjan yli',
    templateSaved: 'Sivupohja on tallennettu',
    updateTemplateDescription: 'Valitse jo luotu sivupohja päivitettäväksi',
    updateTemplateConfirm: 'Oletko varma, että haluat päivittää tämän sivupohjan?',
    selectOrDeleteTemplateDescription: 'Valitse käytettävä tai poistettava sivupohja',
    selectTemplate: 'Valitse sivupohja',
    deleteTemplate: 'Poista',
    selectTemplateDescription: 'Valitse sivupohja:',
    templateSelected: 'Sivupohja on otettu käyttöön',
    templateDeleted: 'Sivupohja on poistettu',
    templateDeleteConfirm: 'Oletko varma, että haluat poistaa tämän sivupohjan?',
    missingDeliveryMarker: 'Toimitusosoite tai sijainti ei kelpaa',
    missingPickupMarker: 'Nouto-osoite tai sijainti ei kelpaa',
    unassignConsignmentFromSlot: 'Poista lähetys toimitusajankohdasta',
    unassignBulkConsignmentsFromSlotsAlert: 'Poista lähetykset niille merkatulta reitistä?',
    unassignedFromSlot: 'Lähetys on nyt poistettu toimitusajankohdasta',
    unassignBulkConsignmentsFromSlots: 'Lähetykset on nyt poistettu niille merkatulta reitiltä',
    unassignTitle: 'Poista tämä lähetys toimitusajankohdasta',
    unassignButton: 'Poista toimitusajankohdasta',
    forceUnassignButton: 'Pakota poisto reitiltä',
    moveOrder: 'Oletko varma, että haluat siirtää tätä lähetystä?',
    driverName: 'Lähetit',
    filters: {
      assigned: 'Annettu toimitettavaksi',
      unassigned: 'Ei  annettu toimitettavaksi',
      pickups: 'Noudot',
      deliveries: 'Toimitukset'
    },
    saveSelected: 'Tallenna valitut ({{count}})',
    showSaved: 'Näytä tallennetut ({{count}})',
    showAll: 'Näytä kaikki',
    clearSaved: 'Tyhjennä tallennetut',
    sendPrePickupSms: 'Ennakko lähetä seuranta SMS vastaanottajalle',
    sendSms: 'Lähetä tekstiviesti',
    sendingSmsText: 'Lähetetään {{count}} seuranta SMS',
    prePickupSmsSent: '{{sentCount}} / {{totalCount}} ennakkoviestiä lähetetty',
    manualDelaySms: { disabledTooltip: 'Tilaukset eivät voi olla tilassa Toimitettu' },
    h2: { booking: 'H2 Tilaus', vasHelperText: 'Käytä VAS 1158 kun MBH' },
    loadList: {
      header: 'Reitti yhteenveto',
      listName: 'Lastauslista',
      unknownRouteName: 'Tuntematon reitin nimi',
      unknownUnitName: 'Tuntematon kuljettajan nimi',
      noDataText: 'Täällä ei ole mitään nähtävää'
    },
    locationList: { listName: 'Sijaintiluettelo' },
    assignedUnits: 'Määrätyt yksiköt',
    unassignedUnits: 'Määrittämättömät yksiköt',
    noUnitSelected: 'Yksikköä ei ole valittu',
    unitAssignmentCount: 'Yksikkö määrätty {{count}} reitille'
  },
  plannerDashboard: {
    vehicleTypes: {
      car: 'Henkilöauto',
      bicycle: 'Polkypyörä',
      truck: 'Kuorma-auto',
      van: 'Pakettiauto',
      motorcycle: 'Moottoripyörä',
      cargobike: 'Rahtipyörä'
    }
  },
  updateServiceLevel: {
    menuItem: 'Vaihda palvelutaso',
    selectOption: 'Valitse palvelutaso',
    heading: 'Vaihda palvelutaso',
    confirmation: 'Palvelutaso päivitetty',
    subtext: 'Vaihda palvelutaso {{count}} lähetykselle',
    subtext_plural: 'Vaihda palvelutaso {{count}} lähetykselle',
    failed: {
      title: 'Palvelutason vaihto epäonnistui',
      message: 'Palvelutason vaihto epäonnistui {{count}} lähetykselle',
      message_plural: 'Palvelutason vaihto epäonnistui {{count}} lähetykselle'
    }
  },
  manualPreAdvice: {
    subtext: 'Change service level for {{count}} shipment',
    subtext_plural: 'Change service level for {{count}} shipments'
  },
  setPickupDeliveryDuration: {
    menuItem: 'Aseta nouto/vienti aika',
    heading: 'Aseta nouto ja vienti aika',
    subtext: 'Aseta nouto ja vienti aika {{count}} lähetykselle',
    subtext_plural: 'Aseta nouto ja vienti aika {{count}} lähetykselle',
    pickupDuration: 'Nouto aika',
    deliveryDuration: 'Vienti aika',
    confirmation: 'Aika päivitetty',
    tooltipTitle: 'Kestoja syötettäessä, maksimimäärä minuutteja ja sekunteja on 59'
  },
  importExportOrders: {
    importSuccess: 'Kaikki laskentataulukon tilaukset lisätään tai päivitetään järjestelmään',
    importSuccessTitle: 'Menestys',
    importOrdersHeading: 'Tuo tilaus',
    importStatus: 'Tällä hetkellä jonossa on {{imports}} tuontia, yhteensä {{orders}} tilausta',
    exportForInvoice: 'Export for Invoicing',
    fileTemplateLink: 'Lataa Excel-malli',
    uploadFile: 'Tuo tilaus',
    uploadingMessage: 'Ladataan tiedostoa ja tuodaan tilauksia...',
    needToSelectFile: 'Sinun on valittava tiedosto',
    exportSuccess: 'Kaikki tilaukset valitulta ajanjaksolta ladataan laskentataulukkoon',
    exportSuccessTitle: 'Menestys',
    dateFormat: 'PP.KK.VVVV',
    testDataWarning: 'Älä käytä henkilökohtaisia ​​tietoja testiympäristössä',
    testDataInfo:
      'Tämä on qa / testiympäristö, etkä saa käyttää tuotantotietoja testeissä ilman asianomaisten käyttäjien nimenomaista suostumusta',
    fromDate: 'Päivämäärästä',
    toDate: 'Päivämäärään',
    fromDateNotAfterToDate: 'Ei voi olla myöhäisempi kuin päivämäärää',
    toDateNotBeforeFromDate: 'Ei voi olla aikaisempi kuin päivämäärästä alkaen',
    removeControlColumns: 'Poista ohjaussarakkeet',
    importResults: 'Tuo tulokset',
    fileName: 'tuotu tiedosto',
    processedAt: 'Processed',
    createdShipmentId: 'Luotu toimitustunnus',
    date: 'Päivämäärä',
    exportShipments: 'vientialukset',
    createLabels: 'Luo tunnisteita',
    selectSlot: 'Valitse reitti',
    selectExportLabel: 'Valitse, mitä haluat viedä',
    allOrders: 'Kaikki tilaukset',
    deviatedOrders: 'Poikkeamatilaus',
    all: 'Kaikki',
    noSelection: 'Ei valintaa',
    selectFile: 'Valitse tiedosto',
    noFileSelected: 'Ei tiedostoa',
    updateSelectedFile: 'Päivitä tiedosto',
    jobList: {
      title: 'Edellinen vienti',
      content: 'sisältö',
      created: 'Luotu',
      user: 'Käyttäjä',
      dates: 'Päivämäärä',
      download: 'Ladattu',
      exporting: 'Vietään...',
      exportFailed: 'Vienti epäonnistui',
      updated: 'Updated',
      previousImports: 'Aiemmat tuonnit',
      fileName: 'Tiedoston nimi',
      numberOfOrders: 'Tilausten määrä',
      imported: 'Tuotu',
      viewOrders: 'Näytä tilaukset',
      lastImports: 'Luetellaan viimeiset {{count}} tuontia'
    },
    exportStartedNotification: 'Vienti aloitettu',
    exportStartedNotificationMessage: 'Raporttisi on saatavilla heti, kun se on käsitelty.',
    exportFailedNotification: 'Vienti epäonnistui, yritä myöhemmin uudelleen',
    refreshData: 'Päivitä tiedot olemassa olevaan raporttiin',
    refreshDataDescription:
      'Valitse tämä vaihtoehto, jos olet jo luonut olemassa olevan raportin tietyille päivämäärille',
    types: {
      SHIPMENT_EXPORT: 'Toimitukset',
      AIREXPRESS_SHIPMENTS: 'Vie lähetykset (sisältää AirExpress datan)',
      COURIER_WORK_REPORT: 'Kuljettajan työraportti',
      SOCIAL_CONTROL: 'Sosiaalinen valvonta',
      LOCATION_LIST: 'Sijaintiluettelo',
      ORDER_EXPORT: 'Tilauksen vienti',
      ORDER_DEVIATION_EXPORT: 'Tilaus (poikkeama)',
      ORDER_EXPORT_ANONYMOUS: 'Tilaus (anonyymit tiedot)',
      ORDER_PARTNER_EXPORT: 'Tilaus (kumppaneille)',
      DEVIATION_EVENTS: 'Poikkeamatapahtumat',
      OPTIMIZATION_RESULTS: 'Optimointitulokset',
      FINANCIAL_REPORT: 'Talousraportti'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Vie lähetykset',
      AIREXPRESS_SHIPMENTS: 'Lataa lähetykset(sisältää AirExpress datan)',
      COURIER_WORK_REPORT: 'Kuljettajan työraportti',
      SOCIAL_CONTROL: 'Vie sosiaalinen hallinta',
      LOCATION_LIST: 'Vie sijaintiluettelo',
      ORDER_EXPORT: 'Vie tilaus',
      ORDER_DEVIATION_EXPORT: 'Vie tilaus poikkeamalla',
      ORDER_EXPORT_ANONYMOUS: 'Vie tilaus nimettömänä',
      ORDER_PARTNER_EXPORT: 'Vie kumppanitilaus',
      DEVIATION_EVENTS: 'Vie poikkeamatapahtumat',
      OPTIMIZATION_RESULTS: 'Vie optimointitulokset',
      FINANCIAL_REPORT: 'Vie talousraportti'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: 'Tämä sisältää AirExpress datan, normaalin datan lisäksi',
      ORDER_EXPORT:
        'Jos haluat käyttää dataa testiympäristöissä, suosittelemme "Tilaa (anonymisoitu data)"-vaihtoehtoa',
      ORDER_DEVIATION_EXPORT:
        'Jos haluat käyttää dataa testiympäristöissä, suosittelemme "Tilaa (anonymisoitu data)" -vaihtoehtoa',
      ORDER_EXPORT_ANONYMOUS: 'Voidaan käyttää, jos tietoja tuodaan testiympäristöön',
      ORDER_PARTNER_EXPORT: 'Muokattu Excel-tiedosto, jota voidaan käyttää Opterin tuontiin',
      OPTIMIZATION_RESULTS: '',
      FINANCIAL_REPORT: 'Sisältää lisäarkin, jossa tilaukset on eritelty veloitusrivein soveltuvin osin'
    }
  },
  unit: {
    new: 'Uusi unit',
    edit: 'Muokkaa unit',
    addCourier: 'Lisää lähetti',
    hideAddCourier: 'Piilota lisää lähetti',
    requiresCourierUsers: 'Pitäisi olla vähintään yksi kuriiri',
    created: 'Unit on luotu',
    updated: 'Unit on päivitetty',
    search: 'Etsi yksiköitä muilta osastoilta...',
    unitsInCurrentDepartment: 'Yksiköt nykyisessä osastossa',
    unitsInOtherDepartments: 'Muiden osastojen yksiköt',
    addCourierToUnit: 'Save courier to unit',
    clearSearch: 'Tyhjennä haku',
    noCouriersFound: 'Lähettejä ei löytynyt',
    addCourierToUnitSearch: 'Voit etsiä lähettejä vain niistä osastoista, joihin sinulla on pääsy',
    addedCouriers: 'Lisätyt lähetit ({{count}})',
    newCourier: 'Luo uusi lähetti',
    name: 'Nimi',
    unitId: 'Yksikkö-ID',
    unitId_glow: 'Alystra-ID',
    couriers: 'Lähetit',
    active: 'Aktiivinen',
    vehicle: 'Ajoneuvo',
    deleteQuestion: 'Poista yksikkö nykyisestä osastosta',
    deleteDescription: 'Yksikkö voidaan yhdistää uudelleen myöhemmin, jos se on edelleen yhteydessä muihin osastoihin',
    unassigned: 'Yksikkö poistettiin onnistuneesti osastosta',
    setDefaultVehicle: {
      add: 'Lisää ajoneuvo',
      change: 'Vaihda ajoneuvo',
      title: 'Lisää ajoneuvo yksikköön {{unit}}',
      selectVehicle: 'Valitse ajoneuvo',
      addSuccess: 'Ajoneuvo lisättiin onnistuneesti yksikköön {{unit}}',
      changeSuccess: 'Ajoneuvo vaihdettiin onnistuneesti yksikössä {{unit}}'
    }
  },
  courier: {
    isActive: 'On aktiivinen',
    canLogIn: 'Voi kirjautua sisään',
    secureLoginEnabled: 'Kirjautuminen pankkitunnuksilla',
    recipientName: 'Vastaanottajan nimi',
    created: 'Lähetti on luotu',
    updated: 'Lähetti on päivitetty',
    courier: 'Lähetti',
    couriers: 'Lähetit',
    noCouriers: 'Ei lähettejä',
    courierWithCount: '{{count}} lähetti',
    courierWithCount_plural: '{{count}} lähettiä',
    numberOfCouriers: 'Löydetty {{number}} lähetit',
    multipleDrivers: 'Useampi kuljettaja',
    missingId: 'ID puuttuu',
    missingName: 'Nimi puuttuu',
    search: 'Etsi lähettejä',
    new: 'Uusi lähetti',
    edit: 'Muokkaa lähettejä',
    name: 'Nimi',
    alystraId: 'Alystra-ID',
    phoneNumber: 'Puhelinnumero',
    capacity: 'Kapasiteetti',
    capacityLabel: 'Kapasiteetti (määrä)',
    weightCapacity: 'Painokapasiteetti (kg)',
    volumeCapacity: 'Tilavuuskapasiteetti (m³)',
    maxRangeKm: 'Toimintasäde (km)',
    color: 'Väri',
    resetColor: 'nollaa väri',
    speedFactor: 'Nopeustekijä',
    speedFactorExplanation: 'alhainen = hitaampi',
    serviceTimeFactor: 'Palveluaikatekijä',
    serviceTimeFactorExplanation: 'alhainen = nopeampi',
    defaultStopLocation: 'Oletukseksi asetettu pysähdyspaikka',
    defaultStartLocation: 'Oletukseksi asetettu aloituspaikka',
    slotNumDeliveries: '{{count}} toimitus',
    slotNumDeliveries_plural: '{{count}} toimitusta',
    slotNumPickups: '{{count}} nouto',
    slotNumPickups_plural: '{{count}} noutoa',
    deliverPerformButtonLabel: 'Toimittaa',
    handoverPerformButtonLabel: 'Luovuttaa',
    deviationPerformButtonLabel: 'Poikkeama',
    deliverWithoutSignatureButtonLabel: 'Ilman allekirjoitusta',
    deliverWithSignatureButtonLabel: 'Allekirjoitus',
    deviateNotAtHomeButtonLabel: 'Ei kotona',
    deviateLocNotFoundButtonLabel: 'Sijaintia ei löytynyt',
    deviateRecipientDeclinedButtonLabel: 'Vastaanottaja ei halunnut sitä',
    deviateTimeWindow: 'Aika ei riittänyt toimittamaan pyydetyssä aikaikkunassa',
    addressNotFound: 'Osoitetta ei löytynyt',
    deliverySpotUnavailable: 'Sovittu toimitus paikka ei ole mahdollinen',
    deviation: {
      'H-12': 'Lähetys yritettiin toimittaa, mutta vastaanottaja ei ollut paikalla',
      'H-11': 'Paketissa toimitusyritys, mutta osoitetta ei löytynyt',
      'H-10': 'Paketissa toimitusyritys, mutta vastaanottajaa ei tavoitettu osoitteesta',
      'H-13': 'Lähetys yritettiin toimittaa, mutta sen vastaanottamisesta kieltäydyttiin',
      '7-10': 'Tavaroita ei voitu noutaa puutteellisen osoitteen takia',
      '7-12': 'Lähettäjä ei ollut tavoitettavissä. Tavaroita ei ole noudettu',
      '7-82': 'Tavarat eivät olleet valmiit noudettavaksi',
      'V-41-K': 'Lähetys on viivästynyt, mutta se toimitetaan eteenpäin mahdollisimman pian',
      'H-07-R': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-07-Z': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-08-Z': 'Alaikäinen henkilö',
      'H-09-Z': 'Henkilöllisyystodistus puuttuu',
      'VI-16-X': 'Aika ei riittänyt toimittamaan pyydetyssä aikaikkunassa',
      'H-11-Z': 'Osoitetta ei löytynyt',
      'H-BL-A': 'Sovittu toimitus paikka ei ole mahdollinen',
      'V-100-CA': 'Ei saatavilla olevaa pakettiautomaattia. Paketti toimitetaan kotiinkuljetuksena',
      'V-100-CI': 'Ei vapaata lokeroa',
      'V-80-CI': 'Paketti ylittää sallitut mitat/painon',
      'V-99-CI': 'Muu tuntematon',
      'V-AO-CI': 'Automaatti on epäkunnossa'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} valmiina',
    lockCode: 'Ovikoodi',
    flexDelivery: 'Simplified delivery -toimitus',
    fuelType: 'Polttoainetyyppi',
    fuelTypes: {
      diesel: 'Diesel',
      gasoline: 'Bensiini',
      natural_gas: 'Maakaasu',
      ethanol: 'Etanoli',
      bio_gas: 'Biokaasu',
      electric: 'Sähkö',
      bicycle: 'Polkupyörä',
      hydrogen: 'Vety',
      rme: 'RME',
      hvo: 'HVO'
    },
    fuelCategory: 'Polttoaineluokka',
    fuelCategories: {
      fossil: 'Fossiilinen polttoaine',
      fossil_free: 'Fossiilivapaa polttoaine',
      zero_emissions: 'Päästöt nolla'
    },
    latestLogin: 'Viimeisin kirjautuminen',
    latestVersion: 'Uusin versio',
    failedSocialControl: 'Epäilyttävä, tai päihtynyt vastaanottaja',
    failedIdControl: 'Henkilöllisyystodistus puuttuu',
    failedAgeControl: 'Alaikäinen henkilö',
    incompletePickupAddress: 'Tavaroita ei voitu noutaa puutteellisen osoitteen takia.',
    custNotAvailableAtPickupAddress: 'Lähettäjä ei ollut tavoitettavissä. Tavaroita ei ole noudettu.',
    goodsNotReadyAtPickupForCust: 'Tavarat eivät olleet valmiit noudettavaksi.',
    shipmentNotAvailableAtTerminal: 'Lähetys on viivästynyt, mutta se toimitetaan eteenpäin mahdollisimman pian.',
    registrationNumber: 'Rekisterinumero',
    commercialTrafficEligibility: 'Kaupallinen liikenne kelpoisuus',
    vehicleBrand: 'Autonmerkki',
    vehicleModel: 'Autonmalli',
    vehicleControl: 'Ajoneuvon tarkastus',
    vehicleControlComment: 'Ajoneuvon tarkastuksen kommentti',
    euroClass: 'Euro-luokka',
    alcoholDeliveryEducation: 'Alkoholin kuljetus koulutus',
    approved: 'Hyväksytty',
    notApproved: 'Ei Hyväksytty',
    couriersInCurrentDepartment: 'Kyseisen osaston kuriirit',
    editCourier: 'Muokkaa kuriiria:',
    courierUserUpdated: 'Kuriirin tiedot päivitetty onnistuneesti.',
    courierScanEnabled: 'Skannaa',
    noCouriersExplain: 'Emme löytäneet tuloksia. Kokeile etsiä toisella hakusanalla',
    unitId: 'Yksikkö-ID',
    minShipmentWeightKg: 'Tämän ajoneuvon vähimmäislähetyspaino (kg)',
    maxShipmentWeightKg: 'Tämän ajoneuvon enimmäislähetyspaino (kg)',
    noAvailableHatchInParcelBoxMachine: 'Ei vapaata lokeroa',
    packageDoesNotFitInParcelBox: 'Paketti ylittää sallitut mitat/painon',
    unknownErrorForParcelBoxDelivery: 'Muu tuntematon',
    errorInParcelBoxMachine: 'Automaatti on epäkunnossa',
    euroClassTypes: { euroEL: 'Euro EL', euro5: 'Euro 5', euro6: 'Euro 6' },
    co2: 'CO2',
    inCurrentDepartment: 'Nykyinen osasto',
    couriersInOtherDepartments: 'Lähetit muissa osastoissa',
    inOtherDepartments: 'Eri osastot',
    confirmConnect: 'Yhdistä lähetti nykyiseen osastoon',
    searchForCouriers: 'Etsi lähettejä',
    dateOfBirth: 'Syntymäaika',
    dateNotValid: 'Virheellinen päivämäärä',
    dateOfBirthWithFormat: 'Syntymäaika (vvvv-kk-pp)',
    occupationalInjuryInsurance: 'Tapaturmavakuutus',
    breakTime: 'Taukoaika',
    breakDuration: 'Tauon kesto (min)',
    timeWindow: 'Tauon aloitusaika (aikaisin ja myöhäisin)',
    breakTimeDescription:
      'Kentissä <i>Tauon aloitusaika (aikaisin ja myöhäisin)</i> asetat aikaisimman ja myöhäisimmän ajan, jolloin tauko voi alkaa. Esimerkiksi asetus 11:00 - 17:00 tarkoittaa, että kuljettajan on aloitettava tauko viimeistään klo 17:00.',
    between: 'Välillä',
    editBreakTime: 'Muokkaa taukoaikaa',
    updateBreakTime: 'Päivitä taukoaika',
    latestBreakStart: 'Myöhäisin tauon aloitus',
    radioButtons: { noBreakTime: 'Ei taukoa', timeWindow: 'Aikaväli', workingTime: 'Työaika' },
    workingTimeDescription:
      'Asetat maksimityöajan ennen kuin kuljettajan on pidettävä tauko. Esimerkiksi 8 tunnin jälkeen kuljettajan on pidettävä 30 minuutin tauko.',
    maxWorkingTime: 'Maksimi työaika',
    selectMaxWorkingTime: 'Valitse maksimityöaika',
    hours: '{{hours}} tuntia',
    breakTooltip: 'Voit asettaa vain yhden taukotyypin per yksikkö'
  },
  slot: {
    minutesDelay: 'minuuttia viivästynyt',
    minutesAhead: 'minuuttia aikaisessa',
    minutes: 'minuuttia',
    time: 'Aika',
    created: 'Luotu',
    assigned: 'Annettu toimitettavaksi',
    lock: 'Lukko',
    lockDescription:
      'Kun toimitusajankohta on lukittu, sitä ei voi käyttää enää tulevissa reitin optimoinneissa, eikä sitä voi muokata.',
    toggleVisibleSlotDescription: 'Piilota toimitusajankohta.',
    inProgress: 'Käynnissä',
    onHold: 'Odottaa',
    complete: 'Valmis',
    unknownState: 'Tuntematon tila',
    courierSelect: 'Valitse lähetti',
    unitSelect: 'Valitse yksikkö',
    printableSlotList: 'Tulostettava toimitusaikalista',
    followsRoute: 'Seuraa',
    doesNotFollowRoute: 'Poiketa',
    hasLocation: 'Näkyvissä',
    hasNoLocation: 'Ei näkyvissä',
    showActiveSlots: 'Näytä käynnissä olevat',
    showSlotNotStarted: 'Näytä ei aloitetut',
    showCompletedSlot: 'Näytä valmiit',
    showPerfectSlot: 'Näytä täydelliset',
    filterSlots: 'Suodata toimitusajankohtia',
    planningCompleted: 'Valmis',
    addMapArea: 'Valitse alue',
    editMapArea: 'Muokkaa alue'
  },
  login: {
    heading: 'Kirjaudu sisään',
    phone:
      "Lisää Glow'hun rekisteröity puhelinnumerosi mukaan lukien maanumero (esim. +358...), niin lähetämme sinulle SMS-tokenin.",
    code: 'Token lähetetty, jos tälle numerolle on rekisteröity käyttäjä. Se on voimassa 5 minuutia. Anna token.',
    requestNewCode: 'Pyydä toista koodia',
    verifyFailed:
      'Koodi tai puhelinnumero on väärin, tai liian monta kertaa mennyt väärin. Yritä uudestaan tai pyydä uusi koodi.',
    requestFailed: 'Tokenia ei voitu lähettää, yritä uudelleen.',
    verifyTokenButton: 'Vahvista koodi',
    requestTokenButton: 'Pyydä koodi',
    privacyLink: 'Tietosuojakäytäntö',
    passwordWrong: 'Virheellinen salasana',
    customer: 'Olen asiakas',
    forgotPassword: 'Unohditko salasanasi?',
    tryApp: 'Kokeile uutta sovellusta!',
    appDownload: 'Lataa kuljettajan sovellus täältä, niin saat käyttöösi enemmän toimintoja!',
    appInfo:
      'Oletko kuljettaja, jolla on Android-puhelin? Lataa kuljettajan sovellus <1>täältä</1>, niin saat käyttöösi enemmän toimintoja!',
    webApp: 'Web-sovellus',
    note: 'Huomautus! Käytä vain kuriirisovelluksen web-versiota, jos et jostain syystä käytä iOS- ja Android-sovelluksia',
    resendToken: 'Lähetä uudelleen',
    submitPassword: 'Lähetä salasana',
    loginAsCustomer: 'Kirjaudu sisään asiakkaana',
    loginAsPlanner: 'Kirjaudu sisään suunnittelijana',
    token: 'Koodi',
    password: 'Salasana',
    tokenRequired: 'Koodi vaaditaan',
    forgotPasswordDescription:
      'Jatkaaksesi sinun on asetettava salasana. Lähetämme sinulle sähköpostin, jossa on linkki tämän asettamiseksi. Jos sähköpostisi on muuttunut, sinun on otettava yhteyttä ylläpitäjään sen muuttamiseksi.',
    sendEmailDescription:
      'Jatkaaksesi tarvitsemme sähköpostisi. Lähetämme sinulle vahvistuslinkin annettuun osoitteeseen. Tällä linkillä voit asettaa 2-vaiheisen salasanasi.',
    emailFormatValidation: 'Sähköpostin on oltava kelvollisessa muodossa',
    sendEmailSuccess: 'Sähköpostin pitäisi saapua pian',
    sendEmailFailed: 'Sähköpostin lähetys epäonnistui, yritä uudelleen',
    setYourPassword: 'Aseta salasanasi',
    sendEmail: 'Lähetä sähköposti',
    passwordsDontMatch: 'Salasanat eivät täsmää',
    passwordNotMeetRequirements: 'Salasana ei täytä vaatimuksia',
    passwordRequired: 'Salasana vaaditaan',
    newPassword: 'Uusi salasana',
    repeatPassword: 'Toista salasanasi',
    lowercaseLetter: 'Pieni kirjain',
    uppercaseLetter: 'Iso kirjain',
    numberCharacter: 'Numero',
    minimumCharacters: 'Vähintään 15 merkkiä',
    passwordDescription: 'Voit nyt asettaa salasanasi, sen on sisällettävä seuraavat'
  },
  error: {
    '404Title': 'Sivua ei löydy',
    '404Message': 'Emme löydä etsimääsi sivua',
    '500Message': '{{error}} - Pahoittelut, emme löydä tietojasi... yritämme uudelleen minuutin kuluttua...',
    title: 'Jotain meni vikaan!',
    dateRequiredWhenSecureLogin: '*Vaadittu jos kirjautuminen pankkitunnuksella on aktivoitu',
    dateNotValid: 'Virheellinen päivämäärä',
    errorMessage:
      'Olemme pahoillamme! Tämä ei sujunut kuten piti – olemme ilmoittaneet virheestä järjestelmänkehittäjille...',
    errorClickToReload: 'Klikkaa tästä ladataksesi sivun uudelleen',
    phoneNumber: 'Väärä puhelinnumero! Oikea on +358 12345678.',
    phoneNumberPrefix: 'Sinun täytyy antaa maanumero, esim. +358...',
    somethingWentWrong: 'Jokin meni vikaan tallennettaessa...',
    notAValidNumber: 'Virheellinen numero',
    notAValidAlphaNumeric: 'Täytyy olla numeroita ja/tai kirjaimia',
    notAValidDate: 'Virheellinen päivämäärä (vvvv-kk-pp)',
    notAValidTime: 'Virheellinen kellonaika (hh:mm)',
    notAValidDuration: 'Täytyy olla kelpaava aika (mm:ss)',
    notAValidZipCode: 'Ei kelvollinen postinumero',
    notAValidTimezone: 'Aikavyöhyke ei ole kelvollinen. Yrittää "Europe/Helsinki" tai samankaltainen',
    somethingWentWrongOnTheServer: 'Jokin meni palvelimella vikaan...',
    somethingWentWrongWithTheRequest: 'Pyynnössä oli jokin vika...',
    somethingWentWrongWithTheResponse: 'Vastauksessa oli jotain vikaa..',
    selectAtLeastOne: 'Sinun täytyy valita vähintään yksi vaihtoehto',
    timeInWrongFormat: 'Aika ei ole mahdollinen',
    invalidEmail: 'Sinun on annettava kelvollinen sähköpostiosoite, esim. name@gmail.com',
    requiredBreakDuration: 'Sinun on syötettävä tauon kesto',
    notAValidTimeWindow: 'Aikaisimman tauon on alettava ennen myöhäisintä',
    requiredEarliestStartBreak: 'Sinun on syötettävä aikaisin tauon aloitus',
    requiredLatestStartBreak: 'Sinun on syötettävä myöhäisin tauon aloitus',
    requiredIfWorkingTimeDuration: 'Sinun on syötettävä enimmäistyöaika',
    invalidUrlParameterCombination: 'Tämä URL-parametriyhdistelmä on virheellinen, eikä sivua voida näyttää',
    numberCannotContainDecimals: 'Luku ei saa sisältää desimaaleja'
  },
  loading: 'Yritämme paikantaa dataasi...',
  newAppVersion: 'Glow just got a little better, we are fetching the new version now...',
  loadingReceiptList: 'Kuittilistoja luodaan...',
  invoicing: { departmentInvoicingInProgress: 'Laskutus käynnissä...' },
  months: {
    january: 'Tammikuu',
    february: 'Helmikuu',
    march: 'Maaliskuu',
    april: 'Huhtikuu',
    may: 'Toukokuu',
    june: 'Kesäkuu',
    july: 'Heinäkuu',
    august: 'Elokuu',
    september: 'Syyskuu',
    october: 'Lokakuu',
    november: 'Marraskuu',
    december: 'Joulukuu'
  },
  weekdays: {
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai'
  },
  weekdaysShort: {
    sunday: 'Su',
    monday: 'Ma',
    tuesday: 'Ti',
    wednesday: 'Ke',
    thursday: 'To',
    friday: 'Pe',
    saturday: 'La'
  },
  maps: { addressLookup: 'Osoitehaku' },
  notifications: {
    dismiss: 'Hylkää',
    dismissAll: 'Hylkää kaikki',
    error: 'Virhe',
    reload: 'Päivitä',
    reloadCourier: 'Paina tästä päivittääksesi näytön',
    navigate: 'Navigoi sivulle',
    networkError:
      'Vaikuttaa siltä, että tällä hetkellä on verkko-ongelmia, ne voivat olla tilapäisiä. Sivu tulee joka tapauksessa päivittää.',
    networkErrorCourier: 'Olet ollut offline-tilassa, ole hyvä ja päivitä sivu.'
  },
  grid: {
    columns: {
      addressWashRecipientId: 'Vastaanottajan tunnus',
      addressWashAddressId: 'Osoitetunnus',
      palletSpace: 'Lavapaikka',
      department: 'Osasto',
      deadline: 'Aikaraja',
      failedReason: 'Virheviesti',
      name: 'Nimi',
      senderName: 'Lähettäjän nimi',
      puearly: 'N',
      pulate: 'N',
      delearly: 'T',
      dellate: 'T',
      zip: 'Posti nro',
      type: 'Tyyppi',
      estimated: 'Arvioitu aika',
      timeWindow: 'aikaikkuna',
      onCar: 'autossa',
      frequency: 'Toistuvuus',
      startDate: 'Aloituspäivä',
      endDate: 'Päättymispäivä',
      from: 'Alkaen',
      to: 'Asti',
      days: 'Päivät',
      holidays: 'Pyhät',
      startdate: 'Aloitus päivämäärä',
      enddate: 'Lopetus päivämäärä',
      orders: 'Tilaukset',
      id: 'Tilausnumero',
      shipmentId: 'Tilausnumero',
      customer: 'Asiakas',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      service: 'Palvelu',
      address: 'Osoite',
      zipcode: 'Posti nro',
      area: 'Alue',
      weight: 'Paino',
      volume: 'Tilavuus{{unit}}',
      qty: 'Kpl',
      preplanned: 'Kuljettaja',
      preplannedRouteTemplate: 'Reitti',
      group: 'Ryhmä',
      totalOrders: 'Tilauksia yhteensä',
      ordersFailed: 'Tilauksia epäonnistunut',
      date: 'Päivämäärä',
      finished: 'Valmis',
      failed: 'Epäonnistunut',
      ordersInvoiced: 'Tilauksia laskutettu',
      start: 'Aloitus',
      stop: 'Lopetus',
      pickupContactPerson: 'Nouto yhteyshenkilö',
      pickupPhone: 'Nouto puhelin',
      deliveryContactPerson: 'Toimitus yhteyshenkilö',
      deliveryPhone: 'Toimitus puhelin',
      contactPerson: 'Yhteyshenkilö',
      phone: 'Puhelin',
      deliveryReferencePlaceholder: 'Vastaanottajan viite',
      specificationDescriptionPlaceholder: 'Tiedot tavarasta',
      groupName: 'Ryhmän nimi',
      customerNumber: 'Asiakasnumero',
      orderNote: 'Muistiinpanot',
      returned: 'Palautettu',
      price: 'Hinta',
      slotName: 'Toimitusajankohta',
      deliveryEtaSmsSentAt: 'Toimitusaika SMS lähetetty',
      trip: 'Matka',
      addresses: {
        title: 'Osoitteet',
        search: 'Etsi osoite',
        searchAddresses: 'Etsi osoitteita',
        filterAddresses: 'Suodata osoitteita',
        deleteConfirmation: 'Oletko varma että haluat poistaa tämän osoitteen?',
        deleteMultipleConfirmation: 'Oletko varma että haluat poistaa kaikki valitut osoitteet?',
        deleteSuccess: 'Osoite poistettu',
        deleteMultipleSuccess: 'Osoitteet poistettu',
        delete: 'Poista',
        name: 'Nimi',
        phone: 'Puhelinnumero',
        address: 'Osoite',
        zipArea: 'Kaupunki',
        zipCode: 'Postinumero',
        country: 'Maa',
        contactPerson: 'Yhteyshenkilö',
        instructions: 'Ohjeet',
        deleteAddress: 'Poista osoite',
        deleteMultipleAddresses: 'Poista osoitteet ({{count}})',
        loc: 'S',
        locTitle: 'Sijainti'
      },
      orderGrid: { extraInfo: 'Lisätieto' },
      dispatch: { id: 'Tilausnumero' },
      orderSource: 'Tilaustapa',
      postalCodes: '# postinumero',
      displayEndCustomerPriceShd: 'Näytä asiakashinta',
      courier: 'Lähetti',
      plannedDeliveryDate: 'Suunniteltu aika',
      orderedDeliveryDate: 'Tilaus päivä',
      lastUpdatedAt: 'Viimeksi päivitetty',
      route: 'Reitti',
      instructions: 'Ohjeet',
      pickupDuration: 'Nouto aika',
      deliveryDuration: 'Vienti aika',
      originalPickupAddress: 'Alkuperäinen lähettäjän osoite',
      originalPickupZipCode: 'Alkuperäinen lähettäjän postinumero',
      originalPickupZipArea: 'Alkuperäinen lähettäjän kaupunki',
      originalPickupCountry: 'Alkuperäinen lähtömaa',
      originalDeliveryAddress: 'Alkuperäinen toimitusosoite',
      originalDeliveryZipCode: 'Alkuperäinen toimituksen postinumero',
      originalDeliveryZipArea: 'Alkuperäinen toimituskaupunki',
      originalDeliveryCountry: 'Alkuperäinen toimitusmaa',
      packagesArrivedAtDip: 'Saapunut',
      packagesCollected: 'Noudettu',
      packagesDelivered: 'Toimitettu',
      originalDeliveryEarliest: 'Alkuperäinen T',
      originalDeliveryLatest: 'Alkuperäinen T',
      preAdvices: 'Preadvices',
      preAdviceScheduledDate: 'Preadvice scheduled',
      hasPreAdviceSettings: 'Preadvice settings apply',
      arrivedAtHub: 'Arrived at hub'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Asiakkaan viite – Tämä kenttä näkyy asiakkaan laskussa',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – Tämä kenttä näkyy asiakkaan laskussa',
    invoiceInfoPlaceholder: 'Laskutustiedot – Tämä kenttä näkyy asiakkaan laskussa',
    pickupTimePlaceholder: 'Aikaisin noutoaika'
  },
  instant: {
    title: 'Ajojärjestely',
    openMap: 'Avoin kartta',
    group: 'Ryhmä valittu',
    editGroup: 'Muokkaa ryhmää',
    groupName: 'Ryhmän nimi',
    groupOrders: 'Ryhmän tilaukset',
    viewGroup: 'Näytä ryhmä',
    ordersToGroup: 'Lisää tilaukset ryhmään',
    fixedOrder: 'Korjattu toimitus',
    paidTogether: 'Maksetaan yhdessä',
    saveGroup: 'Tallenna Ryhmä',
    ungroup: 'Pura ryhmä',
    removeSelected: 'Poista valitut',
    noCourierSelected: 'Lähettiä ei valittu',
    noOrderSelected: 'Tilausta ei valittu',
    workingHours: 'Työtunnit',
    chatButton: 'Chatti kuljettajan kanssa',
    offeredShipments: 'Tarjotut lähetykset',
    rejectedShipments: 'Hylätyt lähetykset',
    rejectedShipmentsSnackbar: '"{{count}}" {{name}} hylkäämä lähetys',
    rejectedShipmentsSnackbar_plural: '"{{count}}" {{name}} hylkäämää lähetystä',
    toBeDone: 'Tulevat noudot ja toimitukset',
    finishedRoutePoints: 'Hoidetut noudot ja toimitukset',
    suggestedRouteApplied: 'Ehdotettu reitti on otettu käyttöön',
    suggestedRouteCancelled: 'Ehdotettu reitti on peruttu',
    reorderValidationError: 'Ehdotettu reitti on virheellinen',
    planSlot: 'Reittisuunnitelma',
    columnSettings: { reset: 'Nollaa', settings: 'Asetukset', hideColumns: 'Piilota sarakkeet' },
    confirmation: {
      heading: 'Tilausvahvistus',
      from: 'Nouto',
      to: 'Toimitus',
      service: 'Palvelu',
      pickup: 'Alkaen',
      delivery: 'Asti',
      shipmentNumber: 'Lähetyksen numero',
      printLabel: 'Tulosta etiketti',
      copyOrder: 'Kopioi tilaus',
      editOrder: 'Muokkaa tilausta'
    },
    goods: {
      letter: 'Kirje',
      bag: 'Pussi',
      smallbox: 'Pieni laatikko',
      mediumbox: 'Keskikokoinen laatikko',
      largebox: 'Iso laatikko',
      tube: 'Putkilo',
      quarterpallet: '1/4 lava',
      halfpallet: 'Teholava',
      fullpallet: 'Lava',
      suitcase: 'Matkalaukku',
      movingbox: 'Muuttolaatikko',
      trolley: 'Rullakko',
      parcel: 'Paketti'
    },
    instantGrid: {
      editColumnTooltip: 'Muokkaa tilausta',
      deleteColumnTooltip: 'Poista',
      unassignColumnTooltip: 'Poista kuljettajalta',
      dragColumnTooltip: 'Klikkaa ja vedä antaaksesi tehtävän lähetille',
      filtersActive: 'Suodatin päällä',
      clearFilters: 'Tyhjennä suodattimet',
      filterPlaceholder: 'suodata <enter>'
    },
    booking: {
      standaloneInvoiceCheckbox: 'Merkitse erilliseksi laskuksi',
      standaloneInvoiceDescription:
        'Valitsemalla tämän vaihtoehdon tämä tilaus käsitellään erikseen eikä yhdistetä muihin tilauksiin laskutusta varten.',
      addPackage: 'Lisää uusi paketti',
      removePackageRow: 'Poista paketti',
      cannotRemoveLastPackageRow: 'Viimeistä pakettia ei voi poistaa',
      copyPackageRow: 'Kopioi paketti',
      title: 'Tilaus',
      header: 'Tilaus',
      headerCreate: 'Uusi tilaus',
      headerEdit: 'Muokkaa tilausta',
      headerCopy: 'Kopioi tilaus',
      headerRestore: 'Palauta tilaus',
      customer: 'Asiakas',
      customerPlaceholder: 'Asiakastunnus',
      invalidSubcustomer: 'Subcustomer nimi on virheellinen',
      invoiceSplitPlaceholder: 'Lasku jaettu',
      invoiceSplitPlaceholderLegend: 'Valitse laskun osuus',
      shipmentOrPackageId: 'Lähetys tai paketti ID',
      creditBlockedCustomer: 'Ei luottotietoja',
      priceDetails: 'Hintatiedot',
      sender: 'Lähettäjä',
      setAsDefaultAddress: 'Aseta oletusosoitteeksi',
      defaultAddressUpdated: 'Oletusosoite päivitetty',
      recipient: 'Vastaanottaja',
      pickupNamePlaceholder: 'Nimi',
      pickupAddressPlaceholder: 'Osoite',
      pickupZipCodePlaceholder: 'Postinumero',
      pickupZipAreaPlaceholder: 'Kaupunki',
      pickupCountryPlaceholder: '-- Valitse maa --',
      pickupPhoneNumberPlaceholder: 'Puhelin',
      pickupContactPerson: 'Yhteyshenkilö',
      customerReference: 'Asiakkaan viite',
      pickupReferencePlaceholder: 'Asiakkaan viite – Tämä kenttä näkyy asiakkaan laskussa',
      pickupInformationPlaceholder: 'Noutotiedot',
      customerContact: 'Asiakaskontakti',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – Tämä kenttä näkyy asiakkaan laskussa',
      customerInfoPlaceholder: 'Yleiset asiakaskohtaiset tiedot',
      customerInfoHelper:
        'Tämä tieto on liitetty asiakkaaseen. Tehdyt muutokset näkyvät kaikissa asiakkaan tilauksissa.<br><br>Jos haluat lisätä tietoa vain tähän tilaukseen, käytä ”Lisätietoja” kohtaa.',
      deliveryNamePlaceholder: 'Nimi',
      deliveryAddressPlaceholder: 'Osoite',
      deliveryZipCodePlaceholder: 'Postinumero',
      deliveryZipAreaPlaceholder: 'Kaupunki',
      deliveryCountryPlaceholder: '-- Valitse maa --',
      deliveryPhoneNumberPlaceholder: 'Puhelin',
      deliverySecondPhoneNumberPlaceholder: 'Phone 2',
      deliveryReferencePlaceholder: 'Vastaanottajan viite',
      deliveryInformationPlaceholder: 'Toimitustiedot',
      orderNote: 'Muistiinpanot',
      invalidInvoiceSplit: 'Virheellinen laskun jakoteksti',
      clearData: 'Tyhjennä',
      dimensions: 'Tavara',
      weightPlaceholder: 'Paino (kg)',
      lengthPlaceholder: 'Pituus (cm)',
      widthPlaceholder: 'Leveys (cm)',
      heightPlaceholder: 'Korkeus (cm)',
      service: 'Palvelu',
      serviceCode: 'Palvelukoodi',
      servicePlaceholder: 'Palvelukoodi',
      serviceSelect: 'Valitse palvelu',
      serviceSelectDisabled: 'Palvelujen vaihtaminen HD-tilauksissa on tehtävä HappyFlow-muodossa',
      goodsEditDisabled: 'HD-tilausten tuotteisiin tehtävät muutokset on tehtävä HappyFlow´ssa',
      standardServices: '-- PERUSPALVELUT --',
      customizedServices: '-- ASIAKASKOHTAISET PALVELUT --',
      specificationDescription: 'Tavara',
      specificationDescriptionPlaceholder: 'Tiedot tavarasta',
      invoiceInfo: 'Laskutustiedot',
      invoiceInfoPlaceholder: 'Laskutustiedot – Tämä kenttä näkyy asiakkaan laskussa',
      manualInvoiceInfoPlaceholder:
        'Manuaalinen laskunkäsittely (jos tähän kenttään on täytetty laskutustiedot, tilausta ei laskuteta automaattisesti)',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      pickupDatePlaceholder: 'Noutopäivä',
      pickupTimePlaceholder: 'Noutoaika',
      deliveryDatePlaceholder: 'Toimituspäivä',
      deliveryTimePlaceholder: 'Toimitusaika',
      deliveryContactPerson: 'Yhteyshenkilö',
      restoreButton: 'Palauta',
      orderButton: 'Tilaus',
      editButton: 'Päivitä',
      copyButton: 'Tilaus',
      created: 'Tilaus on rekisteröity',
      edited: 'Tilaus on päivitetty',
      quickSelect: 'Nopea valinta',
      volume: 'Volyymi',
      totalVolume: 'Kokonaisvolyymi {{volume}}',
      totalWeight: 'Kokonaispaino {{weight}}',
      removeItem: 'Poista',
      copyItem: 'Kopioi',
      addItem: 'Lisää',
      calculatePrice: 'Laske hinta',
      priceExplanations: {
        header: 'Hinnan selitys',
        article: 'Artikla: {{article}}',
        priceListName: 'Hinta lista:: {{pricelist}}',
        explanations: 'Selitykset',
        noInfo: 'Hintatietoja ei ole saatavilla',
        details: 'Yksityiskohdat',
        notFound: 'Hintaselityksiä ei ole saatavilla.'
      },
      prePlan: 'Suunnittele',
      prePlannedToCourier: 'Valitse lähetti',
      prePlannedToRouteTemplate: 'Valitse reitti',
      prePlannedToRouteText:
        'Saatavilla olevat reitit riippuvat osastolle tallennetuista malleista. Jotta automaattinen reititys toimisi oikein, sinun on määritettävä reittiasetukset <0>osastotasolla</0> hallintasivuilla.',
      automaticDepartment: 'Automaattinen',
      departmentLabel: 'Osasto',
      notifications: 'Ilmoitukset',
      emailNotifications: 'Sähköposti ilmoitukset',
      eventRegistered: 'Rekisteröity',
      eventPickup: 'Nouto',
      eventDelivery: 'Toimitus',
      emailAddress: 'Sähköpostiosoite',
      emailRecipientText: 'Seuraavat sähköpostiosoitteet vastaanottavat ilmoituksia:',
      invalidEmail: 'Väärä sähköpostiosoite',
      editIndicator: 'Muokkaa',
      updateIndicator: 'Päivitä',
      copyIndicator: 'Kopioi',
      quantity: 'Määrä',
      additionalServices: 'Lisäpalvelut',
      paymentInformation: 'Maksutiedot',
      paidTogetherLabel: 'Maksetaan samasta?',
      groupingLabel: 'Lähetysten ryhmittäminen',
      groupIdPlaceholder: 'Ryhmän id',
      groupOrderPlaceholder: 'Ryhmän tilausnumero',
      addRecipient: 'Lisää ylimääräinen vastaanottaja',
      addPickup: 'Lisää ylimääräinen lähettäjä',
      customerPrice: 'Asiakas hinta',
      resourcePrice: 'Kuljettajan hinta',
      priceList: 'Hinta lista',
      manualPrice: 'Manuaalinen',
      total: 'Kaikki yhteensä',
      vehicleTypes: {
        car: 'Henkilöauto',
        bicycle: 'Polkypyörä',
        truck: 'Kuorma-auto',
        van: 'Pakettiauto',
        motorcycle: 'Moottoripyörä',
        cargobike: 'Rahtipyörä'
      },
      date: 'Päivämäärä',
      unNumber: 'YK-numero',
      hazardous: 'Luokka',
      group: 'Ryhmä',
      netWeight: 'Nettopaino',
      name: 'Nimi',
      restrictions: 'Rajoitukset',
      totalPoints: 'Kokonaispisteet'
    },
    courierSorting: { title: 'Suodatusperuste', alystraId: 'Kuljettajanumero', empty: 'Tyhjä', full: 'Täynnä' },
    courierFilter: {
      title: 'Lähettien suodatusperuste',
      all: 'Näytä kaikki',
      inactive: 'Piilota passiivinen',
      offline: 'Piilota offline',
      both: 'Piilota passiivinen ja offline-tilassa'
    },
    shipmentFilter: {
      title: 'Suodata lähetyksiä',
      showAll: 'Näytä kaikki',
      notCollected: 'Noutamatta',
      hideAll: 'Piilota kaikki'
    },
    offer: {
      carEmpty: 'Auto on parhaillaan tyhjä',
      suggestedDelivery: 'Ehdotetty toimitus järjestys',
      newShipments: 'Uudet tilaukset',
      manualRoute: 'Manuaalinen reitti',
      confirmRoute: 'Hyväksy reitti',
      assigning: 'Määrää',
      shipmentsTo: 'lähetystä kuljettajalle',
      shipmentTo: 'lähetys kuljettajalle'
    },
    useFlexible: 'Käytä joustavia toimitus aikoja',
    forceOptimizing: 'Pakota annettu toimitus järjestys',
    replanRoute: 'Suunnittele reitti uudelleen',
    recurring: {
      title: 'Vakiot',
      successMessage: '{{number}} lähetystä lisättiin valittuihin toistuviin ryhmiin',
      recurringOrders: 'Vakio tilaukset',
      includedOrders: 'Tilaukset sisältyvät aikatauluun',
      details: 'Henkilötiedot',
      editOriginal: 'Muokkaa alkuperäistä',
      copyOriginal: 'Kopioi alkuperäinen',
      notStarted: 'Ei alkanut',
      executing: 'Esittäen...',
      ended: 'Päättynyt',
      status: 'Tila',
      pending: 'Vireillä',
      failedOrders: 'Epäonnistuneet tilaukset',
      name: 'Nimi',
      frequency: 'Toistuvuus',
      frequencies: {
        weekly: 'Joka viikko',
        second_week: 'Joka toinen viikko',
        third_week: 'Joka kolmas viikko',
        monthly: 'Joka kuukausi'
      },
      daysOfWeek: 'Viikonpäivät',
      includeHolidays: 'sisällytä lomat',
      startDate: 'Aloituspäivä',
      endDate: 'Päättymispäivä',
      save: 'Tallenna',
      fromDate: 'Alkamispäivä',
      confirmDelete: 'Haluatko varmasti poistaa valitun(-t) toistuvan(-t) kuljetuksen(-t)?',
      from: 'Alkaen',
      createOrders: 'Luo tilaukset',
      showResult: 'Näytä tulos',
      startdate: 'Aloitus päivämäärä',
      enddate: 'Lopetus päivämäärä',
      orders: 'Tilaukset',
      failed: 'Epäonnistunut',
      reset: 'nollata',
      confirmReset: 'Haluatko varmasti nollata {{date}} toistuvat tilaukset?',
      startDateExplanation: 'Tilaukset luodaan samana päivänä kuin aloituspäivä'
    },
    corrections: {
      title: 'Muutokset',
      headline: 'Tilauksen muutokset ajalta {{date}}',
      accepted: 'Tilauksen muutos hyväksytty',
      rejected: 'Tilauksen muutos hylätty',
      approve: 'HYVÄKSY',
      decline: 'HYLKÄÄ',
      shipmentId: 'Tilausnumero',
      courier: 'Kuljettaja',
      from: 'Mistä',
      to: 'Mihin',
      details: 'Tietoja',
      pickupWaitingTime: 'Noudon odotusaika',
      deliveryWaitingTime: 'Toimituksen odotusaika',
      pickupLoadingTime: 'Noudettu',
      deliveryLoadingTime: 'Toimitettu',
      comment: 'Kommentti',
      weight: 'Paino',
      volume: 'Tilavuus',
      packages: 'Pakettimäärä',
      serviceCode: 'Palvelun koodi'
    },
    suggestedUnits: {
      unitName: 'Laitteen nimi',
      driverName: 'Kuljettajan nimi',
      slotName: 'Reitti',
      timeAtLocation: 'Aika kohteessa',
      distanceFromLocation: 'Etäisyys kohteesta',
      shipmentInformation: 'Lähetystiedot',
      suggestedVehicles: 'Suositellut ajoneuvot',
      suggestedVehiclesOnPickup: 'Ehdotetut ajoneuvot noutoon',
      suggestedVehiclesOnDelivery: 'Ehdotetut ajoneuvot toimitukseen',
      suggestedUnits: 'Ehdotetut yksiköt',
      noSuggestionsAvailable: 'Ei ehdotuksia saatavilla',
      pickupTime: 'Nouto Aika',
      deliveryTime: 'Toimitus Aika',
      filterBy: 'Rajoita'
    }
  },
  errors: {
    ALREADY_ENQUEUED_FOR_INVOICING: 'Jo lisätty laskutusjonoon',
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Osasto virheellinen alystra id',
    ENTITY_DOES_NOT_EXIST: 'Kohdetta ei ole olemassa',
    INVALID_DATE_RANGE: 'Virheellinen ajanjakso',
    INVALID_DELIVERY_LATITUDE: 'Virheellinen toimituksen leveysaste',
    INVALID_DELIVERY_LONGITUDE: 'Virheellinen toimituksen pituusaste',
    INVALID_LATITUDE: 'Virheellinen leveysaste',
    INVALID_LONGITUDE: 'Virheellinen pituusaste',
    INVALID_OR_MISSING_TIME: 'Virheellinen tai puuttuva aika',
    INVALID_PHONE_NUMBER: 'Virheellinen puhelinnumero',
    DUPLICATE_PHONE_NUMBER: 'Sama puhelinnumero!',
    DUPLICATE_PHONE_NUMBER_COURIER: 'Tällä puhelinnumerolla on jo rekisteröity kuljettaja',
    INVALID_PICKUP_LATITUDE: 'Virheellinen noudon leveysaste',
    INVALID_PICKUP_LONGITUDE: 'Virheellinen noudon pituusaste',
    INVALID_SLOT_TYPE: 'Virheellinen toimitusaikatyyppi',
    MISSING_ADDRESS: 'Osoite puuttuu',
    MISSING_ALYSTRA_ID: 'Alystra id puuttuu',
    MISSING_AREA: 'Alue puuttuu',
    MISSING_CAPACITY: 'Kapasiteetti puuttuu',
    MISSING_COLOR: 'Väri puuttuu',
    MISSING_CONSIGNMENT_INPUTS: 'Tavaraerää ei syötetty',
    MISSING_CONSIGNMENT: 'Tavaraerä puuttuu',
    MISSING_CONSIGNMENTS: 'Tavaraerät puuttuvat',
    MISSING_COURIER_ID: 'Lähetin id puuttuu',
    MISSING_COURIER: 'Sinun täytyy valita lähetti',
    MISSING_CREATED_AT: 'Luontitiedot puuttuvat',
    MISSING_CUSTOMER_ID: 'Asiakastunnus puuttuu',
    MISSING_CUSTOMER_REF: 'Asiakkaan viite puuttuu',
    MISSING_DATE: 'Päivä puuttuu',
    MISSING_DAYS: 'Päivät puuttuvat',
    MISSING_DEPARTMENT: 'Osasto puuttuu',
    MISSING_DESCRIPTION: 'Kuvaus puuttuu',
    MISSING_DEVIATION_TEXT: 'Poikkeaman teksti puuttuu',
    MISSING_END_LOCATION_LATITUDE: 'Päätepisteen leveysaste puuttuu',
    MISSING_END_LOCATION_LONGITUDE: 'Päätepisteen pituusaste puuttuu',
    MISSING_EVENT_DATA: 'Tapahtumadata puuttuu',
    MISSING_EVENT_TYPE: 'Tapahtumatyyppi puuttuu',
    MISSING_FILE_NAME: 'Tiedostonimi puuttuu',
    MISSING_FROM_DATE: 'Alkamispäivä puuttuu',
    MISSING_ID: 'ID puuttuu',
    MISSING_LOCATION_LATITUDE: 'Sijainnin leveysaste puuttuu',
    MISSING_LOCATION_LONGITUDE: 'Sijainnin pituusaste puuttuu',
    MISSING_LOCATION_POSITION: 'Sijainnin paikka puuttuu',
    MISSING_LOCATION_TIME: 'Sijainnin aika puuttuu',
    MISSING_NAME: 'Nimi puuttuu',
    MISSING_GROUP: 'Ryhmittely puuttuu',
    MISSING_PACKAGE_ID: 'Paketin tunnus puuttuu',
    MISSING_PHONE: 'Puhelinnumero puuttuu',
    MISSING_PLANNER_ID: 'Suunnittelutyökalun id puuttuu',
    MISSING_SERVICE_CODE: 'Palvelukoodi puuttuu',
    MISSING_SHIPMENT_ID: 'Lähetytunnus puuttuu',
    MISSING_SLOT_ID: 'Toimitusajankohdan ID puuttuu',
    MISSING_SLOT_TYPE: 'Toimitusajankohdan tyyppi puuttuu',
    MISSING_SMS_TOKEN: 'SMS-token puuttuu',
    MISSING_START_LOCATION_LATITUDE: 'Aloituspisteen leveysaste puuttuu',
    MISSING_START_LOCATION_LONGITUDE: 'Aloituspisteen pituusaste puuttuu',
    MISSING_TO_DATE: 'Päättymispäivä puuttuu',
    MISSING_ZIP_AREA: 'Postinumeroalue puuttuu',
    MISSING_ZIP_CODE: 'Postinumero puuttuu',
    MISSING_COUNTRY_CODE: 'Maatunnus puuttuu',
    NO_SUCH_USER: 'Ei käyttäjää',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parametri ei odotettua tyyppiä',
    PARAM_NULL_NOT_ALLOWED: 'Parametri nolla ei ole sallittu',
    QUERY_NAME_MISSING: 'Kyselyn nimi puuttuu',
    QUERY_PARAMS_MISSING: 'Kyselyn parametrit puuttuvat',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Käyttäjää ei ole tai rooli on väärä',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Käyttäjä on jo olemassa ja hän on toisessa roolissa',
    SLOT_CANNOT_BE_STARTED: 'Toimitusajankohtaa ei voi luoda',
    SLOT_CANNOT_BE_DELETED: 'Toimitusajankohtaa ei voi poistaa',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Lähetti ei kuulu osastoon',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Tavaraerää ei voi päivittää virheellisessä tilassa',
    ORDER_DOES_NOT_EXISTS: 'Tilausta ei ole olemassa',
    OPTIMIZE_ALREADY_RUNNING: 'Optimointi on jo käynnissä',
    CANNOT_CHANGE_ORDER: 'Tilausta ei voi muuttaa',
    NO_ACCESS: 'Ei pääsyä',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Ei relevanttia tavaraerää annettuihin toimitusajankohtiin',
    NO_RELEVANT_CONSIGNMENTS: 'Ei relevanttia tavaraerää annettuihin toimitusajankohtiin',
    NO_SUCH_QUERY: 'Kyselyä ei ole olemassa',
    EXCEL_MISSING_SHIPMENT_ID: 'Lähetystunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Asiakkaan viite puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Asiakastunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Osasto puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Paketin tunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_NAME: 'Nimi puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_PHONE: 'Virheellinen puhelinnumero solussa {{col}} rivillä {{row}}',
    EXCEL_MISSING_ADDRESS: 'Osoite puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Postinumero puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Kaupunki puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Alkamispäivä puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_TO_DATE: 'Päättymispäivä puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Alkamispäivä ei voi olla tätä päivää aikaisempi. Löytyy solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_TO_DATE: 'Päättymispäivä ei voi olla ennen tätä päivää. Löytyy solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Huomautus-SMS puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_SIZE: 'Koko puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ORDERS: 'Laskentataulukossa ei ole tilauksia.',
    EXCEL_MISSING_COUNTRY: 'Maatunnus tai nimi puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ei pääsyä osastoon "{{dept_ref}}" rivin {{row}} mukaisesti',
    EXCEL_MISSING_DESCRIPTION: 'Kuvaus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Noudon kuvaus puuttuu  solusta"{{cell}}" riviltä {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Toimituksen kuvaus puuttuu solusta "{{cell}}" riviltä {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Noudon sijainti ja aika puuttuvat',
    MISSING_EARLIEST_PICKUP_DATE: 'Aikaisin noutoaika puuttuu',
    MISSING_LATEST_PICKUP_DATE: 'Myöhäisin noutoaika puuttuu',
    MISSING_EARLIEST_DELIVERY_DATE: 'Aikaisin toimituspäivä puuttuu',
    MISSING_LATEST_DELIVERY_DATE: 'Myöhäisin toimituspäivä puuttuu',
    INVALID_EARLIEST_PICKUP_DATE: 'Virheelinen noutopäivä',
    INVALID_LATEST_PICKUP_DATE: 'Virheelinen noutopäivä',
    INVALID_EARLIEST_DELIVERY_DATE: 'Virheellinen toimituspäivä',
    INVALID_LATEST_DELIVERY_DATE: 'Virheellinen toimituspäivä',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Excelin API-tilauksia ei voi muokata',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Noutoajan tulee olla ennen toimitusaikaa',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Aikaisin vientiaika täytyy olla ennen myöhäisintä vientiaikaa',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Toimitus ei ole mahdollista pyhinä',
    MISSING_TEMPLATE_ID: 'Sivupohjan ID puuttuu',
    TEMPLATE_DOES_NOT_EXIST: 'Sivupohjaa ei ole olemassa',
    TEMPLATE_CANNOT_BE_DELETED: 'Sivupohjaa ei voi poistaa',
    MISSING_TEMPLATE_SLOTS: 'Sivupohjasta puuttuu toimitusajankohta',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Tämän niminen sivupohja on jo olemassa',
    COURIER_ALREADY_EXISTS: 'Lähetti, jolla on tämä puhelinnumero, on jo olemassa',
    ORDER_EXIST_FOR_SERVICE: 'Palvelua ei voi poistaa, koska tälle palvelulle on tilauksia',
    NO_SERVICE_FOUND: 'Lisää palvelu ennen asiakkaan mappauksen lisäämistä',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Poista asiakaspalvelun mappaus ennen palvelun poistamista',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Toimitusajankohta ei ole oikeassa tilassa tätä päivitystä varten',
    SLOT_CANNOT_BE_EDITED: 'Toimitusajankohta ei ole oikeassa tilassa tätä päivitystä varten',
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Virheellinen postinumero ja maatunnus',
    INVALID_BASIC_SERVICE_CODE: 'Virheellinen peruspalvelukoodi',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: 'Virheellinen aikaisimman noutoajan formaatti',
    INVALID_PICKUP_DATE_LATEST_FORMAT: 'Virheellinen myöhäisimmän noutoajan formaatti',
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Virheellinen myöhäisimmän toimituspäivän formaatti',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Virheellinen aikaisimman toimituspäivän formaatti',
    INVALID_POSTAL_CODE: 'Virheellinen postinumero',
    INVALID_MEASUREMENT_UNIT: 'Virheellinen mittayksikkö',
    MISSING_CONSIGNMENT_SET: 'Tavaraerän asettaminen puuttuu',
    MISSING_UPDATE_INDICATOR: 'Päivitysindikaattori puuttuu',
    MISSING_CONSIGNMENT_ID: 'Tavaraerän ID puuttuu',
    MISSING_REFERENCE_NO: 'Viitteen ID puuttuu',
    MISSING_PACKAGE: 'Paketti puuttuu',
    MISSING_DELIVERY_DATE_EARLIEST: 'Aikaisin toimituspäivä puuttuu',
    MISSING_DELIVERY_DATE_LATEST: 'Myöhäisin toimituspäivä puuttuu',
    MISSING_CONSIGNEE: 'Tavaran vastaanottaja puuttuu',
    MISSING_CONSIGNOR: 'Tavaran lähettäjä puuttuu',
    MISSING_POSTAL_CODE: 'Postinumero puuttuu',
    MISSING_CITY: 'Postinumeroalue puuttuu',
    MISSING_PICKUP_DATE_LATEST: 'Myöhäisin noutoaika puuttuu',
    MISSING_PICKUP_DATE_EARLIEST: 'Aikaisin noutoaika puuttuu',
    MISSING_CONSIGNOR_PARTY_ID: 'Tavaran lähettäjän tyyppitunnus puuttuu',
    INVALID_CUSTOMER_NO_MAPPING: 'Virheellinen asiakasnumeron mappaus',
    INVALID_ROUTING: 'Virheellinen reititys',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ei palvelukoodiin sopivaa palvelua',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ei viikonpäivään sopivaa palvelua',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ei nouto- ja toimitusaikoihin sopivaa palvelua',
    ORDER_WAS_AFTER_CUTOFF_TIME: 'Tilaus tehtiin vastaanottamisajan päätyttyä',
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: 'Nouto- ja toimitusajat pakollisia',
    ORDER_CANNOT_BE_MOVED: 'Lähetystä ei voi siirtää, sitä ei saa sijoittaa toimitusajankohtaan',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Määräpaikan osasto puuttuu',
    SHIPMENT_NOT_FOUND: 'Lähetystä ei löydy',
    DUPLICATE_EXCEL_IMPORT:
      'Sama tiedostonimi on jo tuotu tähän osastoon tänään. Näet tuonnin tulokset <0>täällä</0>. Muuta tiedostonimi, jos haluat tuoda uudelleen.',
    SHIPMENT_INVALID_QUERY:
      'Sesongin aikana haku mahdollisuuksia on rajoitettu toiminnan varmistamiseksi. Voit etsiä kaikki lähetykset yhdeltä päivältä tai lisää haku parametrejä.',
    DURATION_IS_MORE: 'Ole hyvä ja rajoita hakuasi {{maxDuration}} päivälle.',
    ADDRESS_CANNOT_BE_DELETED: 'Osoitetta käytetään oletus osoitteena joten sitä ei voida poistaa.',
    UNKOWN_ERROR: 'Muutoksia ei voitu tallentaa',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Virheellinen tila tai määrätty paikkaan',
    CANNOT_MOVE_ORDERS: 'Tilauksia ei voi siirtää',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Määräysosasto ei kelpaa',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Osastolta puuttuu osoitetietoja',
    MISSING_DELIVERY_PHONE_NUMBER: 'Puhelinnumero puuttuu',
    INVALID_DELIVERY_PHONE_NUMBER: 'Puhelinnumero ei ole kelvollinen',
    ROLE_IN_USE: 'Roolia ei voida poistaa, koska se on käytössä.',
    INVALID_NAME: 'Roolin nimen on oltava yksilöllinen. Rooli tällä nimellä on jo olemassa.',
    TEXT_CANNOT_BE_DELETED:
      'Tekstiä käytetään palvelussa, eikä sitä voi poistaa. Irrota teksti palvelusta poistaaksesi sen.',
    INVALID_INVOICING_STATUS: 'Tilaus on tilassa, joka ei ole laskutukseen kelvollinen',
    ORDER_NOT_READY_FOR_INVOICING: 'Tilaus ei ole valmis laskutukseen',
    ORDER_IS_PAUSED_FROM_INVOICING: 'Tilaus on tauolla laskutuksesta',
    ORDER_IS_EXCLUDED_FROM_INVOICING: 'Tilaus on poissuljettu laskutuksesta',
    MISSING_ORDER_CORRECTION: 'Tilauksesta puuttuu korjaus',
    INVALID_MULTILEG_STATE_FOR_INVOICING: 'Multileg-ryhmässä on tilauksia, jotka eivät ole valmiita laskutukseen',
    DUPLICATE: 'Dublikaattitiedot',
    MISSING_DEPARTMENT_DETAILS: 'Osastolta puuttuu alue- ja postinumerotiedot. Lisää ne ennen jatkamista.',
    INVALID_BANNER_NAME: 'Virheellinen bannerin nimi.',
    INVALID_WHATS_THIS_NAME: 'Virheellinen Mikä tämä on -modaalin nimi.',
    INVALID_CREDIT_ORDER: 'Tilausta ei voi hyvittää, ellei sitä ole laskutettu tai se on jo hyvitetty.',
    OVERLAPPING_BANNERS: 'URL menee päällekkäin olemassa olevan bannerin kanssa.',
    OVERLAPPING_WHATS_THIS_MODALS: 'URL menee päällekkäin olemassa olevan Mikä tämä on -modaalin kanssa.',
    NO_SUGGESTIONS_FOUND_FOR_ORDERS: 'Tilauksille ei ole kelvollisia kuljettajia.',
    noOrdersForDateAndCourier: 'Valitulle päivämäärälle ja yksikölle ei ole tilauksia.',
    INVALID_ORDER_BILLING_CUSTOMER_NUMBER:
      'Tilauslaskutuksen asiakasnumero tyypille {{orderBillingType}} on joko tyhjä tai virheellinen.',
    MISSING_ORDER_BILLING_TYPE: 'Puuttuva tilauslaskutustyyppi.',
    INVALID_ORDER_BILLING_TYPE:
      'Tilauslaskutustyypin on oltava yksi seuraavista: {{orderBillingTypes}}, mutta se oli - {{orderBillingType}}.',
    INVALID_ORDER_BILLING_INFO: 'Virheelliset tilauslaskutustiedot.',
    SMS_SERVICE_NOT_ORDERED: 'SMS-palvelua ei ole tilattu.',
    CANNOT_SEND_SMS_FOR_MESTER_GRONN_FUNERAL: 'Mester Grønn -hautajaispalvelu on tilattu.',
    SLOT_ROUTED_IN_HAPPY_FLOW: 'Aikaväli on reititetty Happy Flow -järjestelmään.',
    SERVICE_LEVEL_SHOULD_NOT_BE_NONE: 'Palvelutaso on ei mitään seuraavalle:',
    SLOT_NAME_ALREADY_CREATED: 'Reitin nimi on jo olemassa.',
    ORDER_IN_INCORRECT_STATE: 'Tilaus on virheellisessä tilassa.',
    ORDER_IN_INCORRECT_STATE_plural: 'Tilaus on virheellisessä tilassa.',
    INVALID_PARAMETERS: 'Virheelliset parametrit, {{description}}.',
    FAILED_DELETING_WEBHOOK_SUBSCRIPTION: 'Webhook-tilauksen poistaminen epäonnistui. Yritä myöhemmin uudelleen.',
    DEFAULT_ROLE_ALREADY_EXISTS: 'Toinen rooli {{name}} on jo oletus samalle käyttäjätyypille.'
  },
  customer: {
    useTerminalAddressModalHeading: 'Käytä terminaaliosoitetta',
    settings: { webhook: { overviewTitle: 'Webhook-asetukset' } },
    returnLastLegModalHeading: 'Luo paluuosuus',
    returnLastLegModalDescription:
      'Valitsemalla "kyllä" tarkoittaa, että jaamme tilauksen kahteen osaan ja lisäämme palautusosuuden. "Ei" tarkoittaa, ettei palautusosuutta tarvitse luoda. "Ei määritetty" tarkoittaa, että palautuksen käsittelysääntöä ei ole määritelty tietylle asiakkaalle.',
    connectMatrices: 'Yhdistä matriisit',
    brandedTrackingModalHeading: 'Brändätty seurannan laskutus',
    scanToCreatePackages: { title: 'Skannaa luodaksesi paketteja', disabled: 'Poistettu käytöstä' },
    invoiceSplit: { title: 'Laskun jakaminen', NotSet: 'Ei asetettu' },
    activateNPSFromDate: 'Ota NPS käyttöön alkaen',
    AddEdit: 'Lisää/muokkaa',
    startTypingPlaceHolder: 'Aloita kirjoittaminen',
    findCustomer: 'Löydä asiakas',
    showAll: 'Näytä kaikki asiakastunnukset',
    customer: 'Asiakas',
    groups: {
      title: 'Asiakasryhmiä',
      titleWithCount: '{{count}} asiakasryhmää',
      name: 'Ryhmän nimi',
      description: 'Kuvaus',
      numberOfCustomers: 'Asiakkaiden määrä',
      none: 'Ei asiakasryhmiä',
      new: 'Uusi asiakasryhmä',
      edit: 'Muokkaa asiakasryhmää',
      searchCustomer: 'Etsi asiakas',
      members: 'Asiakkaat ryhmässä',
      save: 'Tallenna',
      deleteConfirm: 'Oletko varma, että haluat poistaa asiakasryhmän?',
      deleted: 'Asiakasryhmä poistettu'
    },
    customerTitle: 'Asiakkaat',
    customerNo: 'Asiakasnumero',
    name: 'Nimi',
    emailToSender: 'Sähköposti lähettäjälle',
    deviations: 'Poikkeamat',
    add: 'Lisätä',
    noOrders: "Ei tilauksia Glow'ssa",
    noOrdersDescription: "Glow'ssa  ei ole tilauksia niillä asiakkailla, joihin sinulla on pääsy.",
    choose: 'Ole hyvä ja valitse asiakas',
    search: 'Etsi',
    customerNumber: 'Asiakasnumero',
    searchHint:
      'Etsi lähetyksiä lähetystunnuksella, asiakkaan viitteellä, paketin tunnuksella, toimituksen nimellä tai osoitteella.',
    senderName: 'Lähettäjän nimi',
    addSenderName: 'Lisää lähettäjän nimi',
    senderNameAdded: 'Lähettäjän nimi lisätty',
    billingCustomerNumber: 'Laskutusasiakasnumero',
    addBillingCustomerNumber: 'Lisää Laskutusasiakasnumero',
    billingCustomerNumberAdded: 'Laskutusasiakasnumero Lisätty',
    searchPage: {
      fromDate: 'Alkamispäivä',
      toDate: 'Päättymispäivä',
      today: 'Tänään',
      tomorrow: 'Huomenna',
      yesterday: 'Eilinen',
      last30days: 'Viimeiset 30 päivää',
      plusMinus15Days: '+/- 15 päivää',
      plusMinus30Days: '+/- 30 päivää'
    },
    smsText: { title: 'SMS Text', addEditSmsText: 'Add/ Edit SMS text', smsTypeTexts: { delivery: 'Toimitus SMS' } },
    showNotCollected: 'Ei noudettu',
    booking: {
      pickupReady: 'Nouto valmis',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      recipientPrice: 'Tilaajan hinta:',
      customerContactPlaceholder: 'Tilaajan nimi (Etu ja sukunimi) – Tämä kenttä näkyy asiakkaan laskussa'
    },
    email: {
      emailAddress: 'E-mail address',
      true: 'yes',
      false: 'no',
      informationToCustomer: 'Tiedot asiakkaalle',
      returnToCustomer: 'Palautus asiakkaalle',
      addEmailToList: 'Lisää sähköposti listalle',
      registeredEmailSubscription: 'Rekisteröity sähköpostitilaus',
      updateEmail: 'Päivitä sähköposti',
      noRegisteredEmails: 'Ei rekisteröityjä sähköposteja',
      detailsSavedSuccessfully: 'Sähköposti lähettäjälle tallennettiin onnistuneesti'
    },
    customerDeviations: {
      deviationType: 'Poikkeaman syy',
      deviation: 'Poikkeama',
      deviationCode: 'Poikkeamakoodi',
      deviationCode_plural: 'Poikkeamakoodit'
    },
    customerInfo: 'Asiakastiedot',
    customerName: 'Asiakkaan Nimi',
    invoicingAddress: 'Laskutusosoite',
    organisationNumber: 'Organisaation Numero',
    visitingAddress: 'Käyntiosoite',
    creditBlockedFrom: 'Luotto estetty päivämäärästä',
    creditBlockedFromInfo: 'Estä tämän asiakkaan luotto valitusta päivämäärästä alkaen',
    from: 'Päivämäärä',
    address: 'Osoite',
    zipCode: 'Postinumero',
    zipArea: 'Paikka',
    country: 'Maa',
    edit: 'Muokata {{name}}',
    selectCountry: 'Valitse Maa',
    addNewCustomer: 'Lisää uusi asiakas',
    bookingPageModalHeading: 'Varaus Asiakasportaalissa',
    bookingPageModalDescription:
      'Kytke tilaus mahdollisuus pois päältä, jos asiakas ei saa tilata lähetyksiä tällä asiakastunnuksella Glow asiakas portaalissa.',
    bookingActive: 'Päällä',
    bookingInactive: 'Pois päältä',
    brandedTrackingActive: 'Päällä',
    brandedTrackingInactive: 'Pois päältä',
    bookingPageAvailable: 'Päällä',
    bookingPageHidden: 'Pois päältä'
  },
  feedback: {
    title: 'Palaute',
    message: 'Olemme kiitollisia ehdotuksista tämän sivun parantamiseksi.',
    linkText: 'Lähetä palautetta'
  },
  texts: {
    tabTitle: 'Tekstit ja käännökset',
    title: 'Tekstit',
    update: 'Päivitä teksti',
    group: 'Ryhmittely',
    edit: 'Muokkaa',
    delete: 'Poista',
    add: 'Lisää teksti',
    reset: 'Resetoi',
    confirmDelete: 'Haluatko varmasti poistaa tekstin?',
    added: 'Teksti lisätty',
    deleted: 'Teksti poistettu',
    updated: 'Teksti päivitetty',
    nameToolTip: 'Nimi näytetään, kun tekstiä linkitetään palveluun'
  },
  countries: { norway: 'Norja', sweden: 'Ruotsi', finland: 'Suomi', denmark: 'Tanska', germany: 'Saksa' },
  shipmentsExport: {
    unit: 'Yksikkö',
    exportButton: 'Vie Excel-tiedostoon',
    shipmentId: 'Lähetystunnus',
    status: 'Tila',
    returned: 'Palautettu',
    department: 'Osasto',
    customer: 'Asiakas',
    customerNumber: 'Asiakasnumero',
    date: 'Päivämäärä',
    pickupEarliest: 'Aikaisin noutoaika',
    pickupLatest: 'Myöhäisin noutoaika',
    deliveryEarliest: 'Aikaisin toimitusaika',
    deliveryLatest: 'Myöhäisin toimitusaika',
    serviceCode: 'Palvelu',
    additionalServices: 'Lisäpalvelut',
    pickupName: 'Nouto',
    pickupAddress: 'Nouto-osoite',
    pickupZipCode: 'Nouto-osoitteen postinumero',
    pickupZipArea: 'Nouto alue',
    pickupCoordinates: 'Noutokoordinaatit',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryName: 'Toimitus',
    deliveryAddress: 'Toimitus osoite',
    deliveryZipCode: 'Toimitus osoitteen postinumero',
    deliveryZipArea: 'Toimitus alue',
    deliveryCoordinates: 'Toimituskoordinaatit',
    deliveryInstructions: 'Toimitusohjeet',
    quantity: 'Määrä',
    weight: 'Paino',
    volume: 'Tilavuus',
    customerRef: 'Asiakkaan viite',
    pickupContactPerson: 'Nouto yhteyshenkilö',
    pickupPhone: 'Nouto puhelin',
    deliveryContactPerson: 'Toimitus yhteyshenkilö',
    deliveryPhone: 'Toimitus puhelin',
    deliveryEmail: 'Toimitussähköposti',
    recipientRef: 'Vastaanottajan viite',
    price: 'Hinta',
    group: 'Ryhmän',
    pickupTime: 'Noudettu/ Yritetty',
    delivered: 'Toimitettu/ Yritetty',
    deliveryEtaSmsSentAt: 'Toimitusaika SMS lähetetty',
    courierName: 'Lähetti',
    goodsInfo: 'Tiedot tavarasta',
    note: 'Huomautus',
    slotName: 'Toimitusajankohta',
    estimatedArrivalTime: 'Arviolta',
    orderSource: 'Tilaustapa'
  },
  importExport: {
    dropText: 'Pudota tiedosto tähän ladataksesi',
    export: 'Siirrä',
    exportAddresses: 'Siirrä asiakkaan osoitteet exceliin',
    importExcel: 'Tuo Excel-tiedostosta',
    imported: 'tuodut osoitteet',
    noRecipients: 'Ei kelvollisia vastaanottajia',
    or: 'tai',
    recipients: 'vastaanottajat',
    selectFile: 'Valitse tiedosto',
    uploadingFile: 'Lataaminen...'
  },
  customMeasurements: {
    name: 'Nimi',
    weight: 'Paino',
    height: 'Korkeus',
    length: 'Pituus',
    width: 'Leveys',
    heading: 'Mukautetut tavarat',
    error: 'Tallennus epäonnistui: ',
    confirmDelete: 'Oletko varma, että haluat poistaa tämän tavaratyypin?',
    new: 'Luo uusi',
    customizeGoodsTypes: 'Mukautettu tavaran tyyppi',
    customGoodsTypes: 'Mukautetut tavarat',
    defaultGoodsTypes: 'Esitallennetut tavarat',
    invalidName: 'Nimessä on oltava vähintään yksi kirjain'
  },
  unresolved: {
    unresolvedAddresses: 'Selvittämättömiä osoitteita',
    allResolved: 'Selvittämättömiä osoitteita ei löytynyt!',
    address: 'Osoite',
    noOfShipments: 'Lähetysten määrä',
    shipment: 'lähetys',
    shipment_plural: 'lähetykset',
    shipmentNumber: 'Lähetyksen numero',
    customerRef: 'Asiakkaan viite',
    senderName: 'Lähettäjän nimi',
    senderPhone: 'Lähettäjän puhelinnumero',
    recipientName: 'Vastaanottajan nimi',
    recipientPhone: 'Vastaanottajan puhelinnumero',
    searchAndSetLocation: 'Etsi ja kohdista osoite',
    setALocationToContinue: 'Kohdista osoite jatkaaksesi',
    resolveAddress: 'Selvitä osoite',
    suggestedAddressFound: 'Ehdotettu osoite',
    thereIsOneUnresolvedAddress: '{{count}} selvittämätön osoite',
    thereIsOneUnresolvedAddress_plural: '{{count}} selvittämätöntä osoitetta'
  },
  optimizing: {
    reqTime: 'Pyyntöaika',
    respTime: 'Vastausaika',
    department: 'Osasto',
    userName: 'Käyttäjä',
    request: 'Pyyntö',
    response: 'Vastaus',
    error: 'Virhe',
    viewReqResp: 'Näytä',
    status: 'Tila',
    totalTime: 'Aika'
  },
  date: {
    date: 'Päivämäärä',
    today: 'Tänään',
    tomorrow: 'Huomenna',
    yesterday: 'Eilen',
    dateAndTime: 'Päivä ja ajat',
    from: 'Alkaen',
    fromDate: 'Alkupäivämäärä',
    rangeOptions: {
      '15d': '+/- 15 päivää',
      '30d': '+/- 30 päivää',
      custom: 'Mukautetut päivämäärät',
      last30d: 'Viimeiset 30 päivää',
      last7d: 'Viimeiset 7 päivää',
      month: 'Kuukausi',
      months: 'Kuukaudet',
      today: 'Tänään',
      tomorrow: 'Huomenna',
      yesterday: 'Eilen'
    },
    to: 'Asti',
    toDate: 'Loppupäivämäärä'
  },
  routeReceipt: {
    printRouteReceiptList: 'Tulosta kuittauslista',
    noRoutesInfoAvailable: 'Reittitietoja ei ole saatavilla'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kuittauslista',
    shipments: 'Toimitukset',
    weight: 'Paino',
    volume: 'Tilavuus',
    loadingMeters: 'Latausmittarit',
    palletFootPrints: 'Lava jalanjäljet',
    sender: 'Lähettäjä',
    package: 'Lähetys',
    shipmentNo: 'Toimitusnumero',
    ref: 'Viite',
    recipient: 'Vastaanottaja',
    time: 'Aika',
    mobile: 'Puhelinnumero',
    other: 'Muuta',
    attemptedDelivery: 'Poikkeamat',
    receipt: 'Allekirjoitus',
    dateOfBirth: 'Syntymäaika',
    signature: 'Allekirjoitus',
    nameClarification: 'Nimi',
    goodsLine1: 'Muista tarkastaa lähetys näkyviltä vaurioilta ennen allekirjoittamista',
    goodsLine2: 'Jos näkyviä vaurioita löytyy, kuljettajan tulee merkitä ne ennen vastaanottajan allekirjoitusta',
    damage: 'Vaurio',
    missing: 'Puuttuu',
    packageNumber: 'Lähetysnumero',
    comments: 'Kommentti'
  },
  columnCategories: {
    aboutTheShipment: 'Tietoa lähetyksestä',
    goods: 'Tavarat',
    timeAndDate: 'Aika ja päivämäärä',
    planning: 'Suunnittelu',
    pickupInformation: 'Noutotiedot',
    customer: 'Asiakas',
    deliveryInformation: 'Toimitustiedot',
    originalSender: 'Alkuperäinen lähettäjä',
    originalDelivery: 'Alkuperäinen toimitus',
    service: 'Palvelu',
    preAdvice: 'Esi-ilmoitus',
    invoicingInformation: 'Laskutustiedot'
  },
  shipment: {
    shipment: 'Lähetys',
    customer: 'Asiakas',
    customerContact: 'Asiakaskontakti',
    customerReference: 'Asiakkaan viite',
    customerNumber: 'Asiakasnumero',
    route: 'Reitti',
    service: 'Palvelu',
    additionalServices: 'Lisäpalvelut',
    quantity: 'Määrä',
    totalWeight: 'Kokonaispaino',
    totalVolume: 'Kokonaisvolyymi',
    totalLoadingMeters: 'Kokonaislatausmittarit',
    totalPalletFootPrints: 'Kokonais lava jalanjäljet',
    goodsInfo: 'Tiedot tavarasta',
    packages: 'Pakettimäärä',
    pickupInfo: 'Noutotiedot',
    pickupDate: 'Noutopäivä',
    pickupTime: 'Noutoaika',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInfo: 'Toimitustiedot',
    deliveryDate: 'Toimituspäivä',
    deliveryTime: 'Toimitusaika',
    receiverReference: 'Vastaanottajan viite',
    deliveryInstructions: 'Toimitusohjeet',
    trackingPage: 'Vastaanottajan seurantasivu',
    internalNote: 'Muistiinpanot',
    closeWhileEditing:
      'Oletko varma että haluat sulkea tämän kesken muutoksen?\nKaikki tallentamattomat muutokset häviävät.',
    name: 'Nimi',
    address: 'Osoite',
    zipCode: 'Postinumero',
    zipArea: 'Kaupunki',
    phone: 'Puhelin',
    secondPhone: 'Puhelin 2',
    email: 'Sähköposti'
  },
  bookingTimeWindow: {
    week: 'Viikko',
    bookButton: 'Tilaa',
    customButton: 'Muokattu aikaikkuna',
    removeBookingButton: 'Poista tilaus',
    bookedInfo: 'Tilattu {{date}} aikavälille {{time window}}',
    notBookedInfo: 'Ei tilattu',
    tableHeaderDate: 'Päivämäärä',
    tableHeaderSlots: 'Saatavilla olevat aikavälit',
    serverError: 'Tapahtui virhe. Yritä myöhemmin uudelleen.',
    previousButton: 'Takaisin',
    nextButton: 'Seuraava',
    noAvailableDeliveryTimes: 'Ei saatavilla olevia toimitusaikoja tässä kuussa',
    customTimeTitle: 'Aseta muokattu aikaikkuna lähetykselle',
    customDateLabel: 'Päivämäärä',
    customTimeWindowLabel: 'Toimituksen aikaikkuna',
    backFromCustomTimeButton: 'Takaisin vapaisiin toimitusaikoihin',
    errorEarliestNotBeforeLatest: 'Aikaisin toimitusaika ei voi olla ennen myöhäisintä toimitusaikaa',
    arrivedAtTerminal: 'Saapunut terminaaliin',
    bookCustomTimeSlot: 'Varaa aikaväli',
    shipmentBooked: 'Lähetys varattu',
    shipmentBookedWithCustomTimeWindow: 'Lähetys varattu mukautetulla aikavälillä',
    updateBooking: 'Päivitä varaus',
    removeTimeSlot: 'Poista aikaväli',
    bookingChanged: 'Varaus on muutettu onnistuneesti',
    bookingSuccessful: 'Varaus tehtiin onnistuneesti',
    customTimeWindowAdded: 'Mukautettu aikaväli lähetystä varten lisättiin onnistuneesti',
    timeFrom: 'Kello alkaen',
    timeTo: 'Kello asti',
    timeFieldError: 'Varhaisimman toimitusajan on oltava ennen viimeisintä',
    availableTimeSlot: 'Saatavilla olevat aikavälit',
    updateButtonTooltip: 'Varauksen päivittämiseksi sinun on ensin valittava uusi aikaväli'
  },
  manualOverride: {
    scannedFormDescription: 'Valitsemasi osasto käynnistää saapumisskannauksen tyypin.',
    eventType: {
      delivered: 'Toimitettu',
      collected: 'Noudettu',
      returned: 'Palautettu',
      deviated: 'Poikkeama',
      scanned: 'Skannattu',
      preadvised: 'Preadvice yritys'
    },
    selectEventType: 'Valitse tapahtuma',
    noEventSelected: 'Ei valittua tapahtumaa',
    selectManifest: 'Valitse lento',
    noManifestSelected: 'Lentoa ei ole valittu',
    disabledEventReason: {
      returned: ' - Poikkeama tarvitaan',
      collected: ' - Ajojärjestystä tarvitaan',
      default: ' - Ei sallittu'
    },
    fillDeliverOrReturnEventInfo: 'Täytä nimi, päivä ja aika',
    fillDeviationEventInfo: 'Valitse poikkeama ja täytä päivä ja aika',
    fillGeneralEventInfo: 'Täytä nimi, päivä ja aika',
    eventMessage: 'Tapahtuma luotu',
    allPackagesSelected: 'Kaikki paketit valittuja',
    noActiveDriverError: 'Ei aktiivisia kuljettajia yksikölle',
    selectPackages: 'Valitse paketit',
    selectDriver: 'Valitse kuljettaja',
    selectUnit: 'Valitse yksikkö',
    selectDepartment: 'Valita osasto'
  },
  zendesk: {
    email: 'Sähköposti:',
    confirmEmail: 'Sähköposti uudelleen:',
    send: 'Tallenna',
    missingEmail:
      'Uuden tuki portaalin käyttöä varten, sinun täytyy rekisteröidä Posten tai Bring sähköpostisi. Tämä tarvitsee tehdä vain kerran.'
  },
  instantPlannerUnification: {
    message:
      'Osiot Kuriiri ja Reitin suunnittelutyökalu ovat nyt yhdistetty. Ole hyvä ja mene reitin suunnittelutyökalu osioon löytääksesi kuriiri toimintosi.',
    linkTitle: 'Lue lisää täältä.'
  },
  shipmentFormPage: {
    findShipment: 'Etsi lähetystä',
    searchPhraseError: 'Sinun on annettava vähintään 3 merkkiä',
    selectStateLabel: 'Valitse tila pudotusvalikosta',
    selectDateLabel: 'Valitse päivämäärä pudotusvalikosta',
    submit: 'Näytä tulokset',
    inputPlaceholder: 'Hae lähetystunnusta, puhelinnumeroa, osoitetta, aluetta, postinumeroa ...',
    clearButtonLabel: 'Tyhjennä hakuphrase-painike',
    allStates: 'Kaikki tilat',
    customDate: 'Mukautettu',
    noDate: 'Ei päivämäärärajoitusta',
    selectFromDate: 'Valitse päivämäärä',
    selectToDate: 'Valitse päivämäärä',
    include: 'Sisällytä',
    exclude: 'Poissulkea',
    selectAdvancedOperator: 'Valitse lisäoperaattori-pudotusvalikko',
    ofTheseRequirements: 'näistä vaatimuksista',
    tableColumn: 'Taulukon sarake',
    condition: 'Kunto',
    searchTerm: 'Hakusana',
    all: 'KAIKKI',
    any: 'MIKÄ TAHANSA',
    tableDropdown: 'Valitse taulukko-pudotusvalikko',
    conditionDropdown: 'Valitse kunto-pudotusvalikko',
    typeSearch: 'Kirjoita hakutermi',
    addNewRow: 'Lisää uusi rivi',
    deleteRow: 'Poista nykyinen rivi',
    service: 'Palvelu',
    goodsDescription: 'Tavarakuvaus',
    route: 'Reitti',
    unit: 'Yksikkö',
    deliveryArea: 'Toimitusalue',
    deliveryZipcode: 'Toimituspostinumero',
    state: '{{count}} Osavaltio',
    state_plural: '{{count}} Tilat',
    shipmentIds: 'Useita lähetyksiä',
    advancedFilter: 'Laajennettu suodatin',
    selectTable: 'Valitse taulukko',
    selectCondition: 'Valitse ehto',
    selectState: 'Valitse tila',
    orderId: 'Sisäinen tilausnumero',
    allAnd: 'Kaikki (ja)',
    anyOr: 'Mikä tahansa (tai)'
  },
  shipmentResultPage: {
    noResultTitle: 'Ei tuloksia',
    batchLimitReached: 'Näytetään enintään 2000 lähetystä, harkitse hakusi supistamista',
    withoutDateLimitReached:
      'Etsittäessä ilman päivämäärää, näytämme enintään {{limit}} lähetystä. Harkitse hakusi rajaamista.',
    noResultExplanation: 'Emme löytäneet tuloksia. Yritä hakea toisella hakusanalla.',
    findYourShipments: 'Etsi lähetyksiäsi',
    findYourShipmentsDescription:
      'Kirjoita hakemasi termi yllä olevaan hakukenttään. Lisää vaihtoehtoja löytyy laajennetusta hausta',
    searchByShipmentIdsLimitReached:
      'Kun etsit lähetystunnuksilla, näytämme enintään {{limit}} lähetystä. Harkitse haun tarkentamista.',
    searchError: 'Haku epäonnistui. Yritä ladata sivu uudelleen tai muuta hakuehtoja'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Poista lähetys?',
    confirmDeleteTitle_plural: 'Poista valitut lähetykset?',
    confirmDuplicateTitle: 'Kopioi lähetys?',
    confirmDuplicateButton: 'Kopioi',
    deleteSuccess: 'Lähetys poistettiin onnistuneesti',
    deleteSuccess_plural: 'Lähetykset poistettiin onnistuneesti',
    deleteFailure: 'Lähetystä ei voitu poistaa',
    deleteFailure_plural: 'Lähetysten poistaminen epäonnistui',
    invoiceActions: 'Laskutustoiminnot',
    shipmentActions: 'Lähetystoiminnot',
    showInvoicePayloadDisableTooltip: 'Lähetysten on oltava laskutustilassa "epäonnistunut" tai "OK'
  },
  shipmentTable: {
    quantity: 'Määrä',
    delEta: 'LÄH ARV',
    delDuration: 'LÄH K',
    delDurationTitle: 'Toimituksen kesto',
    delDeliveryFrom: 'LÄH A',
    delDeliveryFromTitle: 'Toimitusaika alkaen',
    delDeliveryTo: 'LÄH L',
    delDeliveryToTitle: 'Toimitusaika asti',
    timeOfDelivery: 'Toimituksen aika',
    puDuration: 'NOUTO K',
    puDurationTitle: 'Noudon kesto',
    puPickupFrom: 'NOUTO A',
    puPickupFromTitle: 'Noudon aika alkaen',
    puPickupTo: 'NOUTO L',
    puPickupToTitle: 'Noudon aika asti',
    timeOfPickup: 'Noudon aika',
    delEtaTitle: 'Arvioitu toimitusaika',
    recipientRef: 'Vastaanottajan viite',
    loading: 'Ladataan...',
    pickupName: 'Noutopaikan nimi',
    deliveryName: 'Toimituspaikan nimi',
    selectAllOnPage: 'Valitse kaikki sivulla ({{count}})',
    selectAll: 'Valitse kaikki ({{count}})',
    deselectAll: 'Poista kaikki valinnat',
    numSelectedShipments: '{{numSelected}} valittu',
    createLabels: 'Luo tarrat',
    editShipments: 'Muokkaa lähetystietoja',
    moveShipments: 'Siirrä toiseen osastoon',
    yourSearchResult: 'Hakutuloksesi',
    numberOfShipmentsFound: '{{count}} lähetystä löydetty',
    orderedDeliveryDate: 'Tilattu toimituspäivä'
  },
  tablePagination: { goToPage: 'Siirry sivulle', page: 'Sivu', of: 'tai' },
  moveDepartmentModal: {
    errorMessage: 'Tilaus(t): {{orderIds}}, virhe: {{error}}',
    errorMessage_plural: 'Tilaus/tilaukset: {{orderIds}}, virhe: {{error}}',
    ordersMovedText: 'Tilaus(t) {{orderIds}} on siirretty osastolle {{department}}',
    ordersMovedText_plural: 'Tilaus/tilaukset {{orderIds}} on siirretty osastolle {{department}}',
    partialSuccessMessage: '{{count}} / {{fullCount}} lähetystä siirrettiin',
    title: 'Siirrä valitut lähetykset toiseen osastoon'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}}/{{fullCount}} viestiä lähetettiin',
    successMessage: 'Käsikäyttöinen viiveviesti lähetettiin onnistuneesti'
  },
  multistopModal: {
    title: 'Ryhmäinformaatio',
    description: 'Ryhmä näkymä tilauksille, jotka maksetaan yhdessä.',
    debitLines: 'Velkalinjat',
    notFound: 'Ryhmäinformaatiota ei löytynyt',
    shipmentId: 'Lähetystunnus',
    deliveryDate: 'Toimituspäivä',
    stopAddress: 'Pysäytysosoite',
    postalCode: 'Postinumero',
    noPrice: 'Ei hintatietoja saatavilla ryhmälle',
    priceUpdated: 'Ryhmän hinnat on päivitetty'
  },
  shipmentDetails: {
    eventWasAdded: 'Tapahtuma lisättiin',
    issueSaving: 'Tallennuksessa ilmeni ongelma',
    noSelectedPackages: 'Paketteja ei valittu',
    pickupAndDelivery: 'Nouto ja toimitus',
    whereIsTheOrder: 'Missä tilaus on',
    communicationToRecipient: 'Viestintä vastaanottajalle',
    yesPremium: 'Kyllä, premium',
    alerts: 'Hälytykset',
    pickupDeliveryInfo: 'Noudon ja toimituksen tiedot',
    quickEdit: 'Pikamuokkaus',
    quickEditTitle: 'Muokkaa nouto- ja toimitustietoja',
    aboutShipment: 'Lähetyksestä',
    mapLinkText: 'Näytä karttakoordinaatit',
    copyShipment: 'Kopioi lähetys',
    editShipment: 'Muokkaa lähetystietoja',
    location: 'Sijainti',
    time: 'Aika',
    selectDate: 'Valitse päivämäärä',
    manualChangeEvent: 'Muuta tapahtumaa manuaalisesti',
    disabledEventReason: {
      returned: 'Palautettu: Poikkeama vaaditaan',
      collected: 'Noudettu: Tilaus on annettava reitille',
      default: 'Oletus: Ei sallittu'
    },
    selectDeviation: 'Valitse poikkeama',
    required: 'Pakollinen',
    allPackages: 'Kaikki paketit',
    packages: 'Paketit',
    package: 'Paketti',
    changeDepartment: 'Vaihda osasto',
    moveDepartment: 'Siirrä osastolle',
    shipmentMoved: 'Lähetys siirretty',
    shipmentMovedText: 'Lähetys {{shipmentId}} on siirretty osastolle {{department}}',
    shipmentMovedGoBack: 'Palaa hakusivulle',
    moveShipmentDescription: 'Jos sinulla ei ole pääsyä osastolle, et voi löytää lähetystä sen siirron jälkeen',
    moveShipmentConfirmText: 'Siirrä lähetys toiseen osastoon?',
    addShipmentToSlot: 'Lisää lähetys paikkaan {{slot}}?',
    changeSlotConfirm: 'Muuta paikkaa lähetys paikkaan {{slot}}?',
    changeSlot: 'Muuta paikkaa',
    selectSlot: 'Valitse paikka',
    AddToSlot: 'Lisää paikkaan',
    optimisationInProgress: 'Optimointi käynnissä. Et voi muuttaa paikkaa, kun reittiä optimoidaan.',
    removeFromSlot: 'Poista paikalta',
    removeShipmentFromSlot: 'Poista lähetys paikalta',
    removeShipmentFromSlotHeading: 'Poista lähetys tästä paikasta?',
    successRemoveFromSlot: 'Lähetys poistettu paikalta',
    successChangeSlot: 'Lähetys muutettu paikkaan',
    successAddToSlot: 'Lähetys lisätty paikkaan',
    changeSlotDescription: 'Paikan muutos tehdään ilman reitin optimointia.',
    addToSlotDescription: 'Valittu paikka optimoidaan olemassa olevilla lähetysjärjestyksillä.',
    pickupOrDeliveryChange: 'Noudon tai toimituksen osoite on muuttunut',
    pickupOrDeliveryChangeDescription:
      'Noudon tai toimituksen osoite on muuttunut. Jos haluat muuttaa osoitteen takaisin, paina alla olevaa painiketta.',
    addressWasUnresolved: 'Osoite oli ratkaisematon',
    eventTimeCannotBeInFuture: 'Tapahtuma-aika ei voi olla tulevaisuudessa.'
  },
  terminal: 'Terminaali',
  location: 'Sijainti',
  pickupOrders: {
    acceptOrders: 'Hyväksy tilaukset',
    selected: 'valittu',
    handleOrders: 'Käsittele noutotilaukset',
    found: 'löydetty',
    accept: 'Hyväksy',
    reject: 'Hylkää',
    noResultText: 'Emme löytäneet tuloksia. Yritä haku toisella päivämäärällä.',
    last7d: 'Tänään + viimeiset 7 päivää',
    rejectOptions: {
      notFound: 'Lähettäjää ei löydy nouto-osoitteelta. Ei enää yrityksiä. Nouto suljettu.',
      terminatesPickupOrder: 'Lähettäjä keskeyttää noutotilauksen. Ei enää yrityksiä. Nouto suljetaan.',
      handedOver: 'Lähettäjä on luovuttanut noudon toiselle kuljettajalle. Ei enää yrityksiä. Nouto suljettu.',
      noMoreAttempts: 'Lähettäjä on muuttanut. Ei enää yrityksiä. Nouto suljettu.',
      pickupCorrected: 'Lähettäjä on muuttanut. Nouto korjattu ja lähetetty takaisin aliurakoitsijalle.',
      goodsNotReady: 'Tavaroita ei ole valmiina noudettavaksi. Ei enää yrityksiä. Nouto suljettu.',
      incorrectBooking: 'Virheellinen varaus. Nouto keskeytetty.',
      senderRefuses: 'Lähettäjä kieltäytyy luovuttamasta tavaroita. Ei enää yrityksiä. Nouto suljettu.',
      addressCorrected: 'Virheellinen nouto-osoite. Osoite korjattu.',
      incorrectAddressNoMoreAttempts: 'Virheellinen nouto-osoite. Ei enää yrityksiä. Nouto suljettu.',
      senderNotified: 'Virheellinen nouto-osoite. Ei enää yrityksiä. Nouto suljettu.',
      forbiddenGoods: 'Kielletyt tavarat. Ei enää yrityksiä. Nouto suljettu.',
      timeFrame: 'Noutoa ei voi suorittaa aikataulun puitteissa. Noutotilaus palautetaan varausosastolle.'
    },
    mustSelectReason: 'Sinun on valittava hylkäämisen syy',
    selectRejectReason: 'Valitse hylkäämisen syy*',
    selectReason: 'Valitse syy',
    commentPlaceholder: 'Valinnainen - lisää tietoja hylkäämiseen',
    acceptedSuccessToast: 'hyväksyttiin onnistuneesti',
    rejectedSuccessToast: 'hylättiin onnistuneesti',
    errorToast: 'Jotain meni pieleen',
    rejectOrders: 'Hylkää tilaukset',
    shipment: '{{count}} lähetys',
    shipment_plural: '{{count}} lähetystä'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Noutoikkunan on oltava ennen toimitusikkunaa',
    pickupEarliestShouldBeBeforeLatest: 'Aikaisimman noutoajan on oltava ennen viimeisintä',
    deliveryEarliestShouldBeBeforeLatest: 'Aikaisimman toimitusajan on oltava ennen viimeisintä',
    success: 'Lähetysten tiedot päivitettiin onnistuneesti',
    mixedShipmentsSelected: 'Olet valinnut lähetyksiä päivämäärän kanssa ja ilman. Nouto- ja toimitustiedot vaaditaan.',
    fieldNotEditable: 'Tätä tietoa ei voi muokata tilauksille, jotka ovat tilassa "Osoitettu paikkaan" tai "Noudettu"',
    bulkActionDisabledReason:
      'Tilauksia, jotka ovat tilassa "Poikkeama", "Toimitettu", "Hylätty" tai "Palautettu", ei voi muokata',
    withoutDateDisabledReason:
      'Tilauksien yhdistelmät tilassa "Osoitettu paikkaan" tai "Noudettu" ja ilman päivämäärää eivät voi muokata',
    pickupDateTime: 'Nouto päivä ja ajat',
    deliveryDateTime: 'Toimitus päivä ja ajat',
    earliestPickupTime: 'Aikaisin noutoaika',
    latestPickupTime: 'Myöhäisin noutoaika',
    earliestDeliveryTime: 'Aikaisin toimitusaika',
    latestDeliveryTime: 'Myöhäisin toimitusaika',
    pickupName: 'Nouto nimi',
    pickupPhoneNumber: 'Nouto puhelinnumero',
    deliveryName: 'Toimitus nimi',
    deliveryPhoneNumber: 'Toimitus puhelinnumero',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInstructions: 'Toimitusohjeet'
  },
  shipmentSelectColumn: {
    title: 'Mukauta taulukon otsikoita',
    addColumnHeader: 'Lisää sarakkeiden otsikot',
    addColumnBody:
      'Valitse otsikot, jotka haluat näkyvän taulukossa. Vinkkimme on käyttää 10-15 eri otsikkoa, jotta tieto ei katkeaisi. Tarkka määrä riippuu laitteen koosta, jolla työskentelet.',
    orderOfTable: 'Taulukon otsikoiden järjestys',
    orderOfTableDescription: 'Valitse haluamasi järjestys taulukon otsikoille vetämällä ja pudottamalla.'
  },
  bulkActions: {
    routeReceiptList: { disabledTooltip: 'Tilaukset eivät voi olla tilassa Ratkaistu tai Ratkaisematon' },
    unassignShipmentFromSlot: 'Poista lähetyspaikat paikoista',
    unassignShipmentFromSlotDisabledReason: 'Tilausten on oltava tilassa "Osoitettu paikkaan" tai "Tarjottu"',
    forceUnassignShipmentFromSlot: 'Pakota poistamaan lähetykset paikoista',
    disabledMoveDepartmentTooltip:
      'Tilausten on oltava tilassa Luotu, Ratkaistu, Ratkaisematon tai Hylätty siirtääksesi ne',
    deleteDisableTooltip:
      'Lähetystä ei voi poistaa, jos se on tilassa Luotu, Ratkaisematon, Ratkaistu, Tarjottu tai Määrätty',
    duplicateDisableTooltip:
      'Lähetyksen on oltava tilassa Poikennut tai Palautettu, ja osaston tyypin on oltava Kuriiri',
    viewGroupDisabledTooltip: 'Tilaus on määritettävä ennen kuin voit tarkastella ja muokata sen ryhmää'
  },
  vehicle: {
    weightCapacity: 'Painokapasiteetti',
    volumeCapacity: 'Tilavuuskapasiteetti',
    maxRangeKm: 'Toimintasäde',
    createNewVehicle: 'Luo uusi ajoneuvo',
    createAndEditVehicle: 'Luo ja muokkaa ajoneuvoja',
    listAllVehicle: 'Listaa kaikki ajoneuvot tässä osastossa',
    deleteSuccessMessage: 'Ajoneuvo poistettiin onnistuneesti',
    createdSuccessMessage: 'Ajoneuvo luotiin onnistuneesti',
    updatedSuccessMessage: 'Ajoneuvo päivitettiin onnistuneesti',
    deleteVehicle: 'Poista ajoneuvo',
    notRecover: 'Tätä toimintoa ei voi peruuttaa',
    createVehicle: 'Luo ajoneuvo',
    editVehicle: 'Muokkaa {{name}}',
    vehicleInformation: 'Ajoneuvotiedot',
    selectFuelType: 'Valitse polttoainetyyppi',
    selectEuroClass: 'Valitse Euro-luokka',
    settings: 'Asetukset',
    activeDriver: 'Aktiivinen kuljettaja',
    noVehicleControl: 'Ei ajoneuvon hallintaa',
    slower: 'Hitaampi',
    slow: 'Hidas',
    normal: 'Normaali',
    faster: 'Nopeampi',
    speedFactorVehicle: 'Ajoneuvon nopeuskerroin',
    driverSpeed: 'Kuljettajan nopeus',
    serviceSpeed: 'Palvelunopeus',
    defaultStartLocation: 'Oletusarvoinen lähtöpaikka on osaston osoite',
    specifyEndLocation: 'Määritä päätepiste',
    earliest: 'Aikaisin',
    latest: 'Myöhäisin'
  },
  invoicingStatus: {
    INVOICING_FAILED: 'Laskutus epäonnistui',
    CORRECTION_PENDING: 'Oikaisu vireillä',
    INVOICING_PAUSED: 'Laskutus pysäytetty',
    EXCLUDED_FROM_INVOICING: 'Ulkopuolelle',
    NOT_READY_FOR_INVOICING: 'Ei valmis',
    INVOICED_OK: 'Laskutettu OK',
    ZERO_PRICE: 'Epätäydellinen hinta',
    AWAITING_ACKNOWLEDGEMENT: 'Odottaa vahvistusta'
  },
  invoice: {
    articleCode: 'Artikkeli',
    allOrdersInvoice: 'Kaikki tilaukset laskutetaan',
    allOrdersInvoiceDescription: 'Emme löytäneet laskuttamattomia tilauksia.',
    handledBy: 'Käsitellyt',
    invoicing: 'Laskutus',
    credit: 'Hyvitys',
    invoice: 'Lasku',
    searchShipmentId: 'Hae lähetystunnusta',
    state: '{{count}} tila',
    state_plural: '{{count}} tilaa',
    create: 'Luoda',
    invoiceOrderAction: 'Laskun tilaus',
    invoiceOrderAction_plural: 'Laskuta tilaukset',
    customerNumber: 'Asiakasnumero',
    carrierNumber: 'Yksikkönumero',
    details: 'Yksityiskohdat',
    information: 'Laskun tiedot',
    reference: 'Laskun viite',
    items: 'Tuotteet',
    quantity: 'Määrä',
    mustBeNegative: 'Täytyy olla < 0',
    mustBePositive: 'Täytyy olla > 0',
    description: 'Kuvaus',
    customerAmount: 'Asiakkaan summa',
    carrierAmount: 'Yksikkön määrä',
    totalCustomerAmount: 'Asiakkaan kokonaissumma',
    totalCarrierAmount: 'Yksikön kokonaismäärä',
    failedPriceListLink: 'Epäonnistunut hinnasto Pricing Enginessä',
    failedPriceList: 'Epäonnistunut hinnasto',
    priceFailureDetail: 'Hintavirheen tiedot',
    priceFailureReason: 'Hinnan epäonnistumisen syy',
    priceFailureReasons: {
      missingAttributeValue: 'Attribuutti puuttuu',
      matrixLookupMiss: 'Matriisivirhe',
      invalidExpression: 'Virheellinen lauseke',
      scriptError: 'Skriptivirhe'
    },
    invoiceDepartment: 'Laskutusosasto',
    invoiceDepartment_plural: 'Laskutusosastot',
    invoiceDepartments: 'Laskutusosasto(t)',
    dashboard: 'Dashboard',
    invoicingJobsSummary: 'Laskutustyön yhteenveto',
    invoicingStatus: 'Laskutuksen tila',
    invoicingStatusDescription: 'Näytetään tilaukset viimeisen 3 kuukauden ajalta.',
    invoicingModalWarningInvoicedOk:
      'Lähetyksiä ei laskuteta ja ne katsotaan käsitellyiksi. Laskua ei muodostu ja tilaukset poistuvat laskutussivulta.',
    notInvoicedOrders: 'Ei laskutettuja tilauksia',
    ordersOnPage: 'Näytetään {{count}} tilausta sivulla',
    ordersFoundCount: '{{count}} tilaus löydetty',
    ordersFoundCount_plural: '{{count}} tilausta löytyi',
    toBeInvoiced: 'Laskutettava',
    ordersNotInvoiced: 'Tilaukset, joita ei ole vielä laskutettu',
    invoicingFailed: 'Laskutus epäonnistui',
    invoicingFailedDescription: 'Tilausten käsittely epäonnistui',
    text: 'Laskun teksti',
    correctionPending: 'Tilauksen korjauksella',
    correctionPendingDescription: 'Tilaukset odottavilla korjauksilla',
    invoicingPaused: 'Laskutus keskeytetty',
    ordersPausedFromInvoiced: 'Tilaukset, joiden laskutus on keskeytetty',
    invoicingZeroPrice: 'Epätäydellinen hinta',
    invoicingZeroPriceDescription: 'Tilaukset, joissa on hyväksymättömiä nollarivejä',
    sendInvoices: 'Lähetä laskut',
    sendInvoicesDescription: 'Olet laskuttamassa {{osastot}}. Valitse laskutusjakson päivämäärä tai ajanjakso.',
    sendInvoiceDateValidationError: 'Alkaen päivämäärä ei voi olla myöhäisempi kuin Tähän päivään',
    sendInvoiceDateValidationErrorOneMonth: 'Päivämäärän enimmäispituus on 1 kuukausi',
    invoicingInProgress: 'Laskutus osastolle {{departmentName}} on käynnissä, {{count}} laskutustyötä jäljellä',
    invoicingInFinished: 'Laskutus laitokselle {{departmentName}} on valmis',
    orderEnqueuedForInvoicing: 'Tilaus on jonossa laskutusta varten',
    orderEnqueuedForInvoicing_plural: 'Tilaukset on jonossa laskutusta varten',
    orderSuccessNotInvoiced: 'Tilaus muutettiin laskuttamattomaksi',
    orderSuccessNotInvoiced_plural: 'Tilaukset muutettiin laskuttamattomiksi',
    orderFailedInvoice: 'Valitun tilauksen laskutus epäonnistui',
    howManyInvoices: 'Kuinka monta laskua lähetetään',
    fetchAmount: 'Hae summa',
    wantToResend: 'Tämä tilaus on jo laskutettu. Oletko varma, että haluat lähettää sen uudelleen?',
    adHocInvoiceOrder: 'Olet laskuttamassa tätä tilausta. Haluatko jatkaa?',
    adHocInvoiceOrder_plural: 'Olet laskuttamassa näitä tilauksia. Haluatko jatkaa?',
    pauseOrders: 'Pysäytä tilaukset',
    datePeriod: 'Päivämääräjakso',
    ordersWereSuccessfullyPaused: '{{count}} tilauksen laskutuksen pysäytys onnistui',
    ordersWereSuccessfullyPaused_plural: '{{count}} tilauksen laskutus keskeytettiin onnistuneesti',
    releaseOrders: 'Vapauta tilaukset',
    recalculatePrices: 'Laske hinnat uudelleen',
    ordersSentForPriceRecalculation: '{{count}} tilaus on lisätty jonoon uudelleenlaskentaa varten',
    ordersSentForPriceRecalculation_plural: '{{count}} tilausta on lisätty jonoon uudelleenlaskentaa varten',
    ordersWereSuccessfullyReleased: '{{count}} tilaus vapautettiin onnistuneesti laskutettavaksi',
    ordersWereSuccessfullyReleased_plural: '{{count}} tilausta vapautettiin onnistuneesti laskutettavaksi',
    ordersWereSuccessfullyAcceptedWithIncompletePrice:
      '{{count}} tilaus hyväksyttiin onnistuneesti puutteellisella hinnalla',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural:
      '{{count}} tilausta hyväksyttiin onnistuneesti puutteellisella hinnalla',
    addUnit: 'Lisää yksikkö',
    addUnitDescription: 'Lisää yksikkö valitsemiisi tilauksiin. Tämä yksikkö on se, jota tilitetään.',
    addUnitToOrders: 'Lisää yksikkö tilauksiin',
    addUnitToOrder: '{{yksikkö}} lisättiin onnistuneesti {{count}} tilaukseen',
    addUnitToOrder_plural: '{{yksikkö}} lisättiin onnistuneesti {{count}} tilaukseen',
    addUnitBulkDisabled: 'Yksikkö voidaan lisätä vain tilauksiin, joissa ei ole yksikköä',
    approveIncompletePrices: 'Hyväksy epätäydellisillä hinnoilla',
    manualOverrideTitle: 'Manuaalinen ohitus laskutuksen tila',
    unitId: 'Yksikkötunnus',
    bannerOldInvoicePage:
      'Elokuun 26. päivä <0>uusi laskutussivu</0> avautuu oletuksena. Suosittelemme aloittamaan uuden sivun käytön. Lisätietoja uudesta sivusta <1>täällä</1>.',
    acceptIncompletePricesTitle: 'Hyväksy epätäydelliset hinnat',
    acceptIncompletePricesModalQuestion:
      'Olet hyväksymässä {{count}} tilauksen, jonka hinnat ovat puutteelliset. Haluatko varmasti jatkaa?',
    acceptIncompletePricesModalQuestion_plural:
      'Olet hyväksymässä {{count}} tilausta, joiden hinnat eivät ole täydellisiä. Haluatko varmasti jatkaa?',
    confirmCancelDialog: {
      title: 'Sinulla on tallentamattomia muutoksia',
      question: 'Sinulla on tallentamattomia muutoksia. Haluatko jatkaa?',
      abort: 'Pysy sivulla',
      confirm: 'Jatka tallentamatta'
    }
  },
  live: {
    onlyDeviated: 'Näytä vain poikkeamareitit',
    bannerOldLivePage:
      '<0>Kokeile</0> uutta Live-sivua, jossa on parannetut toiminnot ja uusi muotoilu. Suosittelemme käyttämään uutta live-sivua, jotta voit tottua siihen ennen kuin poistamme sivun kokonaan.',
    shipment: 'lähetys',
    shipmentFrom: 'Mistä',
    shipmentTo: 'Minne',
    confirm: 'Vahvista',
    unconfirm: 'Hylkää',
    confirmedBy: 'Vahvistettu',
    theCourier: 'Kuriiri',
    activeRoutes: 'Aktiiviset reitit',
    routeDeviation: 'Reitin poikkeama',
    routeDeviations: 'Reitin poikkeamat',
    multipleDeviations: 'Useita poikkeamia',
    recentDeviations: 'Viimeaikaiset poikkeamat',
    noRoutesFound: 'Reittejä ei löytynyt',
    noDeviationsFound: 'Poikkeamia ei löytynyt',
    consignmentDamage: 'Lähetyksen vahingot',
    shipmentDeviation: 'lähetyksen poikkeama',
    outsideServiceTime: 'Palveluajan ulkopuolella',
    outsideEstimatedTime: 'Arvioidun aikaikkunan ulkopuolella',
    deliveryLocationDeviation: 'Odottamaton sijainti',
    eventTextDeviationOne: 'Kuljettajalla {{name}} on lähetyksen poikkeamia.',
    eventTextDeviationMany: 'Kuljettajalla {{name}} on {{count}} toimituspoikkeamaa.',
    eventTextDamageOne: 'Kuljettajalla {{name}} on vaurioita {{count}} lähetyksessä.',
    eventTextDamageMany: 'Kuljettajalla {{name}} on vaurio lähetyksessä.',
    eventTextServiceTimeWindow: '{{name}} toimitetti palveluaikaikkunan ulkopuolella.',
    eventTextEstimatedTimeWindow: '{{name}} toimitetti arvioidun aikaikkunan ulkopuolella.',
    eventTextDeviated: '{{name}} on poikennut reitiltä.',
    eventTextDeliveryLocationDeviation:
      '{{name}} toimitettu/ yritetty toimittaa {{distance}} metriä odotetusta sijainnista.',
    eventHelpDeviation: 'Kuljettajalla {{name}} on poikkeama lähetysnumerossa {{shipmentId}}.',
    eventHelpDamage: 'Kuljettajalla {{name}} on vaurio lähetyksessä numero {{shipmentId}}.',
    filterOngoing: 'Jatkuva',
    filterNotStarted: 'Ei alkanut',
    filterFinished: 'valmis',
    filterOnlyDeviated: 'Vain poikkeamat',
    filterShipmentDeviations: 'lähetyksen poikkeamat',
    filterShipmentDamage: 'Lähetysvaurio',
    filterEstimatedTime: 'Arvioitu aika',
    filterServiceTime: 'Palveluaika',
    filterRouteDeviations: 'Reittipoikkeamat',
    filterDeliveryLocationDeviation: 'Odottamaton sijainti',
    filterConfirmed: 'vahvistettu',
    routeProgress: 'Valmis {{complete}} / {{total}}',
    routeDuration: 'From {{from}} - {{to}}',
    routeDelayed: 'viivästynyt',
    routeAhead: 'Eteenpäin',
    routeOnTime: 'Ajallaan',
    routeUnknown: 'Tuntematon',
    routeDeviationsNone: 'Ei poikkeamia',
    routeDeviationCount: '{{unhandledDeviations}} / {{totalDeviations}}',
    routeHasHandleDeviations: 'Reitillä on {{count}} käsiteltyä poikkeamaa',
    routeHasHandleDeviations_plural: 'Reitillä on {{count}} käsiteltyä poikkeamaa',
    openRoute: 'Katso reitti',
    openShipment: 'Katso lähetys',
    viewRoute: 'Näytä reitti',
    deviated: 'poikkeama',
    offRoute: 'Pois reitiltä',
    onRoute: 'Tiellä',
    allRoutesStates: 'Kaikki reittitilat',
    selectedRouteStates: '{{count}} reitin tila',
    selectedRouteStates_plural: '{{count}} reittitilaa',
    noSelectedStates: 'Ei valittuja tiloja',
    allDeviationTypes: 'Kaikki poikkeamatyypit',
    selectedDeviationTypes: '{{count}} poikkeamatyyppi',
    selectedDeviationTypes_plural: '{{count}} poikkeamatyyppiä',
    showHandledDeviations: 'Näytä käsitellyt poikkeamat ({{count}})',
    noSelectedTypes: 'Ei valittuja tyyppejä',
    viewShipment: 'Näytä lähetys',
    selectAllRouteStates: 'Valitse kaikki reittitilat',
    noRoutesWithDeviations: 'Ei reittejä käytettävissä',
    selectAllDeviationTypes: 'Valitse kaikki poikkeamatyypit',
    noDeviationOnRoute: 'Ei poikkeamia reitillä',
    noDeviationAvailable: 'Ei poikkeamia käytettävissä',
    noDeviationsOnFilter: 'Valitulla suodattimella ei ole poikkeamia. Kokeile toista suodatinta',
    noRoutesOnFilter: 'Valitulle suodattimelle ei ole saatavilla reittejä. Kokeile toista suodatinta',
    eventHelpServiceTimeWindow:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} klo {{actual}}, joka on palveluaikaikkunan {{earliest}}-{{latest}} ulkopuolella.',
    eventHelpEstimatedTimeWindow:
      '{{name}} on toimittanut lähetyksen numero {{shipmentId}} klo {{actual}}, mikä on arvioidun aikaikkunan {{earliest}}–{{latest}} ulkopuolella.',
    eventHelpDriverDeviated: '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä.',
    eventHelpDriverDeviatedBefore:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä, pysähtyen toimitukseen #{{actual}} ennen toimitusta #{{odotettu}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä, pysähtyen toimitukseen #{{actual}} toimituksen #{{odotettu}} jälkeen.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} on toimittanut tai poikennut lähetysnumeron {{shipmentId}} <4>tänne</4>, {{distance}} metrin päähän <9>odotetusta sijainnista</9>.',
      withoutShipmentId:
        '{{name}} on toimittanut tai poikennut <4>tänne</4>, {{distance}} metrin päässä <9>odotetusta sijainnista</9>.'
    },
    search: 'Hae reitin, laitteen tai kuljettajan mukaan',
    unhandled: 'käsittelemättömät',
    deviations: 'poikkeamat',
    mostDeviations: 'Suurin osa poikkeamista',
    latestInTime: 'Viimeisin ajallaan',
    earliestInTime: 'Aikaisintaan',
    sortOn: 'Lajittele',
    late: 'myöhässä',
    early: 'etuajassa',
    stops: 'Pysähdykset: {{stops}}',
    deviatedFromRoute: 'Poikennut reitiltä',
    totalTimeMajorDeviations: 'Merkittävien poikkeamien kokonaisaika (≥5 min)',
    totalTimeMinorDeviations: 'Pienten poikkeamien kokonaisaika (<5 min)',
    lateOrEarlyFirstStop: 'Myöhässä tai etuajassa ensimmäiseen toimitukseen',
    showAdjusted: 'Näytä korjattu aika',
    showAdjustedTooltip: 'Korjattu aika näkyy vastaanottajan seurantasivulla',
    showDiff: 'Näytä aika pysähdysten välillä',
    diff: 'Aika pysähdysten välillä',
    showDiffTooltip: 'Ero suunnitellun (ETD) ja todellisen ajan välillä pysähdysten välillä',
    modalTableSummary: 'Kokonaisreittiaika',
    liveModalActualTooltip1: 'Verrattuna ETD:hen:',
    liveModalActualTooltip2: 'Vihreä: Enintään 15 min',
    liveModalActualTooltip3: 'Oranssi: 15–30 min',
    liveModalActualTooltip4: 'Punainen: Yli 30 min',
    routeStateFilter: 'Reitin tilasuodatin',
    departmentFilter: 'Osastosuodatin',
    allDepartments: 'Kaikki osastot',
    deviationTypeFilter: 'Poikkeamatyyppisuodatin'
  },
  userRolesAdmin: {
    userRoles: 'Käyttäjäroolit',
    successCreateMessage: '{{name}} luotiin onnistuneesti',
    successUpdateMessage: '{{name}} päivitettiin onnistuneesti',
    saveRole: 'Tallenna rooli',
    editRole: 'Muokkaa {{name}}',
    createNewRole: 'Luo uusi rooli',
    nameOfRole: 'Roolin nimi',
    nameRequired: 'Nimi vaaditaan',
    descriptionRequired: 'Kuvaus vaaditaan',
    descriptionLabel: 'Kuvaus (max 240 merkkiä)',
    iconLabel: 'Valitse haluamasi ikoni käyttäjäroolille',
    selectRoleTitle: 'Valitse käyttöoikeus roolille',
    emptyState: 'Hakulauseella ei löytynyt rooleja',
    deleteConfirm: 'Oletko varma, että haluat poistaa {{name}}?',
    deleteSuccess: 'Rooli {{name}} poistettiin onnistuneesti',
    settings: 'Asetukset',
    accessRights: 'Käyttöoikeudet',
    settingsTitle: 'Asetukset roolille',
    settingsDescription: 'Englanti on pakollinen. Jos muita kieliä ei valita, käytetään varakielenä englantia.',
    additionalLanguagesTitle: 'Lisää muita kieliä',
    customerPortalAccess: 'Asiakasportaalin pääsy',
    defaultForUserRole: 'Käytä oletusroolina (valinnainen)',
    event: 'Tapahtuma',
    financialAccess: 'Rahoitusoikeudet',
    invoiceActions: 'Laskutoimenpiteet',
    orderAttributes: 'Tilausattribuutit',
    findApiOrder: 'Etsi API-tilaus',
    optimus: 'Optimus',
    noAccessToApiOrder: 'Ei pääsyä Etsi API-tilaukseen',
    noAccessToOptimus: 'Ei pääsyä Optimukseen',
    optimusViewDescription:
      'Käyttäjä voi tarkastella palveluja osastoilta ja asiakkailta, mutta ei voi tehdä muutoksia',
    optimusFullDescription: 'Käyttäjä voi lisätä ja muokata palveluja osastoille ja asiakkaille',
    ordersApiSearchRestrictedDescription:
      'Käyttäjä voi etsiä tilauksia osastoilta tai asiakkailta, joihin heillä on pääsy',
    ordersApiSearchFullDescription: 'Käyttäjä voi etsiä tilauksia kaikilta osastoilta ja asiakkailta',
    addRole: 'Lisää rooli',
    addedRole: 'Rooli lisätty',
    removeRole: 'Poista rooli',
    importExport: 'Tuo / Vie',
    importExportExtendedDescription: 'Sisältää pääsyn laajennettuun kuriiriraporttiin.',
    importExportExtendedSaaSDescription: 'Sisältää pääsyn päivän loppuraporttiin.'
  },
  validationError: {
    provideEmail: 'Sinun täytyy antaa sähköposti',
    provideValidEmail: 'Sinun täytyy antaa kelvollinen sähköposti',
    youMustSelectCustomer: 'Sinun täytyy valita asiakas'
  },
  manageReturns: {
    title: 'Hallinnoi palautuksia',
    typesOfReturns: 'Palautustyypit',
    ordersOverAPeriod: 'Näytetään tilaukset viime kuukaudelta.',
    pickedUpByCustomer: 'Noudettu asiakkaan toimesta',
    pickedUpByCustomerDescription: 'Palautukset, jotka asiakas noutaa',
    transferToDifferentTerminal: 'Siirrä eri terminaaliin',
    transferToDifferentTerminalDescription: 'Palautukset, jotka lähetetään toiseen terminaaliin',
    environmentalReturns: 'Ympäristöpalautukset',
    environmentalReturnsDescription: 'Kierrätettävät palautukset, kerätty konttiin',
    returnForDelivery: 'Palautus toimitettavaksi',
    returnForDeliveryDescription: 'Palautukset, jotka reititetään ja toimitetaan',
    MANUAL_RETURNS: 'Manuaaliset palautukset',
    manualReturnsDescription: 'Tilaus, jossa palautusvaihetta ei ole vielä luotu',
    returnToSenderTitle: 'Haluatko palauttaa lähetykset lähettäjälle',
    manuallyMarkAsHandled: 'Merkitse manuaalisesti käsitellyksi',
    manuallyMarkAsHandledTooltip: 'Kun lähetys on merkitty käsitellyksi, se poistuu listalta',
    ordersFoundCount: '{{count}} tilaus löydetty',
    ordersFoundCount_plural: '{{count}} tilausta löydetty',
    PICKED_UP_BY_CUSTOMER: 'Noudettu asiakkaan toimesta',
    TRANSFER_TO_DIFFERENT_TERMINAL: 'Siirrä eri terminaaliin',
    ENVIRONMENTAL_RETURNS: 'Ympäristöpalautukset',
    RETURN_FOR_DELIVERY: 'Palautus toimitettavaksi',
    ordersAlreadyHandled: 'Kaikki tilaukset on käsitelty',
    ordersAlreadyHandledDescription: 'Emme löytäneet käsittelemättömiä tilauksia.',
    manuallyMarkAsHandledModal: {
      title: 'Merkitse manuaalisesti käsitellyksi',
      shipmentsCount: '{{count}} lähetys',
      shipmentsCount_plural: '{{count}} lähetystä',
      description:
        'Kun lähetykset on merkitty käsitellyiksi, ne poistuvat listalta. Tätä toimintoa ei voi perua. Lähetykset löytyvät hakusivulta.',
      confirm: 'Merkitse käsitellyksi'
    },
    manuallyMarkAsHandledSuccess: '{{count}} lähetys merkittiin käsitellyksi',
    manuallyMarkAsHandledSuccess_plural: '{{count}} lähetystä merkittiin käsitellyksi',
    returnToSenderSuccess: '{{count}} lähetys palautettiin lähettäjälle',
    returnToSenderSuccess_plural: '{{count}} lähetystä palautettiin lähettäjälle',
    selectCustomer: 'Valitse asiakas',
    customer: 'Asiakas',
    customer_plural: 'Asiakkaat',
    allCustomers: 'Kaikki asiakkaat',
    selectDestination: 'Valitse määränpää',
    destination: 'Loppumääränpää',
    destinationDIP: 'Määränpää DIP',
    returnShipmentColumns: 'Palautustiedot',
    allDestinations: 'Kaikki määränpäät',
    noDate: 'Kaikki',
    zeroDays: '0 päivää',
    oneTo6Days: '1–6 päivää',
    moreThan7Days: '> 7 päivää',
    moreThan15Days: '> 15 päivää',
    moreThan30Days: '> 30 päivää',
    customerDate: 'Mukautettu',
    daysOnTerminal: 'Päiviä terminaalissa',
    printList: 'Tulosta lista',
    returnToSender: 'Palauta lähettäjälle',
    returnToSenderQueued: 'Palautus lähettäjälle on jonossa',
    returnToSenderConfirmation: 'Haluatko palauttaa tämän lähetyksen lähettäjälle?',
    returnToSenderFailed: 'Lähetystä ei voitu palauttaa lähettäjälle',
    returnToSenderDisabledTooltip: 'Lähetystä ei voi palauttaa, jos se on jo osa moniosavaihetta',
    senderDIP: 'Lähettäjä DIP',
    createReturnLabel: 'Luo palautusetiketti'
  },
  apolloAdmin: {
    title: 'Integraatiot',
    emptyTitle: 'Ei pyyntöjä',
    emptyDescription: 'Valitse haluamasi päivämäärät ja paina hae.',
    reqType: 'Pyynnön tyyppi',
    routeId: 'Reitin id',
    routeName: 'Reitin nimi'
  },
  preAdviceFollowup: {
    title: 'Hallitse ennakkoilmoitusta',
    toBeHandled: 'Käsiteltävä',
    failedOrders: 'Epäonnistuneet tilaukset',
    failedOrdersDescription: 'Vastaanottaja ei ole saanut ilmoitusta',
    manualPreAdvice: 'Manuaalinen ennakkoilmoitus',
    manualPreAdviceDescription: 'Tilaukset, joissa on manuaalinen laukaisu ylläpitosivulla',
    preAdviceLocked: 'Ennakkoilmoitus lukittu',
    preAdviceLockedDescription: 'Tilaukset, jotka ovat pidossa ja odottavat toimia',
    missingRules: 'Puuttuvat säännöt',
    missingRulesDescription: 'Tilauksilta puuttuu ennakkoilmoituksen sääntö.',
    inProgress: 'Käynnissä',
    inProgressDescription: 'Tilaukset, joilla ennakkoilmoitus on käynnissä',
    ordersFoundCount: '{{count}} tilaus löytyi',
    ordersFoundCount_plural: '{{count}} tilausta löytyi',
    FAILED_ORDERS: 'Epäonnistuneet tilaukset',
    MANUAL_PRE_ADVICE: 'Manuaalinen ennakkoilmoitus',
    PRE_ADVICE_LOCKED: 'Ennakkoilmoitus lukittu',
    MISSING_RULES: 'Puuttuvat säännöt',
    IN_PROGRESS: 'Käynnissä',
    ordersAlreadyHandled: 'Kaikki tilaukset on käsitelty',
    ordersAlreadyHandledDescription: 'Emme löytäneet käsittelemättömiä tilauksia.',
    updatePhoneNumber: 'Vaihda tai lisää puhelinnumero',
    updateEmailAddress: 'Vaihda tai lisää sähköpostiosoite',
    removeShipmentFromPreAdvice: 'Poista lähetys ennakkoilmoituksesta',
    bookDateAndTime: 'Varaa päivämäärä ja aika',
    manuallyPreAdvice: 'Manuaalinen ennakkoilmoitus',
    unlockPreAdvice: 'Avaa ennakkoilmoitus',
    lockPreAdvice: 'Lukitse ennakkoilmoitus',
    triggerPreAdvice: 'Käynnistä ennakkoilmoitus',
    selectedCustomers: '{{count}} asiakas',
    selectedCustomers_plural: '{{count}} asiakasta',
    allCustomers: 'Kaikki asiakkaat',
    selectRowWithOrderId: 'Valitse rivi tilaus-ID:llä {{id}}',
    updateRecipientPhoneNumber: 'Päivitä puhelinnumero',
    phoneNumberUpdated: 'Puhelinnumero päivitetty lähetys-ID:lle: {{shipmentId}}',
    phoneNumberUpdatedAndPreAdviceSent:
      'Puhelinnumero päivitetty ja ennakkoilmoitus lähetetty lähetys-ID:lle: {{shipmentId}}',
    samePhoneNumberAlreadySet: 'Sama puhelinnumero on jo asetettu lähetykselle.',
    updateRecipientEmailAddress: 'Päivitä sähköpostiosoite',
    emailAddressUpdated: 'Sähköpostiosoite päivitetty lähetys-ID:lle: {{shipmentId}}',
    emailAddressUpdatedAndPreAdviceSent:
      'Sähköpostiosoite päivitetty ja ennakkoilmoitus lähetetty lähetys-ID:lle: {{shipmentId}}',
    sameEmailAddressAlreadySet: 'Sama sähköpostiosoite on jo asetettu lähetykselle.',
    manuallyPreAdvicedSuccess: 'Onnistuneesti käynnistetty ennakkoilmoitus {{count}} lähetykselle',
    nrOfSelected: '{{count}} valittu',
    sendPreAdvice: 'Lähetä ennakkoilmoitus',
    unlockPreAdviceSuccess: 'Onnistuneesti avattu ennakkoilmoitus {{count}} lähetykselle',
    unlockPreAdviceAndPreAdviceSent: 'Onnistuneesti avattu ja lähetetty ennakkoilmoitus {{count}} lähetykselle',
    lockPreAdviceSuccess: 'Onnistuneesti lukittu ennakkoilmoitus {{count}} lähetykselle',
    saveAndSendPreAdvice: 'Tallenna ja lähetä ennakkoilmoitus',
    finishPreAdviceFollowupSuccess: 'Onnistuneesti poistettu {{count}} lähetystä ennakkoilmoituksesta'
  },
  coordinates: {
    setCoordinates: 'Aseta koordinaatit',
    title: 'Aseta osoitteiden koordinaatit',
    setCoordinatesCount: 'Aseta koordinaatit ({{count}})',
    zipCodeRange: 'Postinumeroalue',
    goodJob: 'Hyvää työtä',
    noUnresolvedShipments: 'Tälle osastolle tai osastoryhmälle ei löytynyt ratkaisemattomia lähetyksiä',
    coordinateSaved: 'Koordinaatit tallennettu osoitteelle {{address}}',
    checkMapProvider: 'Tarkista {{name}}',
    noDate: 'Ei päivämäärää',
    applyFilter: 'Käytä suodatinta',
    thereAreUnsetCoordinates: 'Sinulla on {{count}} koordinaatti asetettavana.',
    thereAreUnsetCoordinates_plural: 'Sinulla on {{count}} koordinaattia asetettavana.',
    noCoordinateFound:
      'Emme löytäneet koordinaatteja annetulle osoitteelle. Hae osoitetta tai syötä koordinaatit (esim. 59.289976807684084, 18.010318840107406) hakukenttään.'
  },
  relatedOrders: {
    NONE: 'Ei liittyviä tilauksia',
    MULTILEG: 'Multileg',
    PICKUP: 'Nouto',
    DELIVERY: 'Toimitus',
    TRANSPORT: 'Transport',
    CUSTOMER: 'Asiakastilaus'
  },
  vendors: {
    title: 'Toimittajat',
    viewDetails: 'Näytä tiedot',
    searchLabel: 'Etsi toimittajan nimi, toimittajan ID tai yksikön ID',
    searchVendorPlaceholder: 'Etsi toimittajan nimi tai toimittajan ID',
    searchUnitPlaceholder: 'Hae yksikön ID',
    vendorOption: 'Toimittajan nimi/ID',
    unitOption: 'Yksikön ID',
    notFound: 'Hakusanalla ei löytynyt toimittajia',
    initialSearch: 'Etsi toimittajia',
    informationAboutTheVendor: 'Tietoja toimittajasta',
    connectUnit: 'Yhdistä yksikkö',
    connectUnits: 'Yhdistä yksiköt',
    connectedUnits: 'Yhdistetty yksikkö ({{count}})',
    connectedUnits_plural: 'Yhdistetyt yksiköt ({{count}})',
    paymentTerms: 'Maksuehdot',
    connected: 'Yhdistetty',
    searchForUnitDescription: 'Yksikön valitseminen tarkoittaa, että yksikön osasto vastaa kustannuksista.',
    searchForUnitTitle: '{{vendorSite}} varten {{operatingUnitName}}',
    successToast: 'Päivitetyt yhdistetyt yksiköt toimittajaan {{name}}',
    showConnectedUnits: 'Näytä yhdistetyt yksiköt',
    editConnectedUnits: 'Muokkaa yhdistettyjä yksiköitä',
    searchForUnit: 'Etsi yksikköä',
    searchPage: 'Hakusivu',
    currencyCode: 'Valuuttakoodi',
    connectedToCompany: 'Yhdistetty tähän yritykseen',
    noConnection: 'Ei yhteyttä',
    connectedTo: 'Yhdistetty',
    nothingHereYet: 'Ei vielä mitään',
    emptyStateSelectedVendor: 'Valitse sijaintikoodi yhdistääksesi yksiköitä tai nähdäksesi olemassa olevat yhteydet.',
    selfBilling: 'Tasauslaskutus',
    setUnitSettingsTitle: 'Päivitä yksikön asetukset kohteelle {{name}}',
    setUnitSettingsSelfBillingInternalTrade: 'Tasauslaskutus ja sisäinen kauppatapahtuma ovat aktiivisia.',
    setUnitSettingsNone: 'Tasauslaskutus ja sisäinen kauppatapahtuma eivät ole aktiivisia.',
    setUnitSettingsInternalTrade: 'Tasauslaskutus ei ole aktiivinen, mutta sisäinen kauppatapahtuma on aktiivinen.'
  },
  shdAdmin: {
    successfullyDeleted: 'Aikamatriisi poistettiin onnistuneesti',
    successfullyUploaded: 'Aikamatriisi ladattiin onnistuneesti',
    timeWindowForHomeDelivery: 'Aikaväli kotiinkuljetukseen',
    filterByCustomerData: 'Suodata asiakastiedon, alikunden tai asiakasnumeron mukaan',
    uploadTimeMatrixFile: 'Lataa aikamatriisitiedosto',
    downloadExcelTemplate: 'Lataa Excel-malli',
    clickHereOrDragAndDrop: 'Napsauta tästä tai raahaa ja pudota',
    allowedFormats: 'Sallitut muodot ovat XLS ja XLSX',
    existingCustomers: 'Olemassa olevat asiakkaat SHD:llä',
    customerPriceTooltip: 'Jos loppuasiakkaan hinta pitäisi näyttää, aseta liukusäädin päälle',
    deleteModalTitle: 'Poista aikaväli',
    deleteModalDescription:
      'Jos poistat tämän aikavälin, se poistaa kaikki SHD-aikavälit tältä asiakkaalta. Et voi palauttaa tätä toimintoa.',
    exportToExcel: 'Vie Exceliin',
    toggleLabel: 'Vaihda asiakkaan hinta',
    customerNumber: 'Asiakasnumero',
    subCustomer: 'Alikunde',
    customerName: 'Asiakas',
    amountOfPostalCodes: 'Postinumeroiden määrä',
    lastUpdatedAt: 'Viimeksi päivitetty',
    showCustomerPrice: 'Näytä asiakkaan hinta',
    uploadMaximumFileSizeLimit: 'Tiedoston koko ei voi olla suurempi kuin {{size}} KB'
  },
  serviceMapping: {
    title: 'Palvelut',
    serviceName: 'Palvelun nimi',
    pickupTime: 'Noutoaika',
    deliveryTime: 'Toimitusaika',
    internalName: 'Sisäinen nimi',
    internalDescription: 'Sisäinen kuvaus',
    externalName: 'Ulkoasu nimi',
    externalDescription: 'Ulkoasu kuvaus',
    alystraId: 'Alystra ID',
    allowFlexDelivery: 'Salli joustava toimitus',
    timeWindow: 'Aikaväli',
    serviceMappingType: 'Tyyppi',
    service: 'Palvelu',
    vas: 'VAS',
    stopsBeforeShowEta: 'ETA-pysäkit',
    minutesUntilDelivery: 'ETA minuutteina',
    sendAutomaticEtaSmsHeading: 'ETA SMS',
    createNew: 'Luo uusi',
    serviceAndVasCodes: 'Palvelut ja VAS-koodit',
    successfullyAdded: 'Palvelukartoitus lisätty onnistuneesti listalle',
    successfullyUpdated: 'Palvelukartoitus päivitetty onnistuneesti',
    successfullyDeleted: 'Palvelukartoitus poistettu onnistuneesti',
    editService: 'Muokkaa palvelua',
    createNewService: 'Luo uusi palvelu',
    settingsHeading: 'Palvelun tai VAS:n asetukset',
    selectLevel: 'Valitse taso',
    standardLevel: 'Vakiotaso',
    customerLevel: 'Asiakastaso',
    customerSpecific: 'Asiakaskohtainen',
    selectServiceType: 'Valitse palvelun tyyppi',
    vasToolTip: 'Arvonlisäpalvelu esim. ID-tarkastus',
    customerNumber: 'Asiakastunnus',
    pickupTimeInSeconds: 'Noutoaika (sekunteina)',
    pickupTimeSubsequentShipments: 'Noutoaika seuraaville lähetyksille (sekunteina)',
    deliveryTimeInSeconds: 'Toimitusaika (sekunteina)',
    deliveryTimeSubsequentShipments: 'Toimitusaika seuraaville lähetyksille (sekunteina)',
    pickupDurationSecondsApartment: 'Noutoaika huoneistoissa (sekunteina)',
    deliveryDurationSecondsApartment: 'Toimitusaika huoneistoissa (sekunteina)',
    deliveryDurationApartmentHeading: 'Toimitusaika huoneistoissa (tällä hetkellä vain H2)',
    timeWindowInMinutes: 'Aikaväli (minuutteina)',
    stopsLeftHeading: 'Milloin haluat näyttää tarkan ETA:n asiakkaalle?',
    stopsLeftDescription: 'Jos jätät tämän kentän tyhjäksi, aikaväli näytetään',
    amountOfStops: 'Pysäkkien määrä',
    flexibleDelivery: 'Joustava toimitus',
    flexibleDeliveryDescription: 'Salli vastaanottajalle valita, että paketti jätetään oven ulkopuolelle',
    flexibleDeliveryCheckboxText: 'Kyllä, vastaanottaja saa valita',
    linkTextToService: 'Linkitä teksti palveluun',
    linkTextToServiceDescription: 'Jos tekstiä ei valita, käytetään oletustekstiä',
    defaultText: 'Oletusteksti',
    listOfServicesAndVases: 'Palveluiden ja VAS-koodien lista',
    searchService: 'Suodata palvelua, VAS tai asiakastunnusta',
    customizedText: 'Mukautettu teksti',
    deleteModalTitle: 'Poista palvelukartoitus',
    deleteModalDescription: 'Tätä toimintoa ei voida peruuttaa',
    infoModal: {
      title: 'Mitä näytetään missä Glow:ssa',
      subHeading1: 'Suunnittelijat ja kuljettajat',
      description1: 'Sisäinen nimi: Palvelun nimi, esim. "Kotitoimitus"',
      description2:
        'Sisäinen kuvaus: Laajennettu tietoa palvelusta, esim. "Tämä pitää toimittaa vastaanottajan valitsemaan huoneeseen."',
      subHeading2: 'Asiakkaat ja vastaanottajat',
      description3: 'Ulkoasu nimi: Palvelun nimi, esim. "Kotitoimitus"',
      description4:
        'Ulkoasu kuvaus: Laajennettu tietoa palvelusta, esim. "Tuotteesi toimitetaan valitsemaasi huoneeseen".'
    },
    routeOptimizing: 'Reitin optimointi',
    routeOptimizingDescription:
      'Antamasi tiedot ohittavat oletusajan ja vaikuttavat reitin optimointiin. Oletusasetus noutoille on 15 sekuntia ja toimituksille 180 sekuntia.',
    settingsRecipientTrackingPage: 'Vastaanottajan seurantasivu',
    etaComponentHeading: 'Näytä tarkka ETA vastaanottajan sivulla',
    etaComponentDescription:
      'Tämän aktivoiminen antaa sinun päättää, milloin tarkka ETA näytetään vastaanottajalle aikarajan sijaan.',
    displayExactEta: 'Kyllä, näytä tarkka ETA',
    stopsLeftSelected: 'Pysäkkien määrä jäljellä toimitukseen',
    minutesLeftSelected: 'Minuuttien määrä jäljellä toimitukseen',
    numberOfMinutes: 'Minuuttien määrä',
    numberOfStops: 'Pysäkkien määrä',
    sendAutomaticEtaSmsTitle: 'Lähetä automaattinen SMS vastaanottajalle',
    sendAutomaticEtaSmsDescription:
      'Tämän aktivoiminen poistaa myös mahdollisuuden kuljettajalle lähettää SMS:ää kuljettajan sovelluksesta.',
    sendAutomaticEtaSms: 'Lähetä SMS automaattisesti',
    missingNumberOfStops: 'Syötä kelvollinen pysäkkien määrä, kun "Lähetä SMS automaattisesti" on valittu',
    missingNumberOfMinutes: 'Syötä kelvollinen minuuttimäärä, kun "Lähetä SMS automaattisesti" on valittu',
    minutesNotInRange: 'Määritä minuuttimäärä välillä {{from}} ja {{to}}',
    serviceDeliveryType: 'Palvelun toimitustyyppi',
    noServiceDeliveryTypeSelected: 'Ei asetettu',
    serviceDeliveryTypes: { Delivery: 'Toimitus', Return: 'Palautus', PickupPoint: 'Noutopiste' },
    Delivery: 'Toimitus',
    Return: 'Palautus',
    PickupPoint: 'Noutopiste',
    courierApp: 'Kuljettajan sovellus',
    allowCourierDescription: 'Salli kuljettajan hyväksyä tai hylätä lähetykset tämän palvelukoodin kanssa',
    allowCourierCheckbox: 'Kyllä, kuljettajalle sallitaan'
  },
  matrix: {
    title: 'Matriisi',
    pageTitle: 'Matrix Admin',
    name: 'Nimi',
    filename: 'Tiedoston nimi',
    uploaded: 'Ladattu',
    uploadedBy: 'Ladannut',
    downloadTemplate: 'Lataa malli',
    byUser: 'Käyttäjältä',
    country: 'Maa',
    ROUTING: 'Reititys',
    LINEHAUL: 'Linehaul',
    HUB: 'Keskus',
    DELIVERY: 'Toimitus',
    VAS: 'VAS',
    'booking-limits': 'Varauksen rajat',
    searchLabel: 'Etsi matriisia nimellä tai asiakasnumerolla',
    'baggage-delivery': 'Matkatavarat',
    baggage: {
      title: 'Matkatavaramatriisit',
      name: 'Matkatavarat {{country}}',
      legend: "Jos haluat päivittää olemassa olevia matkatavaramatriiseja, tee se valitsemalla 'lataa matriisitiedot'.",
      uploadTitle: 'for {{country}}',
      custom: 'Mukautettu DS-koodimatriisi',
      uploadCustomTitle: 'mukautetuille DS-koodeille',
      status: 'Tila',
      uploadFail: '{{label}} ei hyväksytty virheellisen tiedon vuoksi. Muokkaa tietoja ja lataa matriisi uudelleen.',
      statuses: { accepted: 'Hyväksytty', pending: 'Odottaa', rejected: 'Hylätty' }
    },
    bookingLimits: {
      header: '{{name}} - Muokkaa varauksen rajoja',
      rules: '#Säännöt',
      total: 'Yhteensä',
      rank: 'Prioriteetti',
      customer: 'Asiakas',
      customerNo: 'Asiakasnumero',
      serviceCode: 'Palvelu',
      timeWindow: 'Aikaraja',
      selectTimeWindow: 'Valitse aikaraja',
      noTimeWindow: 'Ei aikarajaa',
      noCustomer: 'Ei asiakasta',
      bookingLimit: 'Varauksen raja',
      removeLimit: 'Poista raja',
      addMoreLimits: 'Lisää lisää rajoja',
      delete: 'Poista varauksen rajat',
      deleteModalDescription: 'Et voi palauttaa tätä toimintoa',
      deleted: 'Varauksen rajat poistettiin onnistuneesti',
      updated: 'Varauksen rajat päivitettiin onnistuneesti'
    },
    master: 'Master',
    select: 'Valitse {{matrix}} matriisi...',
    overflow: '+{{overflow}} lisää..',
    vasCodes: 'VAS-koodit',
    connectedCustomers: {
      title: 'Liitetyt asiakkaat',
      button: 'Näytä {{connectedCustomers}} liitettyä asiakasta',
      search: 'Hae asiakasnimen tai numeron mukaan',
      subcustomer: 'Ala-asiakas'
    },
    upload: {
      success: 'Matriisitiedot ladattiin onnistuneesti',
      question: 'Lataa matriisitiedot',
      text: 'Lataa Excel',
      description: 'Lataa Excel-taulukko, jossa on tiedot tälle matriisille',
      error: 'Virhe ladattaessa tietoja {{name}}:hin. {{error}}'
    },
    delete: {
      question: 'Poista matriisi {{name}}',
      description: 'Tätä ei voi peruuttaa',
      success: 'Matriisi {{name}} poistettiin onnistuneesti',
      error: 'Virhe poistettaessa {{name}}. {{error}}'
    },
    new: {
      question: 'Luo uusi matriisi',
      description: 'Luo uusi matriisi',
      success: 'Matriisi {{name}} luotiin onnistuneesti',
      error: 'Virhe matriisin luomisessa. {{error}}'
    },
    edit: {
      question: 'Muokkaa¨matriisia {{name}}',
      error: 'Virhe päivittäessä {{name}}. {{error}}',
      success: 'Matriisi {{name}} tallennettiin onnistuneesti'
    },
    type: 'Valitse tyyppi',
    day: { '1': 'Ma', '2': 'Ti', '3': 'Ke', '4': 'To', '5': 'Pe', '6': 'La', '7': 'Su' },
    leadTime: 'Toimitusaika',
    terminal: 'Terminaali',
    terminalFrom: 'Terminaali alkaen',
    terminalTo: 'Terminaali asti',
    postalCode: 'Postinumero',
    postalCodeFrom: 'Postinumero alkaen',
    postalCodeTo: 'Kohdepostinumero',
    offsetDays: 'Viivepäivät',
    city: 'Kaupunki',
    error: {
      duplicateName: 'Nimi on jo olemassa',
      duplicateServiceCode: 'Varausrajoitukset on jo määritelty tälle asiakkaan ja palvelukoodin yhdistelmälle',
      duplicateTimeWindow: 'Päällekkäinen aikajakso'
    }
  },
  shipmentDetailsBilling: {
    finanicalStakeholder: 'Taloudelliset sidosryhmät',
    item: 'Tuote',
    customerTotal: 'Asiakkaan kokonaissumma',
    unitTotal: 'Yksikkösumma',
    orderTotal: 'Tilauksen kokonaissumma',
    disconnectToOrginalOrder: 'Irrota alkuperäisestä lähetysnumerosta'
  },
  upsalesRulesAdmin: {
    title: 'Lisämyyntisäännöt',
    titleSingular: 'Lisämyyntisääntö',
    name: 'Nimi',
    type: 'Tyyppi',
    searchPlaceholder: 'Hae lisämyyntisäännöt',
    settings: 'Asetukset',
    successfullyAdded: '{{name}} lisättiin onnistuneesti luetteloon',
    successfullyUpdated: '{{name}} päivitettiin onnistuneesti',
    createNewRule: 'Luo uusi lisämyyntisääntö',
    edit: 'Muokkaa',
    serviceUpgrade: 'Palvelun päivitys',
    shorterTimeWindow: 'Lyhyempi aikaikkuna',
    ruleName: 'Lisämyyntisäännön nimi',
    serviceUpgradeDescription1: 'Palvelu päivitetään 3123:sta 2870:een.',
    serviceUpgradeDescription2: 'VAS 1381 lisätään automaattisesti, jotta kuljettaja saa oikean maksun.',
    zipCodeRangeTitle: 'Postinumero',
    zipCodeRangeDescription:
      'Valitse postinumerot, jotka haluat sisällyttää tai jättää pois säännössä. Jos haluat poistaa tietyn postinumeron tai alueen sisällytetystä alueesta, käytä poissulkemisvaihtoehtoa valintasi tarkentamiseen.',
    fromZipCode: 'Postinumero alkaen',
    toZipCode: 'Postinumero asti',
    addRange: 'Lisää postinumeroalue',
    addZipCode: 'Lisää postinumero',
    noZipCodeRangesAdded: 'Sinun on annettava postinumeroalue',
    zipCodeRangeError: '"Postinumero alkaen" ei saa olla suurempi kuin "Postinumero asti"',
    deleteModalTitle: 'Poista lisämyyntisääntö',
    deleteModalDescription: 'Et voi peruuttaa tätä toimintoa',
    successfullyDeleted: 'Lisämyyntisääntö poistettiin onnistuneesti',
    shorterTimeWindowDescription1: 'Lyhyempi aikaikkuna on saatavilla kohteelle 3332',
    windowOnlyInEvening: 'Lyhyempi aikaikkuna tulee olla saatavilla vain iltaisin',
    timeWindowOffer: 'Mikä aikaikkuna tulisi tarjota vastaanottajalle?',
    oneHour: '1 tunti',
    twoHours: '2 tuntia',
    threeHours: '3 tuntia',
    mustSelectTimeWindow: 'Sinun on annettava vastaanottajalle tarjottu aikaikkuna',
    include: 'Sisällytä',
    exclude: 'Poissulje',
    zipCodeRangeType: 'Tyyppi',
    exactZipCode: 'Tarkka',
    zipCodeRange: 'Alue',
    zipCode: 'Postinumero',
    mustSelectCountry: 'Sinun on valittava maa ensin',
    includedZipCodes: 'Sisällytetyt postinumerot ({{count}})',
    excludedZipCodes: 'Poissuljetut postinumerot ({{count}})',
    noZipCodesAddedYet: 'Postinumeroita ei ole vielä lisätty',
    noMatchOnZipCodeSearch: 'Postinumeroa ei löytynyt. Kokeile suodattaa toisella postinumeroalueella.',
    zipCodesFound: '{{count}} postinumero löytyi',
    zipCodesFound_plural: '{{count}} postinumeroa löytyi',
    filterOnDepartments: 'Suodata osastojen mukaan',
    UPSALES_RULE_COLLISION_OCCURRED:
      'Sääntö on jo olemassa! Poista joko seuraavat osastot: {{departments}} tai seuraavat asiakkaat: {{customers}}',
    status: 'Tila',
    active: 'Aktiivinen',
    paused: 'Keskeytetty',
    pauseRule: 'Keskeytä sääntö',
    activateRule: 'Aktivoi sääntö',
    activateRuleModalDescription:
      'Olet aktivoimassa sääntöä. Tämä tarkoittaa, että lisämyynti on saatavilla vastaanottajille. Haluatko jatkaa?',
    pauseRuleModalDescription:
      'Olet keskeyttämässä sääntöä. Tämä tarkoittaa, että lisämyynti ei ole saatavilla vastaanottajille. Haluatko jatkaa?',
    successfullyActivated: 'Lisämyyntisääntö aktivoitiin onnistuneesti',
    successfullyPaused: 'Lisämyyntisääntö keskeytettiin onnistuneesti'
  },
  billingOrder: {
    standaloneInvoice: 'Erillinen lasku',
    standaloneInvoiceLabel:
      'Haluan, että tämä tilaus käsitellään erikseen eikä yhdistetä muihin tilauksiin laskutusta varten.',
    noUnitNotSelfBilled: 'Tilauksia ilman yksikköä ei hyvitetä.'
  },
  welcomeToGlow: "Tervetuloa Glow'hun",
  welcomeToGlowDescription: 'Käytä vasemmalla olevaa navigointia aloittaaksesi työsi!',
  formValidation: {
    mustProvideName: 'Sinun on annettava nimi',
    mustProvideDescription: 'Sinun on annettava kuvaus',
    mustProvideServiceCode: 'Sinun on annettava palvelukoodi',
    mustProvideCalendarDays: 'Sinun on annettava kalenteripäivät',
    mustProvideCutOffTime: 'Sinun on annettava katkaisuajankohta',
    mustProvideCutOffMinutes: 'Sinun on annettava katkaisuminuutit',
    mustProvideDeliveryAttempts: 'Sinun on annettava toimitusyritykset',
    mustProvideCustomer: 'Sinun on annettava asiakas',
    mustProvideQuantity: 'Sinun on annettava määrä',
    mustProvideArticle: 'Sinun on annettava artikkeli',
    mustProvideDate: 'Sinun on annettava päivämäärä',
    mustProvideCustomerOrUnitAmount: 'Sinun on annettava asiakas- tai yksikkömäärä'
  },
  units: {
    title: 'Yksiköt',
    new: 'Luo uusi yksikkö',
    connectFromOtherDepartments: 'Yhdistä muista osastoista',
    connect: {
      title: 'Yhdistä yksiköt nykyiseen osastoon',
      description: 'Voit etsiä yksiköitä vain niistä osastoista, joihin sinulla on pääsy',
      selected: 'Valitut yksiköt',
      noneSelected: 'Ei yksiköitä',
      submit: 'Yhdistä'
    },
    assigned: 'Yksikkö/yksiköt yhdistettiin onnistuneesti'
  }
}
export default fi
